import { Table } from "antd";
import { useEffect, useState } from "react";
import { fetchSuppliesTotal } from "../utils/fetch";
import { suppliesTotalColumn, suppliesTotalDataSource } from "../utils/table";

const SuppliesTotal = () => {
  const [accessories, setAccessories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSuppliesTotal(setAccessories, setIsLoading);
  }, []);

  return (
    <Table
      bordered
      loading={isLoading}
      size="small"
      pagination={false}
      columns={suppliesTotalColumn}
      dataSource={suppliesTotalDataSource(accessories)}
    />
  );
};

export default SuppliesTotal;
