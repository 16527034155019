import { Modal, Button, message } from "antd";
import { useState } from "react";
import { acceptDocumentsForDelivery, sendDeliveryCodeToClient, completeDelivery } from "../utils/fetch";
import PinInput from "react-pin-input";

export const AcceptDocumentsForDeliveryButton = ({ lead, fetch }) => {
  const [showAcceptAccessoriesModal, setShowAcceptAccessoriesModal] = useState(false);

  const content = (
    <>
        <p>Убедитесь, что переданный заказ соответствует полученному вами Акту приема-передачи. С текущего момента ответственность за заказ лежит на вас.</p>
    </>
  )
  
    return (
      <>
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            onClick={() => {
              setShowAcceptAccessoriesModal(true);
            }}
          >
            Принять
          </Button>
        <DeliveryModal 
          visible = {showAcceptAccessoriesModal}
          onSubmit = {() => {
            acceptDocumentsForDelivery(lead._id, fetch)
            setShowAcceptAccessoriesModal(false)
          }}
          onCancel = {() => setShowAcceptAccessoriesModal(false)}
          okText = "Принять"
          content = {content}
        />
    </>
    )
}

export const CompleteDeliveryButton = ({ lead, fetch }) => {
    const [showAcceptAccessoriesModal, setShowAcceptAccessoriesModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pin, setPin] = useState('')

    const onClick = async (phone, id) => {
      setIsLoading(true);
      sendDeliveryCodeToClient(id, phone, 
        msg => {
          if (msg) {
              message.success(msg, 3)
          }
          setShowAcceptAccessoriesModal(true)
        },
      )
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err?.response?.data?.message;
        }
  
        message.error(msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
    };

    const onSubmit = (pin, lead) => {
      setIsLoading(true);
      completeDelivery(pin, lead,
        msg => {
          if (msg) {
              message.success(msg, 5)
          }
          setShowAcceptAccessoriesModal(false)
        },
        () => {
          message.error("Неверный код", 3)
        },
        () => {
          fetch();
          setIsLoading(false)
        }
      )
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err?.response?.data?.message;
        }
  
        message.error(msg);
      })
    }
  
    const content = (
      <>
          <p>Чтобы завершить доставку, введите код, который клиент получил на WhatsApp</p>
          <div className="my-3">
          <PinInput
            length={4}
            initialValue=""
            onChange={(value, _index) => {
              setPin(value);
            }}
            type="numeric"
            inputMode="number"
            inputStyle={{ borderColor: "#dee2e6" }}
            inputFocusStyle={{ borderColor: "#1890ff" }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>
      </>
    )
      return (
        <>
            <Button
              style={{
                padding: "5px 10px",
                color: "green",
                marginRight: 10,
              }}
              loading = {isLoading}
              onClick={() => {
                onClick(lead.client[0].phone, lead._id)
              }}
            >
                Завершить доставку
            </Button>
          <DeliveryModal 
            visible = {showAcceptAccessoriesModal}
            onSubmit = {() => {
              onSubmit(pin, lead)
            }}
            onCancel = {() => setShowAcceptAccessoriesModal(false)}
            okText = "Отправить"
            content = {content}
            loading = {isLoading}
          />
      </>
      )
}

const DeliveryModal = ({
  visible,
  onSubmit,
  onCancel,
  okText,
  content,
}) => {
  return (
    <Modal
    title="Внимание!"
    visible={visible}
    onOk={onSubmit}
    onCancel={onCancel}
    okText={okText}
    cancelText="Закрыть"
    okButtonProps={{
      style: {
        backgroundColor: "red",
        borderColor: "red",
      },
    }}
  >
    {content}
  </Modal>
  )
}  