import { message } from "antd";
import config from "../../../config.json";
import { getRequest, postRequest, patchRequest } from "../../../utils/requests";

export const fetchEntities = ({
  setEntities = () => {},
  setIsLoading = () => {},
  setToken = () => {},
}) => {
  const url = `${config.service}/api/entity`;

  const response = getRequest(url, {});

  response
    .then((data) => {
 
      
      setEntities(data.data);
      console.log('fetch', data.data)
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        setToken("");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  
    
};

export const createEntity = (body, onFinish) => {
  const hideLoadingMsg = message.loading("Сохраняется", 0);

  const url = `${config.service}/api/entity`;

  const response = postRequest(url, body);

  response
    .then(() => {
      message.success("Юр. лицо успешно создан", 2);

      onFinish();
    })
    .catch(() => {
      message.error("Не удалось сохранить юр.лицо", 2);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const updateEntity = async ({
  entityId = "",
  body = {},
  setIsLoading = () => {},
}) => {
  const hideLoadingMsg = message.loading("Загрузка...", 0);

  await patchRequest(`${config.service}/api/entity/${entityId}`, body)
    .catch(() => {
      message.error("Ошибка при обновлении", 3);
    })
    .finally(() => {
      setIsLoading(false);
    });


  await patchRequest(
    `${config.service}/api/permission/entity/${entityId}`,
    body.permission
  )
    .then(() => {
      message.success("Обновлено", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};
