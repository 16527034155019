import { message } from "antd";
import config from "../../../config.json";
import { patchRequest, postRequest } from "../../../utils/requests";

const isNotFinished = (app) => {
 return (app.status === "ORDER_DELIVERED" && app.isHasMontage === true)
}

export const fetchStatuses = (
  state = "NEW",
  status = "",
  query = "",
  setSpecifications = () => {},
  setIsLoading = () => {},
  remaining = false,
  excludeStatus = ""
) => {
  let url = `${config.service}/api/appl/state?search=${query}`;

  const body = {
    state,
  };
  if (status) {
    body.status = status;
  }
  if (excludeStatus) {
    body.excludeStatus = excludeStatus;
  }

  const response = postRequest(url, body, {});

  response
    .then((data) => {
      if (remaining) {
        const filtered = data.data.application.filter(app => !isNotFinished(app))
        setSpecifications(filtered);
        return
      } 
      setSpecifications(data.data.application)
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const acceptApplication = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "ACTIVE",
    status: "ASSEMBLING_CARCASS",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const refuseApplication = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "NEW",
    status: "REFUSED",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const updateApplication = (id, body, onFinish) => {
  const url = `${config.service}/api/appl/changeStatus/${id}`;

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Обновлено", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const updateApplicationStatus = (id, body, onFinish) => {
  const url = `${config.service}/api/appl/changeStatus/${id}`;

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Обновлено", 3);

      if (onFinish) {
        onFinish();
      }
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};
