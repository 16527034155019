export const formatStringToDate = (stringDate) => {
  try {
    const arr = stringDate.split(".");
    const day = parseInt(arr[0]);
    const month = parseInt(arr[1]);
    const year = parseInt(arr[2]);

    const date = new Date(year, month - 1, day);

    return date;
  } catch (_) {}

  return new Date();
};
