import { message } from "antd";
import config from "../../../config.json";
import { postRequest, getRequest, patchRequest } from "../../../utils/requests";

export const fetchSuppliesLeads = (
  query,
  pageNumber,
  pageSize,
  setLeads,
  setTotalPages,
  setIsLoading
) => {
  setIsLoading(true);

  const url = `${config.service}/api/equipments/active?page=${pageNumber}&size=${pageSize}&query=${query}`;

  const response = getRequest(url);

  response
    .then((data) => {
      setLeads(data.data?.application || []);
      setTotalPages(data.data?.totalPages || 0);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchSuppliesLeadsCalc = (specId, setData, setIsLoading) => {
  setIsLoading(true);

  const url = `${config.service}/api/equipments/calc/${specId}`;

  const response = getRequest(url);

  response
    .then((data) => {
      setData(data.data || {});
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchSuppliesTotal = (setData, setIsLoading) => {
  setIsLoading(true);

  const url = `${config.service}/api/equipments/total`;

  const response = getRequest(url);

  response
    .then((data) => {
      setData(data.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const generateSuppliesLeadsFile = (body, setIsLoading) => {
  setIsLoading(true);

  const url = `${config.service}/api/equipments/file`;

  const response = postRequest(url, body, { responseType: "blob" });

  response
    .then((data) => {
      const href = URL.createObjectURL(data.data);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Закупка_${new Date().toLocaleDateString()}.pdf`
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((err) => {
      message.error("Не удалось сгенерировать документ");
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchSuppliesWarehouse = (
  page,
  size,
  setData,
  setTotalPages,
  setIsLoading
) => {
  setIsLoading(true);

  const url = `${config.service}/api/equipments/storage?page=${page}&size=${size}`;

  const response = getRequest(url);

  response
    .then((data) => {
      setData(data.data?.application || []);
      setTotalPages(data.data?.totalPages || 0);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const setAccessoriesPurchaseStatus = async (applicationIds) => {
  const url = `${config.service}/api/equipments/accessories-purchase-status`;

  await patchRequest(url, { applicationIds });
};
