const formatDeadlineDate = (date) => {
  let parsedDate;

  if (!date || isNaN(new Date(date).getTime())) {
    parsedDate = new Date();
  } else {
    parsedDate = new Date(date);
  }

  return parsedDate;
};

export const getInitialValues = (
  client,
  discount = 0,
  increasePrice = 0,
  lead,
  specification
) => {
  const deadline = formatDeadlineDate(specification?.deadline);

  return {
    name: client?.name || "",
    iin: client?.iin || "",
    clientType: client?.clientType || "INDIVIDUAL",
    phone: client?.phone || "",
    additionalPhone: client?.additionalPhone || "",
    designerDiscount: specification?.designerDiscount || "",
    deadline,
    companyName: client?.companyName,
    fullNameDirector: client?.fullNameDirector || "",
    onWhatBasisDirector: client?.onWhatBasisDirector,
    BIN: client?.BIN || "",
    accountNumber: client?.accountNumber || "",
    discount,
    constractPriceIncreasePercentage: increasePrice,
    constractPriceIncreaseDescription: "",
    prepayment: specification?.paymentFormat?.prepayment || "",
    remains: specification?.paymentFormat?.remains || "",
    hasSignature: lead?.hasSignature || false,
    bankName: client?.bankName,
    BIC: client?.BIC,
    address: lead?.address || "",
    paymentMethod: specification?.paymentMethod || "KASPI_PAY",
  };
};
