import { CheckOutlined } from "@ant-design/icons";
import { Button, Popconfirm, message } from "antd";
import { setAccessoriesPurchaseStatus } from "../utils/fetch";
import { useState } from "react";

const SuppliesPurchasedButton = ({ selectedLeads, setSelectedLeads }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSetAccessoriesPurchaseStatus = () => {
    setIsLoading(true);

    setAccessoriesPurchaseStatus(selectedLeads)
      .then(() => {
        setSelectedLeads([]);

        message.success("Выбранные заявки успешно отправлены на склад.");
      })
      .catch(() => {
        message.error("Выбранные заявки не удалось отправить на склад.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Popconfirm
      title={
        <div>
          Выбранные заявки будут перемещены на вкладку "Склад" и станут
          недоступны для редактирования после завершения операции.
        </div>
      }
      onConfirm={handleSetAccessoriesPurchaseStatus}
    >
      <Button
        loading={isLoading}
        icon={<CheckOutlined />}
        type="primary"
        disabled={selectedLeads.length === 0}
      >
        Закуплено
      </Button>
    </Popconfirm>
  );
};

export default SuppliesPurchasedButton;
