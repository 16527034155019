import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import CreateEntity from "./components/CreateEntity";
import { fetchEntities } from "./utils/fetch";
// import { useHistory } from "react-router-dom";
import {entitiesColumn, getEntitiesDataSource} from "./utils/table";

const Entity = ({ setToken}) => {
  const [entities, setEntities] = useState([]); 
  console.log('ENTITY', entities)
  useEffect(() => {
    fetchEntities({
      setEntities: setEntities,
      setToken: setToken, 
    });
  }, [setToken]);


  return (
    <Layout>
      <PageContainer title="Реквизиты ">
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: 30,
            }}
          >
            <CreateEntity/>
          </div>
          <div style={{ overflow: "auto" }}>
        
            <ProTable
  bordered
  search={false}
  options={false}
  // loading={isLoading}
  size="small"
  columns={entitiesColumn}
  dataSource={getEntitiesDataSource(entities.entities)}
  entities={entities}
  pagination={false}
/>
          </div>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default Entity;
