import config from "../../../config.json";
import { getRequest } from "../../../utils/requests";

export const fetchUserPermission = (
  setUserPermission = () => {},
  setIsLoading = () => {}
) => {
  const url = `${config.service}/api/permission/user`;

  getRequest(url)
    .then((data) => {
      setUserPermission(data.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
    console.log('Запрос на сервер:', url);

};
