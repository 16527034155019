import { Card, Layout } from "antd";
import { PageContainer } from "@ant-design/pro-components";
import { useEffect, useState } from "react";
import {
  SuppliesLead,
  SuppliesNavBar,
  SuppliesTotal,
  SuppliesWarehouse,
} from "./components";
import { changePageTitle } from "../../utils/title";

const Supplies = () => {
  const [mod, setMod] = useState("lead");

  useEffect(() => {
    changePageTitle("Снабжения");
  }, []);

  const getBody = () => {
    if (mod === "total") {
      return <SuppliesTotal />;
    } else if (mod === "warehouse") {
      return <SuppliesWarehouse />;
    }

    return <SuppliesLead />;
  };

  return (
    <Layout>
      <Card>
        <div>
          <h2 className="mb-3">Снабжения</h2>
          <Card className="bg-white">
            <SuppliesNavBar mod={mod} setMod={setMod} />
            <div className="mt-3">{getBody()}</div>
          </Card>
        </div>
      </Card>
    </Layout>
  );
};

export default Supplies;
