import { currencyFormat } from "../../NewLead/utils/price";

export const suppliesLeadsColumn = [
  {
    title: "Дата",
    width: 100,
    dataIndex: "date",
    key: "date",
  },
  {
    title: "ФИО",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "№ дог.",
    width: 80,
    dataIndex: "agreementNumber",
    key: "agreementNumber",
  },
  {
    title: "Срок",
    width: 100,
    dataIndex: "deadline",
    key: "deadline",
  },
  {
    title: "Кол-во",
    width: 87,
    dataIndex: "amount",
    key: "amount",
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
];

export const suppliesLeadsDataSource = (applications) => {
  const result = [];

  for (let application of applications) {
    const agreementNumber = application?.lead?.agreementNumber || 0;
    let fullName = "";
    const client = application?.client[0];
    if (client) {
      fullName = client?.name || client?.companyName;
    }

    let aluminium = application.aluminium;
    if (!Array.isArray(aluminium)) {
      aluminium = [];
    }

    result.push({
      key: application._id,
      date: new Date(application.createdAt || 0).toLocaleDateString("ru-RU", {
        timeZone: "Asia/Almaty",
      }),
      deadline: new Date(application.deadline || 0).toLocaleDateString(
        "ru-RU",
        {
          timeZone: "Asia/Almaty",
        }
      ),
      aluminiumColor: aluminium.filter((v) => v !== "").join(", ") || "",
      agreementNumber,
      fullName,
      amount: application?.doorCount || 0,
    });
  }

  return result;
};

export const suppliesTotalColumn = [
  {
    title: "Название",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Кол-во",
    key: "amount",
    dataIndex: "amount",
  },
  {
    title: "Цена",
    key: "price",
    dataIndex: "price",
  },
  {
    title: "Сумма",
    key: "totalPrice",
    dataIndex: "totalPrice",
  },
];

export const suppliesTotalDataSource = (data) => {
  const result = [];

  for (let accessory of data) {
    result.push({
      name: accessory.name,
      unit: accessory.unit,
      amount: `${currencyFormat(accessory.count, 2)} ${accessory.unit}`,
      price: currencyFormat(accessory.price),
      totalPrice: currencyFormat(accessory.total),
    });
  }

  return result;
};
