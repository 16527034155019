import { MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { Link } from "react-router-dom";

const LeadPopover = ({ data, setLeadFullInfo, setShowLeadFullInfo }) => {
  return (
    <Popover
      placement="bottomRight"
      title={"Действие"}
      content={
        <LeadPopoverContent
          data={data}
          setLeadFullInfo={setLeadFullInfo}
          setShowLeadFullInfo={setShowLeadFullInfo}
        />
      }
      trigger="click"
    >
      <MoreOutlined data={data} />
    </Popover>
  );
};

const LeadPopoverContent = ({ data, setLeadFullInfo, setShowLeadFullInfo }) => {
  const clientId = data.clientId._id;
  const leadId = data._id;

  if (!clientId || !leadId) {
    return <></>;
  }

  return (
    <div>
      <Link to={`/lead/${clientId}/${leadId}/create`}>Новая спецификация</Link>
    </div>
  );
};

export default LeadPopover;
