import { Button, Card, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../config.json";
import {
  fetchSpecificationOrders,
} from "../utils/fetch";
import {
  getSpecificationProductsDataSource,
} from "../../ClientInfo/utils/table";
import { specificationProductsColumn } from "../utils/table";

const OrderStatusFullInfo = ({ data, userPermission, status }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSpecificationOrders(data._id, setOrders, setIsLoading);
  }, [data]);

  const openEditPage = () => {
    navigate(
      `/lead/${data?.lead?.clientId}/${data?.lead?._id}/${data._id}/edit`
    );
  };

  const openDocument = (type = "agreement", id) => {
    window.open(`${config.service}/api/file/${type}/${id}`);
  };

  return (
    <Card>
      <Table
        style={{
          maxWidth: 850,
          minWidth: 600,
          width: "100%",
        }}
        columns={specificationProductsColumn}
        dataSource={getSpecificationProductsDataSource(orders)}
        loading={isLoading}
        pagination={false}
      />
      <div className="mt-3 d-flex align-items-center justify-content-between">
        <div>
          {(userPermission?.isAdmin || userPermission?.agreement) && (
            <Button
              onClick={() => {
                openDocument("agreement", data.lead._id);
              }}
            >
              Договор
            </Button>
          )}
          {(userPermission?.isAdmin || userPermission?.specification) && (
            <Button
              className="mx-2"
              onClick={() => {
                openDocument("specification", data._id);
              }}
            >
              Спецификация
            </Button>
          )}
          {(userPermission?.isAdmin || userPermission?.act) && (
            <Button
              onClick={() => {
                openDocument("act", data._id);
              }}
            >
              Заявка на производство
            </Button>
          )}
          {/* {(userPermission?.isAdmin || userPermission?.deliveryCertificate) && (
            <Button
              onClick={() => {
                openDocument("delivery-certificate", data._id);
                // sendDeliveryCertification(data._id)
              }}
            >
              Акт приема-передачи
            </Button>
          )} */}
        </div>
      </div>
    </Card>
  );
};

export default OrderStatusFullInfo;
