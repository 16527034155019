import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useState } from "react";
import { deleteAccessory } from "../utils/fetch";

const AccessoryDelete = ({ accessory, fetch }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = (id) => {
    deleteAccessory(id, setIsLoading, onSuccess);
  };

  const onSuccess = () => {
    fetch();

    setIsLoading(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Tooltip title={`Удалить ${accessory.name}`}>
        <Button
          loading={isLoading}
          icon={
            <DeleteOutlined className="text-danger" style={{ fontSize: 16 }} />
          }
          onClick={() => {
            onDelete(accessory._id);
          }}
        ></Button>
      </Tooltip>
    </div>
  );
};

export default AccessoryDelete;
