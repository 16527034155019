import { calculateAssembly } from "./orderAccessories";

export const getTotalPrice = (selectedProducts, priceIncrease = 0) => {
  if (!Array.isArray(selectedProducts)) {
    return 0;
  }

  let totalPrice = 0;
  for (let product of selectedProducts) {
    const p = product.product;
    const price = product.price || p.price;

    totalPrice += price * product.amount;
  }

  const increaseValue = (totalPrice * priceIncrease) / 100;

  return totalPrice + parseInt(increaseValue);
};

export const getTotalPriceWithDiscount = (selectedProducts, priceIncrease = 0, discount = 0) => {
  if (!Array.isArray(selectedProducts)) {
    return 0;
  }
  
  let totalPrice = 0;
  for (let product of selectedProducts) {
    const p = product.product;
    let price = product.price || p.price;
    const orderDiscount = product?.orderDiscount || 0;
    price *= product?.amount || 1;

    console.log(`Initial price for ${product.name}: ${price}`);

    if (product.aluminium === "BLACK") {
      price *= 1.1;
      console.log(`Price after aluminium adjustment for ${product.name}: ${price}`);
    }


    if (product.width) {
      price = Math.round(price + price * ((product.appreciationPercentage || 0) / 100));
    console.log(`Price after appreciation for ${product.name}: ${price}`);
      // price += parseInt((priceIncrease / 100) * price);
      // console.log(`Price after overall price increase for ${product.name}: ${price}`);

      if (orderDiscount > 0) {
        price = parseInt(price - price * (orderDiscount / 100));
        console.log(`Price after order discount for ${product.name}: ${price}`);
      } else {
        price = parseInt(price - price * (discount / 100));
        console.log(`Price after general discount for ${product.name}: ${price}`);
      }
      
      if (product.doorKnob) {
        price += product.doorKnob.price * product.amount;
        console.log(`Price after adding door knob for ${product.name}: ${price}`);
      }
      if (product.doorstep) {
        price += product.doorstep.price * product.amount;
        console.log(`Price after adding doorstep for ${product.name}: ${price}`);
      }
      if (product.doorPanel) {
        price += product.doorPanel.price * product.amount;
        console.log(`Price after adding door panel for ${product.name}: ${price}`);
      }
      if (product.latch) {
        price += product.latch.price * product.amount;
        console.log(`Price after adding latch for ${product.name}: ${price}`);
      }
      if (product.assembly) {
        let assemblyPrice = calculateAssembly([product]);
        price += assemblyPrice.price * product.amount;
        console.log(`Price after adding assembly for ${product.name}: ${price}`);
      }
    }

    totalPrice += price;
    console.log(`Total price after processing ${product.name}: ${totalPrice}`);
  }

  console.log(`Final total price: ${totalPrice}`);
  return totalPrice;
};


export const currencyFormat = (num, minimumFractionDigits = 0) => {
  if (!num) {
    return 0;
  }

  if (Number.isNaN(parseInt(num))) {
    return num;
  }

  try {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits,
    });
    return formatter.format(num).replace(/,/g, " ");
  } catch (e) {
    return 0;
  }
};

export const getOrderPriceWithDiscountAndAppreciationPercentage = (productPrice, discount = 0, appreciationPercentage = 0) => {
  const priceWithAppreciationPercentage = productPrice + productPrice * (appreciationPercentage / 100);

  const resultWithDiscount = Math.ceil(priceWithAppreciationPercentage - priceWithAppreciationPercentage * (discount / 100));

  return resultWithDiscount;
};
