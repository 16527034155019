export const unitOptions = [
  { value: "м" },
  { value: "компл." },
  { value: "шт." },
];

export const formulaOptions = [
  {
    label: "Периметр",
    value: "PERIMETER",
  },
  {
    label: "По высоте",
    value: "HEIGHT",
  },
  {
    label: "Множитель",
    value: "FACTOR",
  },
];
