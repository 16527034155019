import { Image } from "antd";
import KeplerPartnerChangeImageModal from "./KeplerPartnerChangeImageModal";

const KeplerPartnerImagesSettings = ({ partner, fetch }) => {
  const logoUrl = `https://api.kepler.kz/api/kepler-partner-image/logo/${partner.logoUrl}`;
  const smallBannerUrl = `https://api.kepler.kz/api/kepler-partner-image/banner/${partner.smallBannerUrl}`;
  const largeBannerUrl = `https://api.kepler.kz/api/kepler-partner-image/banner/${partner.largeBannerUrl}`;

  return (
    <div>
      <h1>Настройки баннера</h1>
      <div className="d-flex flex-wrap">
        <div className="mt-3 me-3">
          <div className="mb-2 d-flex align-items-center">
            <div className="me-3">Логотип</div>
            <KeplerPartnerChangeImageModal
              partnerId={partner._id}
              fieldName={"logo"}
              fetch={fetch}
              label={"Логотип"}
            />
          </div>
          <Image style={{ maxWidth: 350 }} src={logoUrl} />
        </div>

        <div className="mt-3 me-3">
          <div className="mb-2 d-flex align-items-center">
            <div className="me-3">Маленький баннер</div>
            <KeplerPartnerChangeImageModal
              partnerId={partner._id}
              fieldName={"smallBannerUrl"}
              fetch={fetch}
              label={"Маленький баннер"}
            />
          </div>
          <Image style={{ maxWidth: 350 }} src={smallBannerUrl} />
        </div>

        <div className="mt-3">
          <div className="mb-2 d-flex align-items-center">
            <div className="me-3">Большой баннер</div>
            <KeplerPartnerChangeImageModal
              partnerId={partner._id}
              fieldName={"largeBannerUrl"}
              fetch={fetch}
              label={"Большой баннер"}
            />
          </div>
          <Image style={{ maxWidth: 350 }} src={largeBannerUrl} />
        </div>
      </div>
    </div>
  );
};

export default KeplerPartnerImagesSettings;
