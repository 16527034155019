import { Table } from "antd";
import { useEffect, useState } from "react";
import { fetchStatuses } from "../utils/fetch";
import {
  getOrderStatusDataSource,
  orderStatusColumnsCompleted,
} from "../utils/table";
import OrderStatusFullInfo from "./OrderStatusFullInfo";

const CompletedStatus = ({ userPermission, query = "" }) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    fetchStatuses("COMPLETED", "", query, setLeads, setIsLoading);
  }, [query]);

  return (
    <Table
      style={{ minWidth: 1000 }}
      size="small"
      bordered
      loading={isLoading}
      columns={orderStatusColumnsCompleted}
      dataSource={getOrderStatusDataSource(leads)}
      expandable={{
        expandedRowRender: (record) => (
          <OrderStatusFullInfo
            data={record.data}
            userPermission={userPermission}
          />
        ),
      }}
    />
  );
};

export default CompletedStatus;
