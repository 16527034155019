import { Form, InputNumber } from "antd";
import { ProForm } from "@ant-design/pro-components";

const PaymentFormat = ({ lead, setLead }) => {
  return (
    <>
      <h1
        style={{
          marginBottom: 15,
        }}
      >
        Формат оплаты
      </h1>
      <ProForm.Group>
        <Form.Item
          name="prepayment"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            placeholder={"Предоплата"}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, "")}
            style={{ width: 150 }}
          />
        </Form.Item>
        <Form.Item
          name="remains"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            disabled
            placeholder={"Остаток"}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, "")}
            style={{ width: 150 }}
          />
        </Form.Item>
      </ProForm.Group>
      <p
        style={{
          color: "rgb(133, 133, 133, 0.5)",
          fontSize: 12,
        }}
      >
        Предоплата и остаток
      </p>
    </>
  );
};

export default PaymentFormat;
