import { currencyFormat } from "../../NewLead/utils/price";
import config from "../../../config.json";
import { AccessoryDelete } from "../components";
import {
  AccessoryCostPrice,
  AccessoryFormula,
  AccessoryName,
  AccessoryUnit,
} from "../components/AccessoryItem";
import { Select } from "antd";
import { DOOR_TYPES_OPTIONS } from "../constants/door-type";
import { useEffect, useState } from "react";
import { updateDoorType } from "./fetch";

export const productsColumn = [
  {
    title: "",
    dataIndex: "photo",
    key: "photo",
  },
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Ширина",
    dataIndex: "width",
    key: "width",
  },
  {
    title: "Высота",
    dataIndex: "height",
    key: "height",
  },
  {
    title: "Стоимость",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Тип двери",
    dataIndex: "doorType",
    key: "doorType",
  },
];

export const productsDataSource = (products) => {
  const data = [];

  const getWidthRange = (product) => {
    if (product.doorType) {
      return `${product.minWidth}-${product.maxWidth}`;
    }

    return "-";
  };

  const getHeightRange = (product) => {
    if (product.doorType) {
      return `${product.minHeight}-${product.maxHeight}`;
    }

    return "-";
  };

  const getImage = (product) => {
    if (!product.doorType) {
      return <></>;
    }

    return (
      <div
        style={{
          width: 100,
          height: 100,
        }}
      >
        <img
          style={{
            width: 100,
            maxHeight: "100%",
            objectFit: "contain",
          }}
          src={`${config.service}/api/file/photos/${product.doorType.imgPath}`}
          alt={`${config.service}/api/file/photos/${product.doorType.imgPath}`}
        />
      </div>
    );
  };

  for (let product of products) {
    data.push({
      key: product._id,
      photo: getImage(product),
      name: product.name,
      doorType: product?.doorType?.name || "-",
      price: currencyFormat(product.price),
      width: getWidthRange(product),
      height: getHeightRange(product),
      data: product,
    });
  }

  return data;
};

export const productTypesColumn = [
  {
    title: "Название",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Ед. изм.",
    key: "unit",
    dataIndex: "unit",
  },
  {
    title: "Доб. в спец.",
    key: "isAvailableInSpec",
    dataIndex: "isAvailableInSpec",
  },
  {
    title: "Доб. в заявку на производство",
    dataIndex: "isAvailableInAct",
    key: "isAvailableInAct",
  },
  {
    title: "Считать по одн.",
    dataIndex: "countOne",
    key: "countOne",
  },
];

export const getProductTypesDataSource = (productTypes = []) => {
  const dataSource = [];

  for (let productType of productTypes) {
    dataSource.push({
      name: productType.name,
      unit: productType.unit,
      isAvailableInAct: productType?.isAvailableInAct ? "Да" : "Нет",
      isAvailableInSpec: productType?.isAvailableInSpec ? "Да" : "Нет",
      countOne: productType?.countOne ? "Да" : "Нет",
    });
  }

  return dataSource;
};

export const accessoryColumns = [
  {
    dataIndex: "name",
    key: "name",
    title: "Название",
  },
  {
    dataIndex: "costPrice",
    key: "costPrice",
    title: "Себестоимость",
    width: 120,
  },
  {
    dataIndex: "unit",
    key: "unit",
    title: "Ед. изм.",
    width: 75,
  },
  {
    dataIndex: "formula",
    key: "formula",
    title: "Формула",
    width: 130,
  },
  {
    dataIndex: "action",
    key: "action",
    title: "",
    width: 50,
  },
];

export const accessoryDataSource = (accessories, fetch) => {
  const result = [];

  for (let accessory of accessories) {
    result.push({
      name: <AccessoryName name={accessory.name} id={accessory._id} />,
      costPrice: (
        <AccessoryCostPrice
          costPrice={accessory.costPrice}
          id={accessory._id}
        />
      ),
      unit: <AccessoryUnit unit={accessory.unit} id={accessory._id} />,
      action: <AccessoryDelete accessory={accessory} fetch={fetch} />,
      formula: (
        <AccessoryFormula formula={accessory.statement} id={accessory._id} />
      ),
    });
  }

  return result;
};

export const doorTypeColumns = [
  {
    dataIndex: "image",
    width: 60,
  },
  {
    dataIndex: "name",
    title: "Название",
  },
  {
    dataIndex: "type",
    title: "Тип",
    width: 80,
  },
];

export const doorTypeDataSource = (doorTypes) => {
  const result = [];

  if (Array.isArray(doorTypes)) {
    for (let doorType of doorTypes) {
      const imgSrc = `${config.service}/api/file/photos/${doorType.imgPath}`;

      result.push({
        key: doorType._id,
        data: doorType,
        image: (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 50, margin: "0 auto" }}
          >
            <img
              style={{
                width: 50,
                height: "100%",
                objectFit: "contain",
              }}
              src={imgSrc}
            />
          </div>
        ),
        name: <div style={{ minWidth: 200 }}>{doorType.name}</div>,
        type: <DoorTypeChange data={doorType} />,
      });
    }
  }

  return result;
};

const DoorTypeChange = ({ data }) => {
  const [value, setValue] = useState(data.type);
  const [isLoading, setIsLoading] = useState(false);

  const update = (value) => {
    updateDoorType(
      data._id,
      {
        type: value,
        name: data.name,
      },
      setIsLoading
    );
  };

  return (
    <div>
      <Select
        className="w-100"
        loading={isLoading}
        disabled={isLoading}
        onChange={(v) => {
          setValue(v);

          update(v);
        }}
        value={value}
        options={DOOR_TYPES_OPTIONS}
      />
    </div>
  );
};
