import { Table } from "antd";
import { useEffect, useState } from "react";
import { fetchStatuses } from "../utils/fetch";
import {
  getOrderStatusDataSource,
  orderStatusColumnsActive,
} from "../utils/table";
import OrderStatusFullInfo from "./OrderStatusFullInfo";

const ActiveStatus = ({ userPermission, query = "" }) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    fetch();
  }, [query]);

  const fetch = () => {
    setIsLoading(true);

    setLeads([]);

    fetchStatuses("ACTIVE", "INSTALLING", query, setLeads, setIsLoading);
  };

  return (
    <>
      <Table
        size="small"
        bordered
        loading={isLoading}
        columns={orderStatusColumnsActive}
        scroll={{
          y: 500,
        }}
        dataSource={getOrderStatusDataSource(leads, fetch)}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <OrderStatusFullInfo
              data={record.data}
              userPermission={userPermission}
              status={record.data?.status}
            />
          ),
        }}
      />
    </>
  );
};

export default ActiveStatus;