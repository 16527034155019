import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Upload, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { useState } from "react";
import config from "../../../config.json";

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Размер фотографии не должен превышать 2 мб");
  }
  return isJpgOrPng && isLt2M;
};

const KeplerPartnerChangeImageModal = ({
  partnerId,
  fieldName,
  label,
  fetch,
}) => {
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (info) => {
    form.setFieldValue(fieldName, info.file);
  };
  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onFinish = () => {
    setIsLoading(true);

    const file = form.getFieldValue(fieldName);
    console.log(file);

    const formData = new FormData();
    formData.append(fieldName, file.originFileObj, file.name);

    axios
      .post(
        `${config.service}/api/kepler-partner/${partnerId}/images`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then(() => {
        setOpen(false);
        form.resetFields();
        message.success(`${label} успешно обновлен`);
        fetch();
      })
      .catch(() => {
        message.error("Не удалось добавить партнера");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button icon={<EditOutlined />} onClick={() => setOpen(true)}></Button>

      <Modal
        destroyOnClose
        visible={open}
        title={`Изменить "${label}"`}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
          setIsLoading(false);
        }}
        okText="Сохранить"
        okButtonProps={{ onClick: () => form.submit(), loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label={`Выберите ${label}:`}
            rules={[{ required: true, message: "Обязтельное поле" }]}
            className="m-0 p-0"
            labelCol={{ span: 24 }}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={beforeUpload}
              onChange={(info) => onChange(info)}
              onPreview={onPreview}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить</div>
              </div>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default KeplerPartnerChangeImageModal;
