import { TreeSelect } from "antd";

const { TreeNode } = TreeSelect;

export const getTreeNode = (products = []) => {
  if (!Array.isArray(products)) {
    return <></>;
  }

  let keys = [];

  for (let product of products) {
    const foundKey = keys.find((v) => v.value === product?.productType?._id);

    if (foundKey) {
      const newKeys = [];
      for (let key of keys) {
        if (key.value === foundKey.value) {
          key.products.push(product);
        }

        newKeys.push(key);
      }

      keys = newKeys;
    } else {
      keys.push({
        value: product?.productType?._id,
        label: product?.productType?.name,
        products: [product],
      });
    }
  }

  return (
    <>
      {keys.map((key, index) => {
        return (
          <TreeNode key={index} value={key.value} title={key.label}>
            {key.products.map((product, index) => {
              return (
                <TreeNode
                  key={index}
                  value={product._id}
                  title={product.name}
                ></TreeNode>
              );
            })}
          </TreeNode>
        );
      })}
    </>
  );
};
