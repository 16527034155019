import { useEffect, useState } from "react";
import {
  getKeplerPartnerDiscounts,
  updateKeplerPartnerDiscounts,
} from "../utils/fetch";
import { InputNumber, Spin, message } from "antd";

const KeplerPartnerDiscount = ({ keplerPartnerId }) => {
  const [discounts, setDiscounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);
    getKeplerPartnerDiscounts(keplerPartnerId)
      .then(setDiscounts)
      .finally(() => setIsLoading(false));
  };
  useEffect(fetch, [keplerPartnerId]);

  return (
    <div className="my-4">
      {isLoading && (
        <div className="my-4 d-flex justify-content-center">
          <Spin />
        </div>
      )}
      {!isLoading && (
        <div>
          <div className="d-flex">
            {discounts.map((discount) => {
              return <KeplerPartnerDiscountCard discount={discount} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const KeplerPartnerDiscountCard = ({ discount }) => {
  const [value, setValue] = useState(discount.discount);

  const update = () => {
    updateKeplerPartnerDiscounts(discount.partnerId, {
      discount: value,
      clientSegment: discount.clientSegment,
    })
      .then(() => {
        message.success("Cкидка партнера успешно обновлена");
      })
      .catch(() => {
        message.error("Не удалось обновить скидку партнера");
      });
  };

  return (
    <div className="me-3">
      <div>{discount.clientSegment}</div>
      <InputNumber
        min={0}
        max={100}
        value={value}
        addonAfter="%"
        onChange={(v) => {
          setValue(parseInt(v));
        }}
        onBlur={update}
      />
    </div>
  );
};

export default KeplerPartnerDiscount;
