import { DownOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Table } from "antd";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";
import {
  DoorType,
  CreateProduct,
  FullProductInfo,
  ProductType,
} from "./components";
import {
  fetchDoorTypes,
  fetchProducts,
  fetchProductTypes,
} from "./utils/fetch";
import { productsColumn, productsDataSource } from "./utils/table";
import { ProFormSelect } from "@ant-design/pro-components";
import CreateAccessories from "./components/CreateAccessories";
import { changePageTitle } from "../../utils/title";

const Products = ({ setToken }) => {
  const [searchQuery, setSearchQuery] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [products, setProducts] = useState([]);
  const [_total, setTotal] = useState(0);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showCreateDoorType, setShowCreateDoorType] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [accessories, setAccessories] = useState([]);
  const [accessoriesTotalCount, setAccessoriesTotalCount] = useState(0);

  useEffect(() => {
    setIsFetching(true);

    fetch({});

    changePageTitle("Товары");
  }, []);

  const fetch = () => {
    setIsLoading(true);

    fetchProducts(
      searchQuery,
      setProducts,
      setTotal,
      setIsFetching,
      setIsLoading,
      setToken
    );
  };

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Card>
      {showCreateProduct && (
        <CreateProduct
          onClose={() => setShowCreateProduct(false)}
          visible={showCreateProduct}
          fetchProducts={fetch}
          accessories={accessories}
        />
      )}
      {showCreateDoorType && (
        <DoorType
          onClose={() => setShowCreateDoorType(false)}
          visible={showCreateDoorType}
          accessories={accessories}
        />
      )}
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <Button className="me-2" onClick={() => setShowCreateProduct(true)}>
          Добавить товар
        </Button>
        <Button className="me-2" onClick={() => setShowCreateDoorType(true)}>
          Тип двери
        </Button>
        <ProductType />
        <CreateAccessories
          accessories={accessories}
          setAccessories={setAccessories}
          totalCount={accessoriesTotalCount}
          setTotalCount={setAccessoriesTotalCount}
        />
      </div>
      <Form
        className="mb-3"
        initialValues={{
          name: "",
          productType: "",
          doorType: "",
        }}
        onValuesChange={(value) => {
          setSearchQuery({
            ...searchQuery,
            ...value,
          });
        }}
      >
        <div
          className="mb-3"
          style={{
            display: "flex",
          }}
        >
          <Input
            placeholder="Поиск по ключевому слову"
            className="me-2"
            onChange={(value) => {
              setSearchQuery({
                ...searchQuery,
                name: value.target.value,
              });
            }}
          />
          <Button
            // htmlType="submit"
            onClick={() => fetch()}
            type="primary"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SearchOutlined />
            <span>Поиск</span>
          </Button>
        </div>
        <div>
          <Button
            type="text"
            className="text-primary d-flex align-items-center"
            onClick={() => setIsAdvancedSearch(!isAdvancedSearch)}
          >
            {isAdvancedSearch ? <DownOutlined /> : <RightOutlined />}
            Расширенный поиск
          </Button>
          <div
            className="d-flex align-items-center text-primary cursor-pointer mb-3"
            style={{
              fontSize: 12,
            }}
          >
            <span className="ms-1 d-flex"></span>
          </div>
        </div>
        {isAdvancedSearch && (
          <Card>
            <AdvancedSearchForm />
          </Card>
        )}
      </Form>
      <div style={{ overflow: "auto" }}>
        <Table
          size="small"
          bordered
          loading={isLoading}
          columns={productsColumn}
          dataSource={productsDataSource(products)}
          expandable={{
            expandedRowRender: (record) => (
              <FullProductInfo data={record.data} accessories={accessories} />
            ),
          }}
        />
      </div>
    </Card>
  );
};

const AdvancedSearchForm = () => {
  const [productTypes, setProductTypes] = useState([]);
  const [isLoadingProductTypes, setIsLoadingProductTypes] = useState(true);
  const [doorTypes, setDoorTypes] = useState([]);
  const [isLoadingDoorTypes, setIsLoadingDoorTypes] = useState(true);

  useEffect(() => {
    fetchProductTypes(setProductTypes, setIsLoadingProductTypes);
    fetchDoorTypes(setDoorTypes, setIsLoadingDoorTypes);
  }, []);

  if (isLoadingProductTypes || isLoadingDoorTypes) {
    return <Loading size="medium" />;
  }

  return (
    <>
      <div
        style={{
          maxWidth: 400,
        }}
      >
        <ProFormSelect
          style={{ fontSize: 12 }}
          label="Тип продукта"
          placeholder={"Выберите тип продукта"}
          name="productType"
          options={[
            {
              label: "Любой",
              value: "",
            },
            ...productTypes.map((productType, index) => {
              return {
                label: productType?.name || "",
                value: productType?._id || "",
              };
            }),
          ]}
        />
        <ProFormSelect
          style={{ fontSize: 12 }}
          label="Тип двери"
          placeholder={"Выберите тип двери"}
          name="doorType"
          options={[
            {
              label: "Любой",
              value: "",
            },
            ...doorTypes.map((doorType, index) => {
              return {
                label: doorType?.name || "",
                value: doorType?._id || "",
              };
            }),
          ]}
        />
      </div>
    </>
  );
};

export default Products;
