import {
  AuditOutlined,
  DeleteOutlined,
  FormOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Divider, Modal, Popover } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { deleteClient } from "./utils/fetch";

const ClientsPopover = ({ data }) => {
  return (
    <Popover
      placement="bottomRight"
      title={"Действие"}
      content={<ClientsPopoverContent data={data} />}
      trigger="click"
    >
      <MoreOutlined data={data} />
    </Popover>
  );
};

const ClientsPopoverContent = ({ data }) => {
  const [showNotification, setShowNotification] = useState(false);

  const onDelete = () => {
    deleteClient(data._id);
  };

  return (
    <div
      style={{
        userSelect: "none",
      }}
    >
      {showNotification && (
        <DeleteNotification
          data={data}
          onClose={() => setShowNotification(false)}
          onSubmit={onDelete}
          content={
            <>
              <p>Вы точно хотите удалить пользователя?</p>
              <p>
                <b>{data.name}</b>
              </p>
            </>
          }
        />
      )}
      <Link
        to={`/client/${data._id}`}
        style={{
          display: "flex",
          alignItems: "center",
          margin: "5px 0",
        }}
      >
        <FormOutlined />
        <div
          style={{
            marginLeft: 10,
          }}
        >
          Открыть
        </div>
      </Link>
      <a
        href={`/lead/${data._id}`}
        style={{
          display: "flex",
          alignItems: "center",
          margin: "5px 0",
        }}
      >
        <AuditOutlined />
        <div
          style={{
            marginLeft: 10,
          }}
        >
          Новый договор
        </div>
      </a>
      <Divider />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "5px 0",
          color: "red",
          cursor: "pointer",
        }}
      >
        <DeleteOutlined />
        <div
          style={{
            marginLeft: 10,
          }}
          onClick={() => setShowNotification(true)}
        >
          Удалить
        </div>
      </div>
    </div>
  );
};

export const DeleteNotification = ({
  content,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const handleDelete = () => {
    onSubmit();
  };

  return (
    <Modal
      title="Внимание!"
      visible={true}
      onOk={handleDelete}
      onCancel={onClose}
      okText="Удалить"
      cancelText="Отменить"
      okButtonProps={{
        style: {
          backgroundColor: "red",
          borderColor: "red",
        },
      }}
    >
      {content}
    </Modal>
  );
};

export default ClientsPopover;
