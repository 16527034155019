import { Modal, Tooltip, Button, message } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {useState} from "react";
import { accept, acceptAccessories, completeAssembly, returnToAssembly, sendPickupCodeToClient, confirmPickup } from "../utils/fetch";
import config from "../../../config.json";
import PinInput from "react-pin-input";

export const AcceptAccessoriesButton = ({ lead, fetch }) => {
  const [showAcceptAccessoriesModal, setShowAcceptAccessoriesModal] = useState(false);

  const content = (
    <>
        <p>Убедитесь, что фурнитура в верном объеме и нужного качества. </p>
        <p>Вы точно хотите принять фурнитуру?</p>
    </>
  )
  
    return (
      <>
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            onClick={() => {
              setShowAcceptAccessoriesModal(true);
            }}
          >
            Принять фурнитуру
          </Button>
        <AssemblyModal 
          visible = {showAcceptAccessoriesModal}
          onSubmit = {() => {
            acceptAccessories(lead._id, fetch)
            setShowAcceptAccessoriesModal(false)
          }}
          onCancel = {() => setShowAcceptAccessoriesModal(false)}
          okText = "Принять фурнитуру"
          content = {content}
        />
    </>
    )
}

export const AcceptButton = ({ lead, fetch }) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const content = (
    <>
        <p>Вы точно хотите начать сборку?</p>
    </>
  )
  
    return (
      <>
        <Tooltip title="Начать сборку">
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            onClick={() => {
              setShowAcceptModal(true);
            }}
          >
            <CheckOutlined />
          </Button>
        </Tooltip>
        <AssemblyModal 
          visible = {showAcceptModal}
          onSubmit = {() => {
            accept(lead._id, fetch)
            setShowAcceptModal(false)
          }}
          onCancel = {() => setShowAcceptModal(false)}
          okText = "Подтвердить"
          content = {content}
        />
    </>
    )
}

export const CompleteAssemblyButton = ({ lead, fetch }) => {
    const [showCompleteAccessoriesModal, setShowCompleteAccessoriesModal] = useState(false);

    const openDocument = (type = "agreement", id) => {
      window.open(`${config.service}/api/file/${type}/${id}`);
    };

    const onClick = () => {
      if (!lead.accessoriesAcceptedDate) {
        return message.error('Фурнитура не принята. Нажмите "Принять фурнитуру"', 5)
      }

      setShowCompleteAccessoriesModal(true);
    }

    const onSubmit = () => {
      completeAssembly(lead._id, lead.isHasDelivery, fetch)
      // openDocument("delivery-certificate", lead._id);
      setShowCompleteAccessoriesModal(false)
    }
  
    const content = (
      <>
          <p>Вы точно хотите завершить сборку?</p>
      </>
    )
      return (
        <>
          <Tooltip title="Завершить сборку">
            <Button
              style={{
                padding: "5px 10px",
                color: "green",
                marginRight: 10,
              }}
              onClick={() => onClick()}
            >
              <CheckOutlined />
            </Button>
          </Tooltip>
          <AssemblyModal 
            visible = {showCompleteAccessoriesModal}
            onSubmit = {() => onSubmit()}
            onCancel = {() => setShowCompleteAccessoriesModal(false)}
            okText = "Подтвердить"
            content = {content}
          />
      </>
      )
}

export const ConfirmPickupButton = ({ lead, fetch }) => {
  const [showConfirmPickupModal, setShowConfirmPickupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('')

  // const onClick = async (phone, id) => {
  //   setIsLoading(true);
  
  //   try {
  //     await sendPickupCodeToClient(id, phone);
  //     message.success("Сообщение успешно отправлено", 3);
  //     setShowConfirmPickupModal(true);
  //   } catch (err) {
  //     let msg = "Неизвестная ошибка";
  //     if (err?.response?.data?.message) {
  //       msg = err.response.data.message;
  //     }
  //     message.error(msg);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };  

  const onClick = async (phone, id) => {
    setIsLoading(true);
    sendPickupCodeToClient(id, phone, 
      msg => {
        if (msg) {
            message.success(msg, 3)
        }
        setShowConfirmPickupModal(true)
      },
    )
    .catch((err) => {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err?.response?.data?.message;
      }

      message.error(msg);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const onSubmit = (pin, lead) => {
    setIsLoading(true);
    confirmPickup(pin, lead,
      msg => {
        if (msg) {
            message.success(msg, 5)
        }
        setShowConfirmPickupModal(false)
      },
      () => {
        message.error("Неверный код", 3)
      },
      () => {
        fetch();
        setIsLoading(false);
      }
    )
    .catch((err) => {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err?.response?.data?.message;
      }

      message.error(msg);
    })
  }

  const content = (
    <>
        <p>Введите код, который клиент получил на WhatsApp</p>
        <div className="my-3">
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, _index) => {
            setPin(value);
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{ borderColor: "#dee2e6" }}
          inputFocusStyle={{ borderColor: "#1890ff" }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </div>
    </>
  )
    return (
      <>
        <Tooltip title="Выдать заказ">
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            loading = {isLoading}
            onClick={() => {
              onClick(lead.client[0].phone, lead._id)
            }}
          >
              <CheckOutlined/>
          </Button>
          </Tooltip>
        <AssemblyModal
          visible = {showConfirmPickupModal}
          onSubmit = {() => {
            onSubmit(pin, lead)
          }}
          onCancel = {() => setShowConfirmPickupModal(false)}
          okText = "Отправить"
          content = {content}
          loading = {isLoading}
        />
    </>
    )
}

export const ReturnToAssemblyButton = ({ lead, fetch }) => {
    const [showReturnToAssemblyModal, setShowReturnToAssemblyModal] = useState(false);
    
    const content = (
      <>
            <p>Вы точно хотите вернуть заказ на сборку?</p>
          </>
    )
    
      return (
        <>
          <Tooltip title="Вернуть на сборку">
            <Button
              style={{
                padding: "5px 10px",
                color: "red",
                marginRight: 10,
              }}
              onClick={() => {
                setShowReturnToAssemblyModal(true);
              }}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
          <AssemblyModal 
            visible = {showReturnToAssemblyModal}
            onSubmit = {() => {
              returnToAssembly(lead._id, fetch)
              setShowReturnToAssemblyModal(false)
            }}
            onCancel = {() => setShowReturnToAssemblyModal(false)}
            okText = "Подтвердить"
            content = {content}
          />
      </>
      )
    }

const AssemblyModal = ({
  visible,
  onSubmit,
  onCancel,
  okText,
  content,
}) => {
  return (
    <Modal
    title="Внимание!"
    visible={visible}
    onOk={onSubmit}
    onCancel={onCancel}
    okText={okText}
    cancelText="Закрыть"
    okButtonProps={{
      style: {
        backgroundColor: "red",
        borderColor: "red",
      },
    }}
  >
    {content}
  </Modal>
  )
}  