import { message } from "antd";
import axios from "axios";
import config from "../../../config.json";
import { getRequest, patchRequest, postRequest } from "../../../utils/requests";

export const fetchProducts = (
  searchQuery = {},
  setProducts = () => {},
  setTotalPage = () => {},
  setIsFetching = () => {},
  setIsLoading = () => {},
  setToken = () => {}
) => {
  const request = axios.get(
    `${config.service}/api/product?name=${
      searchQuery?.name || ""
    }&productType=${searchQuery?.productType || ""}&doorType=${
      searchQuery?.doorType || ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );

  request
    .then((data) => {
      setProducts(data.data?.products || []);
      setTotalPage(data.data?.totalPages || 0);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("accessToken");
        setToken("");
      }
    })
    .finally(() => {
      setIsLoading(false);
      setIsFetching(false);
    });
};

export const fetchProductTypes = (
  setProductTypes = () => {},
  setIsLoading = () => {}
) => {
  setIsLoading();

  const url = `${config.service}/api/product/type`;

  getRequest(url)
    .then((data) => {
      setProductTypes(data.data);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("accessToken");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const createProductType = (body, onFinish = () => {}) => {
  const hideLoadingMsg = message.loading("Загузка...", 0);

  postRequest(`${config.service}/api/product/type`, body)
    .then(() => {
      message.success("Тип продукта успешно создан");
    })
    .catch(() => {
      message.error("Не удалось создать тип продука");
    })
    .finally(() => {
      onFinish();
      hideLoadingMsg();
    });
};

export const fetchDoorTypes = (
  setDoorTypes = () => {},
  setIsLoading = () => {}
) => {
  const request = axios.get(`${config.service}/api/product/door-type`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });

  request
    .then((data) => {
      setDoorTypes(data.data);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("accessToken");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const createProduct = (
  body,
  setIsLoading = () => {},
  fetchProducts = () => {}
) => {
  const hideLoadingMsg = message.loading("Загрузка", 0);

  const url = `${config.service}/api/product`;

  axios
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then(() => {
      message.success("Товар добавлен", 3);

      fetchProducts();
    })
    .catch((err) => {
      message.error("Не удалось добавить товар", 3);
    })
    .finally(() => {
      hideLoadingMsg();
      setIsLoading(false);
    });
};

export const saveDoorType = (
  body,
  setIsLoading = () => {},
  onClose = () => {}
) => {
  const hideLoadingMsg = message.loading("Загрузка", 0);
  setIsLoading(true);

  const url = `${config.service}/api/product/door-type`;

  axios
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then(() => {
      message.success("Сохранено", 3);
      onClose();
    })
    .catch((err) => {
      let msg = "Ошибка при загрузке";

      if (err?.response?.data?.message) {
        msg = err?.response?.data?.message || "";
      }

      message.error(msg, 3);
    })
    .finally(() => {
      setIsLoading(false);
      hideLoadingMsg();
    });
};

export const deleteProduct = (id) => {
  const hideLoadingMsg = message.loading("Удаление");

  const url = `${config.service}/api/product/${id}`;

  axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then(() => {
      window.location.reload();
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const updateProduct = (id, body) => {
  const hideLoadingMsg = message.loading("Обновляется", 0);

  const url = `${config.service}/api/product/${id}`;

  axios
    .patch(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then(() => {
      message.success("Товар успешно обновлен", 3);
    })
    .catch(() => {
      message.error("Не удалось обновить товар", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const fetchAccessories = (
  setAccessories,
  setTotalCount,
  setIsLoading
) => {
  const url = `${config.service}/api/accessories`;

  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((data) => {
      setAccessories(data.data?.accessories || []);
      setTotalCount(data.data?.totalCount || 0);
    })
    .catch(() => {
      message.error("Не удалось загрузить список комплектующих", 3);
    })
    .finally(() => {
      setIsLoading();
    });
};

export const createAccessory = (body, setIsLoading, onSuccess) => {
  setIsLoading(true);

  const url = `${config.service}/api/accessories`;

  axios
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then(() => {
      message.success("Комплектующий успешно сохранен", 3);

      onSuccess();
    })
    .catch(() => {
      message.error("Не удалось сохранить комплектующего", 3);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const deleteAccessory = (id, setIsLoading, onSuccess) => {
  setIsLoading(true);

  const url = `${config.service}/api/accessories/${id}`;

  axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      message.error("Не удалось удалить комплектующего", 3);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const updateAccessory = (id, body, setIsLoading) => {
  if (setIsLoading) {
    setIsLoading(true);
  }

  const url = `${config.service}/api/accessories/${id}`;

  axios
    .patch(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then(() => {
      message.success("Комплектующий обновлен", 2);
    })
    .catch(() => {
      message.error("Не удалось обновить комплектующего", 3);
    })
    .finally(() => {
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
};

export const updateDoorType = (id, body, setIsLoading) => {
  setIsLoading(true);

  const url = `${config.service}/api/product/door-type/${id}`;

  patchRequest(url, body)
    .then(() => {
      message.success("Тип двери обновлен");
    })
    .catch(() => {
      message.error("Не удалось обновить");
    })
    .finally(() => {
      setIsLoading(false);
    });
};
