import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../../../config.json";

const KeplerPartnerActivations = ({ partnerId }) => {
  const [activations, setActivations] = useState({
    today: 0,
    week: 0,
    total: 0,
  });

  useEffect(() => {
    const fetchActivations = async () => {
      try {
        const response = await axios.get(`${config.service}/api/promo-code/${partnerId}/stats`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setActivations(response.data);
      } catch (error) {
        console.error("Error fetching activations data:", error);
      }
    };

    fetchActivations();
  }, [partnerId]);

  return (
    <div>
      <h3>Активации промокодов</h3>
      <p>За месяц: {activations.month}</p>
      <p>За все время: {activations.total}</p>
    </div>
  );
};

export default KeplerPartnerActivations;
