import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import { fetchSuppliesLeadsCalc } from "../utils/fetch";
import { suppliesTotalColumn, suppliesTotalDataSource } from "../utils/table";

export const SuppliesLeadCalc = ({ id }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, [id]);

  const fetch = () => {
    fetchSuppliesLeadsCalc(id, setData, setIsLoading);
  };

  return (
    <Card className="lead-calc">
      <Table
        loading={isLoading}
        bordered
        size="small"
        pagination={false}
        columns={suppliesTotalColumn}
        dataSource={suppliesTotalDataSource(data)}
      />
    </Card>
  );
};

export default SuppliesLeadCalc;
