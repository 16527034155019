import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";
import { getRowColor, getStatusColor } from "./color";
import {
  acceptApplication,
  refuseApplication,
  updateApplication,
  updateApplicationStatus,
} from "./fetch";
import { formatStringToDate } from "./format";
import { getRowColorForDate } from "../../ManufactureStatus/utils/color";
import { getDeadlineColor } from "../../../utils/deadlineColor";
import { ConfirmPickupButton } from "../../AssemblyStatus/components/AssemblyActionModal";

export const orderStatusColumnsNew = [
  {
    key: "sentToProductionDate",
    title: "Дата",
    dataIndex: "sentToProductionDate",
    sorter: (a, b) =>
      formatStringToDate(a.sentToProductionDate).getTime() -
      formatStringToDate(b.sentToProductionDate).getTime(),
    width: 100,
  },
  {
    key: "fullName",
    title: (
      <div
        style={{
          width: 500,
        }}
      >
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 120,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "deadlineForAcception",
    title: "Срок принятия",
    dataIndex: "deadlineForAcception",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadlineForAcception).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadlineForAcception).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "amount",
    title: (
      <div
        style={{
          width: 56,
        }}
      >
        Кол-во
      </div>
    ),
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 65,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "montage",
    title: "Монтаж",
    dataIndex: "montage",
    width: 75,
  }, 
  // действия над заявкой (принять, отказаться)
  // {
  //   key: "actions",
  //   title: "",
  //   dataIndex: "actions",
  //   width: 100,
  // },
];

export const getOrderStatusDataSource = (leads, fetch) => {
  const statusOptions = [
    {
      label: "Сборка каркаса",
      value: "ASSEMBLING_CARCASS",
    },
    {
      label: "Отправлен на пресс",
      value: "SENT_TO_PRESS",
    },
    {
      label: "Грунтовка",
      value: "PRIMING",
    },
    {
      label: "Шпонирование",
      value: "VENEERING",
    },
    {
      label: "Покраска",
      value: "PAINTING",
    },
    {
      label: "Зеркало",
      value: "MIRROR",
    },
    {
      label: "Сборка алюминия",
      value: "ALUMINUM_ASSEMBLING",
    },
    {
      label: "На производстве",
      value: "PRODUCTION",
    },
    {
      label: "Передан на сборку",
      value: "PASSED_TO_ASSEMBLY",
    },
    {
      label: "Сборка", // Фрезеровка петель
      value: "LOOP_MILLING",
    },
    {
      label: "Упаковка",
      value: "PACKAGING",
    },
    {
      label: "Готов к отгрузке",
      value: "READY_FOR_SHIPMENT",
    },
    {
      label: "Монтаж",
      value: "INSTALLING",
    },
    {
      label: "На доставке",
      value: "ON_DELIVERY",
    },
    {
      label: "Передан на монтаж",
      value: "PASSED_TO_MONTAGE",
    },
    {
      label: "Заказ сдан",
      value: "ORDER_INSTALLED",
    },
    {
      label: "Заказ сдан",
      value: "ORDER_DELIVERED",
    },
  ];

  const remainsOptions = [
    {
      label: "Оплачено",
      value: true,
    },
    {
      label: "Не оплачено",
      value: false,
    },
  ];

  const getStatusLabel = (lead) => {
    const statusValue = lead.status; 
    if (lead.isHasMontage && statusValue === "ORDER_DELIVERED") {
      return "Передан монтаж"
    }
    const statusOption = statusOptions.find(option => option.value === statusValue);
    return statusOption ? statusOption.label : "";
  };

  
  const getClientName = (lead, client) => {
    if (lead.state === "ACTIVE") {
      if (!lead?.type) {
        return (
          <div style={{
            position: "relative",
          }}>
            {client?.name || client?.companyName}
            <div
              style={{
                position:"absolute",
                top: "-12px",
                color: "red",
                fontSize: "9px",
              }}
            >
              OLD
            </div>
          </div>
        )
      } else if (!lead?.sentToProductionDate) {
        return client?.name || client?.companyName
      } else {
        if (!lead?.accessoriesAcceptedDate) {
          return (
            <div style={{
              position: "relative",
            }}>
              {client?.name || client?.companyName}
              <div
                style={{
                  position:"absolute",
                  top: "-12px",
                  color: "red",
                  fontSize: "9px",
                }}
              >
                Фурнитура отсутствует
              </div>
            </div>
          )
        } else return client?.name || client?.companyName
      }
    } else return client?.name || client?.companyName
  }
  
  const dataSource = [];
  for (let lead of leads) {
    const client = lead.client[0];

    const user = lead?.user;

    let responsibleUser = "";
    if (user) {
      responsibleUser = `${user.name} ${user.surname}`;
    }

    let props = {};
    if (lead.state === "NEW") {
      props = getDeadlineColor(lead?.acceptionDeadlineForProduction)
    }

    if (lead.state === "ACTIVE") {
      props = getStatusColor(lead)
    }

    let aluminiumColor = "";
    if (Array.isArray(lead?.aluminium)) {
      aluminiumColor = lead?.aluminium.filter((v) => v !== "").join(", ");
    }

    const getActions = (lead) => {if (lead.state === "ACTIVE" && lead.status === "READY_FOR_SHIPMENT" && !lead.isHasDelivery) {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ConfirmPickupButton lead={lead} fetch={fetch}/>
          </div>
        )
      }
    }

    dataSource.push({
      key: lead._id,
      data: lead,
      montage: lead.isHasMontage ? "Есть" : "Нет",
      responsibleUser,
      agreementNumber: lead?.lead?.agreementNumber,
      phoneNumber: client?.phone,
      address: lead?.lead?.address,
      // fullName: client?.name || client?.companyName,
      fullName: getClientName(lead, client),
      deadline: (
        <div {...props}>{new Date(lead?.deadline).toLocaleDateString()}</div>
      ),
      deadlineForAcception: (
        <div {...props}>{new Date(lead?.acceptionDeadlineForProduction).toLocaleDateString()}</div>
      ),
      amount: lead?.doorCount ? parseInt(lead?.doorCount) : 0,
      aluminiumColor,
      date: new Date(lead?.createdAt).toLocaleDateString(),
      sentToProductionDate: new Date(lead?.sentToProductionDate).toLocaleDateString(),
      actions: getActions(lead),
      // (
      //   <div
      //     style={{
      //       width: "100%",
      //       display: "flex",
      //       justifyContent: "center",
      //     }}
      //   >
      //     <Tooltip title="Принять">
      //       <Button
      //         style={{
      //           padding: "5px 10px",
      //           color: "green",
      //           marginRight: 10,
      //         }}
      //         onClick={() => {
      //           acceptApplication(lead._id, fetch);
      //         }}
      //       >
      //         <CheckOutlined />
      //       </Button>
      //     </Tooltip>
      //     <Tooltip title="Отклонить">
      //       <Button
      //         style={{
      //           padding: "5px 10px",
      //           color: "red",
      //         }}
      //         onClick={() => {
      //           refuseApplication(lead._id, fetch);
      //         }}
      //       >
      //         <CloseOutlined />
      //       </Button>
      //     </Tooltip>
      //   </div>
      // ),
      status: (
        <Tooltip title={getStatusLabel(lead)}>
        <div
          style={{
            // backgroundColor: getStatusBgColor(lead.status),
            border: "1px solid #d9d9d9",
            borderRadius: "2px",
            width: "100%",
            height: "32px",
            padding: "4px 11px",
            overflow: "hidden",
            fontWeight: "normal",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          {...getStatusColor(lead)}
          > 
          {/* {getstatusColor(lead)} */}
        {getStatusLabel(lead)}
          {/* <Select
            defaultValue={lead.status}
            placeholder="Статус заявки"
            style={{
              width: 120,
              background: getStatusBgColor(lead.status),
            }}
            options={statusOptions}
            className={lead.status}
            onChange={(v) => {
              updateApplicationStatus(
                lead._id,
                {
                  status: v,
                },
                fetch
              );
            }}
          /> */}
        </div>
        </Tooltip>
      ),
      remains: (
        <Select
          defaultValue={lead.isPaidRemainder}
          style={{
            width: 100,
          }}
          options={remainsOptions}
          onChange={(v) => {
            updateApplication(
              lead._id,
              {
                isPaidRemainder: v,
              },
              fetch
            );
          }}
        />
      ),
    });
  }

  console.log('datasource', dataSource);
  return dataSource;
};

export const orderStatusColumnsActive = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 95,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "agreementNumber",
    title: <div>№ дог.</div>,
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 200,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "amount",
    title: <div>Кол-во</div>,
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 85,
  },

  {
    key: "montage",
    title: "Монтаж",
    dataIndex: "montage",
    width: 75,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Статус",
    width: 135, // 135
    fixed: "right",
  },
  {
    key: "remains",
    dataIndex: "remains",
    title: "Остаток",
    width: 120,
    fixed: "right",
  },
  {
    key: "actions",
    title: "Самовывоз",
    dataIndex: "actions",
    width: 100,
    fixed: "right",
  },
];

export const orderStatusColumnsBooking = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 95,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "agreementNumber",
    title: <div>№ дог.</div>,
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 200,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "amount",
    title: <div>Кол-во</div>,
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 85,
  },

  {
    key: "montage",
    title: "Монтаж",
    dataIndex: "montage",
    width: 75,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
];

export const orderStatusColumnsCompleted = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
  },
  {
    key: "amount",
    title: "Кол-во",
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 87,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 130,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
];

export const orderStatusColumnsRemaining = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 95,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "agreementNumber",
    title: <div>№ дог.</div>,
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 200,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "amount",
    title: <div>Кол-во</div>,
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 85,
  },

  {
    key: "montage",
    title: "Монтаж",
    dataIndex: "montage",
    width: 75,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Статус",
    width: 135, // 135
    fixed: "right",
  },
  {
    key: "remains",
    dataIndex: "remains",
    title: "Остаток",
    width: 120,
    fixed: "right",
  },
];