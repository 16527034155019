import { ModalForm } from "@ant-design/pro-components";
import { Segmented } from "antd";
import { useState } from "react";
import { CreateDoorType, ListDoorType } from "./";

const DoorType = ({ visible, onClose, accessories }) => {
  const [mod, setMod] = useState("create");

  const getContent = () => {
    if (mod === "edit") {
      return <ListDoorType accessories={accessories} />;
    }

    return <CreateDoorType accessories={accessories} />;
  };

  return (
    <ModalForm
      visible={visible}
      onVisibleChange={(v) => {
        if (!v) {
          onClose();
        }
      }}
      title="Тип двери"
      submitter={{ render: () => <></> }}
    >
      <Segmented
        block
        value={mod}
        onChange={setMod}
        options={[
          {
            label: "Создать",
            value: "create",
          },
          {
            label: "Редактировать",
            value: "edit",
          },
        ]}
      />
      <div className="mt-3">{getContent()}</div>
    </ModalForm>
  );
};

export default DoorType;
