export const getAluminium = (aluminium) => {
  // Фильтруем массив aluminium, удаляем пустые строки
  const filtered = aluminium.filter((item) => item !== "")

  // Если после фильтрации остался только один элемент, возвращаем его
  if (filtered.length === 1) {
    return filtered[0]
  } else {
    // Иначе соединяем оставшиеся элементы в строку с разделителями
    return filtered.join(', ')
  }
}

export const getModalInnerText = (lead) => {
  const deadline = new Date(lead.productionDeadline).toLocaleDateString()

  return (
    <div>
      <p style={{ marginBottom: 0 }}>Номер договора: <b>{lead.agreementNumber}</b></p>
      <p style={{ marginBottom: 0 }}>Имя: <b>{lead.client[0].name}</b></p>
      <p style={{ marginBottom: 0 }}>Кол-во: <b>{lead.doorCount}</b></p>
      <p style={{ marginBottom: 0 }}>Цвет: <b>{getAluminium(lead.aluminium)}</b></p>
      <p style={{ marginBottom: 0 }}>Срок изготовления: <b>{deadline}</b></p>
    </div>
  )
}
