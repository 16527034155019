import { getRequest, patchRequest, postRequest } from "../../../utils/requests";
import config from "../../../config.json";
import { message } from "antd";

export const fetchUsers = (setUsers = () => {}, setIsLoading = () => {}) => {
  const url = `${config.service}/api/user`;
  setIsLoading(true);

  const response = getRequest(url);

  response
    .then((data) => {
      setUsers(data.data.users);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchCompanyUsers = (
  id,
  setUsers = () => {},
  setIsLoading = () => {}
) => {
  const url = `${config.service}/api/user/company/${id}`;
  setIsLoading(true);

  const response = getRequest(url);

  response
    .then((data) => {
      setUsers(data.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const updateUser = async (id, body, onFinish = () => {}) => {
  const url = `${config.service}/api/user/${id}`;

  const hideLoadingMsg = message.loading("Обновляется", 0);

  const response = patchRequest(url, body);

  try {
    await response.then(() => {});

    await patchRequest(`${config.service}/api/permission/user/${id}`, body);

    message.success("Пользователь успешно обновлен", 2);
    hideLoadingMsg();
    onFinish();
  } catch (e) {}
};

export const fetchCompanies = (
  setCompanies = () => {},
  setIsLoading = () => {}
) => {
  const url = `${config.service}/api/company`;
  setIsLoading(true);

  const response = getRequest(url);

  response
    .then((data) => {
      setCompanies(data.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const createCompany = (
  body,
  setIsLoading = () => {},
  setCompanies = () => {}
) => {
  const hideLoadingMsg = message.loading("Сохраняется", 0);

  const url = `${config.service}/api/company`;

  const response = postRequest(url, body);

  response
    .then(() => {
      message.success("Компания успешно создана", 2);

      fetchCompanies(setCompanies);
    })
    .catch(() => {
      message.error("Не удалось сохранить компанию", 2);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const createUser = (body, onFinish) => {
  const hideLoadingMsg = message.loading("Сохраняется", 0);

  const url = `${config.service}/api/user`;

  const response = postRequest(url, body);

  response
    .then(() => {
      message.success("Пользователь успешно создан", 2);

      onFinish();
    })
    .catch(() => {
      message.error("Не удалось сохранить пользователя", 2);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const fetchRoles = (setRoles = () => {}, setIsLoading = () => {}) => {
  const url = `${config.service}/api/role`;
  setIsLoading(true);

  const response = getRequest(url);

  response
    .then((data) => {
      setRoles(data.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchCompanyPermissions = (
  companyId,
  setCompanyPermissions = () => {},
  setIsLoading = () => {}
) => {
  const url = `${config.service}/api/permission/company/${companyId}`;

  getRequest(url)
    .then((data) => {
      setCompanyPermissions(data.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
};
