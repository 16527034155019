import { Button, Form, Input, Select, Upload } from "antd";
import { useState } from "react";
import { saveDoorType } from "../utils/fetch";
import ProductAccessories from "./ProductAccessories";
import { DOOR_TYPES_OPTIONS } from "../constants/door-type";

const CreateDoorType = ({ accessories }) => {
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("SINGLE_LEAF");

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleSave = () => {
    const formdata = getBody();

    saveDoorType(formdata, setIsLoading);
  };

  const getBody = () => {
    const image = fileList[0].originFileObj;

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("image", image, image.name);
    formdata.append("type", type);

    for (let selectedAccessory of selectedAccessories) {
      formdata.push("accessories", selectedAccessory);
    }

    return formdata;
  };

  const setSelectedAccessory = (id) => {
    if (!Array.isArray(selectedAccessories)) {
      return;
    }

    const exists = selectedAccessories.includes(id);

    if (!exists) {
      setSelectedAccessories([...selectedAccessories, id]);
    } else {
      const result = [];

      for (let a of selectedAccessories) {
        if (a !== id) {
          result.push(a);
        }
      }

      setSelectedAccessories(result);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <div>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            accept={".png, .jpeg, .jpg"}
          >
            {fileList.length < 1 && "+ Загрузить"}
          </Upload>
        </div>
      </div>
      <Form.Item
        style={{
          marginTop: 15,
        }}
        name="name"
      >
        <div className="mb-1">Наименование:</div>
        <Input
          placeholder="Введите наименование..."
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </Form.Item>
      <div className="mb-3">
        <div className="mb-1">Тип:</div>
        <Select
          value={type}
          onChange={setType}
          className="w-100"
          options={DOOR_TYPES_OPTIONS}
        />
      </div>
      <ProductAccessories
        selectedAccessories={selectedAccessories}
        accessories={accessories}
        setSelected={setSelectedAccessory}
      />
      <div className="d-flex justify-content-end mt-3">
        <Button
          htmlType="submit"
          type="primary"
          disabled={fileList.length === 0 || name.trim() === ""}
          onClick={handleSave}
          loading={isLoading}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
};

export default CreateDoorType;
