import {
  ModalForm,
  ProFormGroup,
  ProFormText,
  ProFormCheckbox,
} from "@ant-design/pro-components";
import { Button, Collapse, Divider, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import { createProductType, fetchProductTypes } from "../utils/fetch";
import { getProductTypesDataSource, productTypesColumn } from "../utils/table";

const { Panel } = Collapse;

const ProductType = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [productTypes, setProductTypes] = useState([]);
  const [isCreatingLoading, setIsCreatingLoading] = useState(false);
  const [form] = useForm();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    fetchProductTypes(setProductTypes, setIsLoading);
  };

  const handleSubmit = (values) => {
    setIsCreatingLoading(true);

    createProductType(values, () => {
      form.resetFields();
      setIsCreatingLoading(false);
      fetch();
    });
  };

  return (
    <ModalForm
      visible={visible}
      onVisibleChange={setVisible}
      form={form}
      trigger={<Button className="me-2">Тип товара</Button>}
      title="Тип товара"
      style={{}}
      initialValues={{
        name: "",
        unit: "",
        isAvailableInAct: true,
        isAvailableInSpec: true,
        countOne: true,
      }}
      onFinish={(values) => {
        handleSubmit(values);
      }}
      submitter={{
        submitButtonProps: {
          loading: isCreatingLoading,
        },
        resetButtonProps: {
          onClick: () => {
            setVisible(false);
            form.resetFields();
          },
        },
      }}
    >
      <>
        <ProFormGroup className="w-100">
          <ProFormText
            className="w-75"
            label="Название"
            name="name"
            placeholder="Название"
            rules={[{ required: true }]}
          />
          <ProFormText
            className="w-25"
            label="Ед. измерение"
            name="unit"
            placeholder="Ед. измерение"
            rules={[{ required: true }]}
          />
        </ProFormGroup>
        <Collapse>
          <Panel header="Дополнительно">
            <ProFormCheckbox className="d-flex" name="isAvailableInAct">
              Добавить в заявку на производство
            </ProFormCheckbox>
            <ProFormCheckbox name="isAvailableInSpec">
              Добавить в Спецификацию
            </ProFormCheckbox>
            <ProFormCheckbox name="countOne">Считать по одному</ProFormCheckbox>
          </Panel>
        </Collapse>
        <Divider />
        <ProductTypeItems productTypes={productTypes} isLoading={isLoading} />
      </>
    </ModalForm>
  );
};

const ProductTypeItems = ({ productTypes, isLoading }) => {
  return (
    <Table
      size="small"
      loading={isLoading}
      bordered
      columns={productTypesColumn}
      dataSource={getProductTypesDataSource(productTypes)}
      pagination={false}
    />
  );
};

export default ProductType;
