import { currencyFormat, getTotalPriceWithDiscount } from "../utils/price";
import { ProFormCheckbox, ProDescriptions } from "@ant-design/pro-components";

const PriceInfo = ({ selectedProducts, discount, increasePrice }) => {
  const totalPriceWithDiscount = getTotalPriceWithDiscount(
    selectedProducts,
    increasePrice,
    discount
  );

  return (
    <div
      style={{
        maxWidth: 650,
        marginTop: 30,
      }}
    >
      <ProDescriptions column={2}>
        <ProDescriptions.Item label="Общая сумма">
          <b>{currencyFormat(totalPriceWithDiscount)}&nbsp;(тенге)</b>
        </ProDescriptions.Item>

        <ProDescriptions.Item>
          <ProFormCheckbox
            style={{
              margin: 0,
            }}
            name="hasSignature"
          >
            Документы с печатью
          </ProFormCheckbox>
        </ProDescriptions.Item>
      </ProDescriptions>
    </div>
  );
};

export default PriceInfo;
