import { UpdateUser } from "../components";

export const usersColumn = [
  {
    title: "Имя",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Фамилия",
    key: "surname",
    dataIndex: "surname",
  },
  {
    title: <div>Номер телефона</div>,
    key: "phone",
    dataIndex: "phone",
  },
  {
    title: "Компания",
    key: "company",
    dataIndex: "company",
  },
  {
    title: "Роль",
    key: "role",
    dataIndex: "role",
  },
  {
    title: <div>Дата&nbsp;регистрации</div>,
    key: "registrationDate",
    dataIndex: "registrationDate",
  },
  {
    title: "",
    fixed: "right",
    key: "actions",
    dataIndex: "actions",
    width: 100,
  },
];

export const getUsersDataSource = (clients, companyPermissions, fetch) => {
  const data = [];

  for (let client of clients) {
    data.push({
      registrationDate: new Date(client.createdAt).toLocaleDateString(),
      company: client?.companyId?.name || "",
      role: client?.roleId?.name || "",
      phone: client?.phone || "",
      name: client?.name || "",
      surname: client?.surname || "",
      actions: (
        <UpdateUser
          data={client}
          companyPermissions={companyPermissions}
          fetch={fetch}
        />
      ),
    });
  }

  return data;
};
