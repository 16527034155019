import { Table } from "antd";
import { useEffect, useState } from "react";
import { fetchDoorTypes } from "../utils/fetch";
import { doorTypeColumns, doorTypeDataSource } from "../utils/table";
import DoorTypeAccessories from "./DoorTypeAccessories";

const ListDoorType = ({ accessories }) => {
  const [doorTypes, setDoorTypes] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDoorTypes(setDoorTypes, setIsLoading);
  }, [accessories]);

  return (
    <div style={{ overflow: "auto" }}>
      <Table
        bordered
        size="small"
        loading={isLoading}
        columns={doorTypeColumns}
        dataSource={doorTypeDataSource(doorTypes)}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <DoorTypeAccessories
                data={record.data}
                accessories={accessories}
              />
            );
          },
        }}
      />
    </div>
  );
};

export default ListDoorType;
