import { FilePdfOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { generateSuppliesLeadsFile } from "../utils/fetch";
import { SuppliesPurchasedButton, SuppliesLeadList } from "./";

const SuppliesLead = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const generateFile = () => {
    generateSuppliesLeadsFile(selectedLeads, setIsLoading);
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <Button
          type="primary"
          ghost
          className="d-flex align-items-center"
          onClick={generateFile}
          loading={isLoading}
          icon={<FilePdfOutlined />}
          disabled={selectedLeads.length === 0}
        >
          Сформировать закупку
        </Button>
        <div className="ms-3">
          <SuppliesPurchasedButton
            selectedLeads={selectedLeads}
            setSelectedLeads={setSelectedLeads}
          />
        </div>
      </div>
      <SuppliesLeadList
        leads={leads}
        setLeads={setLeads}
        selectedLeads={selectedLeads}
        setSelectedLeads={setSelectedLeads}
      />
    </div>
  );
};

export default SuppliesLead;
