import { SaveOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { useState } from "react";
import { updateDoorType } from "../utils/fetch";
import ProductAccessories from "./ProductAccessories";

const DoorTypeAccessories = ({ data, accessories }) => {
  const [selectedAccessories, setSelectedAccessories] = useState(
    data?.accessories || []
  );
  const [isLoading, setIsLoading] = useState(false);

  const setSelectedAccessory = (id) => {
    if (!Array.isArray(selectedAccessories)) {
      return;
    }

    const exists = selectedAccessories.includes(id);

    if (!exists) {
      setSelectedAccessories([...selectedAccessories, id]);
    } else {
      const result = [];

      for (let a of selectedAccessories) {
        if (a !== id) {
          result.push(a);
        }
      }

      setSelectedAccessories(result);
    }
  };

  const update = () => {
    updateDoorType(
      data._id,
      {
        accessories: selectedAccessories,
        name: data.name,
      },
      setIsLoading
    );
  };

  return (
    <Card>
      <ProductAccessories
        accessories={accessories}
        selectedAccessories={selectedAccessories}
        setSelected={setSelectedAccessory}
      />
      <div className="mt-3">
        <Button
          style={{ fontSize: 13 }}
          loading={isLoading}
          type="primary"
          icon={<SaveOutlined />}
          onClick={update}
        >
          Сохранить
        </Button>
      </div>
    </Card>
  );
};

export default DoorTypeAccessories;
