import { Table } from "antd";
import { useEffect, useState } from "react";
import { fetchStatuses } from "../utils/fetch";
import {
  getOrderStatusDataSource,
  orderStatusColumnsNew,
} from "../utils/table";
import OrderStatusFullInfo from "./OrderStatusFullInfo";

const NewStatus = ({ userPermission, query = "" }) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, [query]);

  const fetch = () => {
    setIsLoading(true);

    fetchStatuses("ACTIVE", "PASSED_TO_ASSEMBLY", query, setLeads, setIsLoading);
  };

  return (
    <>
      <Table
        style={{
          minWidth: 1000,
        }}
        size="small"
        bordered
        loading={isLoading}
        dataSource={getOrderStatusDataSource(leads, fetch)}
        columns={orderStatusColumnsNew}
        pagination={false}
        scroll={{
          y: 500,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <OrderStatusFullInfo
              data={record.data}
              userPermission={userPermission}
            />
          ),
        }}
      />
    </>
  );
};

export default NewStatus;
