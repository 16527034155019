import { PlusOutlined } from "@ant-design/icons";
import { ModalForm } from "@ant-design/pro-components";
import { Button, Form, Input, Upload, message } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useState } from "react";
import config from "../../../config.json";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { isPhoneNumber } from "class-validator";

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Размер фотографии не должен превышать 2 мб");
  }
  return isJpgOrPng && isLt2M;
};

const AddKeplerPartner = ({ onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    if (isLoading) {
      return;
    }
    if (!isPhoneNumber(values.phoneNumber)) {
      message.error("Неверный формат номера телефона");
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("address", values.address);
    formData.append("addressUrl", values.addressUrl);
    formData.append("email", values.email);
    formData.append("instagramUrl", values.instagramUrl);
    formData.append("instagramUsername", values.instagramUsername);
    formData.append("phoneNumber", values.phoneNumber);
    formData.append(
      "largeBannerUrl",
      values.largeBannerUrl.originFileObj,
      values.largeBannerUrl.name
    );
    formData.append("logo", values.logo.originFileObj, values.logo.name);
    formData.append(
      "smallBannerUrl",
      values.smallBannerUrl.originFileObj,
      values.smallBannerUrl
    );
    formData.append("description", values.description);
    formData.append("webSiteUrl", values.webSiteUrl);
    formData.append("telegramUrl", values.telegramUrl);
    formData.append("facebookUrl", values.facebookUrl);

    axios
      .post(`${config.service}/api/kepler-partner`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then(() => {
        onSuccess();
        setOpen(false);
        form.resetFields();
        message.success("Партнер успешно сохранен");
      })
      .catch(() => {
        message.error("Не удалось добавить партнера");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadButton = () => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </div>
  );

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange = (fieldName, info) => {
    form.setFieldValue(fieldName, info.file);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        icon={<PlusOutlined />}
        type="primary"
      >
        Добавить
      </Button>
      <ModalForm
        title="Добавить партнера"
        visible={open}
        onVisibleChange={setOpen}
        onFinish={onFinish}
        form={form}
        initialValues={{
          logo: "",
          name: "",
          address: "",
          addressUrl: "",
          webSiteUrl: "",
          telegramUrl: "",
          facebookUrl: "",
          instagramUsername: "",
          instagramUrl: "",
          phoneNumber: "",
          email: "",
          description: "",
        }}
      >
        <Form.Item
          name="logo"
          label="Логотип"
          rules={[{ required: true, message: "Логотип не загружен" }]}
          style={{ maxWidth: 250 }}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={beforeUpload}
            onChange={(info) => onChange("logo", info)}
            onPreview={onPreview}
          >
            {uploadButton()}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Название"
          name="name"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>

        <div className="d-flex w-100">
          <Form.Item
            label="Адрес"
            name="address"
            rules={[{ required: true, message: "" }]}
            className="w-100 me-1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Ссылка на 2GIS"
            name="addressUrl"
            className="w-100 ms-1"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label="Номер телефона"
          name="phoneNumber"
          rules={[{ required: true, message: "Введите номер телефона" }]}
        >
          <MaskedInput mask={config.mask.phone} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label="Ссылка на сайт"
          name="webSiteUrl"
          className="w-100 ms-1"
        >
          <Input />
        </Form.Item>
        <div className="d-flex w-100">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Cсылка на Telegram"
            name="telegramUrl"
            className="w-100 me-1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Ссылка на Facebook"
            name="facebookUrl"
            className="w-100 ms-1"
          >
            <Input />
          </Form.Item>
        </div>
        <div className="d-flex w-100">
          <Form.Item
            label="Instagram"
            name="instagramUsername"
            className="w-100 me-1"
            addonBefore="@"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Cсылка на Instagram профиль"
            name="instagramUrl"
            className="w-100 ms-1"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item label="E-mail" name="email">
          <Input />
        </Form.Item>
        <div className="d-flex w-100">
          <Form.Item
            className="w-50"
            name="smallBannerUrl"
            label="Маленький баннер"
            rules={[{ required: true, message: "Баннер не загружен" }]}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={beforeUpload}
              style={{ height: 150, width: 100 }}
              accept={".png, .jpeg, .jpg"}
              onChange={(info) => onChange("smallBannerUrl", info)}
              onPreview={onPreview}
            >
              {uploadButton()}
            </Upload>
          </Form.Item>
          <Form.Item
            className="w-50"
            name="largeBannerUrl"
            label="Большой баннер"
            rules={[{ required: true, message: "Баннер не загружен" }]}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={beforeUpload}
              style={{ height: 150, width: 100 }}
              onPreview={onPreview}
              onChange={(info) => onChange("largeBannerUrl", info)}
            >
              {uploadButton()}
            </Upload>
          </Form.Item>
        </div>
        <Form.Item name="description" label="Описание">
          <Input.TextArea rows={4} />
        </Form.Item>
      </ModalForm>
    </>
  );
};

export default AddKeplerPartner;
