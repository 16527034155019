import { getRequest, patchRequest } from "../../../utils/requests";
import config from "../../../config.json";
import { saveToken } from "../../../utils/token";
import axios from "axios";
import { message } from "antd";

export const fetchProducts = (
  setProducts = () => {},
  setIsLoading = () => {},
  setToken = () => {},
  setKnobs = () => {},
  setDoorstep = () => {},
  setLatch = () => {},
  setDoorPanel = () =>{}
) => {
  setIsLoading(true);
  const url = `${config.service}/api/product`;
  const result = getRequest(url);

  result
    .then((data) => {
      const filteredProducts = [];
      const knobs = [];
      const doorstep = [];
      const latch = [];
      const doorPanel = [];


      data.data.products.forEach((product) => {
        if (product.productType.name === "Дверная ручка") {
          knobs.push(product);
        } else if (product.productType.name === "Порог") {
          doorstep.push(product);
        } else if (product.productType.name === "Поворотник") {
          latch.push(product);
        } else if(product.productType.name === "Добор с наличником"){
          doorPanel.push(product)
        }else {
          filteredProducts.push(product);
        }
      });
      // setProducts(filteredProducts);
      setProducts(data.data.products);
      setKnobs(knobs);
      setDoorstep(doorstep);
      setLatch(latch);
      setDoorPanel(doorPanel)
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        setToken("");
        saveToken("");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const saveLead = (body, navigateToEditPage) => {
  const hideLoadingMsg = message.loading("Сохраняется", 0);

  const url = `${config.service}/api/client`;

  axios
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((data) => {
      message.success("Заявка успешно сохранено", 3);

      const clientId = data.data.clientId;
      const leadId = data.data.leadId;
      const applicationId = data.data.applicationId;

      if (navigateToEditPage) {
        navigateToEditPage(clientId, leadId, applicationId);
      } else {
      }
    })
    .catch(() => {
      message.error("Не удалось сохранить заявку", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const saveSpecification = (body, navigateToClientPage) => {
  const hideLoadingMsg = message.loading("Сохраняется", 0);

  const url = `${config.service}/api/appl`;

  axios
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((data) => {
      message.success("Заявка успешно сохранено", 3);

      const clientId = data.data.clientId;
      const leadId = data.data.leadId;
      const applicationId = data.data.applicationId;

      if (navigateToClientPage) {
        navigateToClientPage(clientId, leadId, applicationId);
      } else {
      }
    })
    .catch(() => {
      message.error("Не удалось сохранить заявку", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const fetchLead = (id, setLead, setIsLoading, setToken) => {
  setIsLoading(true);
  const url = `${config.service}/api/lead/find/${id}`;
  const result = getRequest(url);

  result
    .then((data) => {
      setLead(data.data);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        setToken("");
        saveToken("");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchSpecificSpecification = (
  id,
  setSpecification = () => {},
  setIsLoading = () => {}
) => {
  const url = `${config.service}/api/appl/find/${id}`;

  const request = getRequest(url, {});

  request
    .then((data) => {
      setSpecification(data.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const updateLead = (id, body) => {
  const hideLoadingMsg = message.loading("Заявка обновляется...", 0);

  const url = `${config.service}/api/lead/${id}`;

  const response = patchRequest(url, body);

  response
    .then(() => {
      message.success("Заявка успешно обновлена", 3);
    })
    .catch(() => {
      message.error("Не удалось обновить заявку", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const updateSpecification = (id, body) => {
  const hideLoadingMsg = message.loading("Спецификация обновляется...", 0);

  const url = `${config.service}/api/appl/${id}`;

  const response = patchRequest(url, body);

  response
    .then(() => {
      message.success("Спецификация успешно обновлена", 3);
    })
    .catch(() => {
      message.error("Не удалось обновить спецификацию", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const getBodyToUpdateSpecification = (values, selectedProducts) => {
  const getFormattedOrders = () => {
    const result = [];

    for (let product of selectedProducts) {
      if (!product.productId) {
        product.productId = product.product.productId;
      }

      result.push({
        ...product,
        description: product?.description || product?.comment || "",
      });
    }

    return result;
  };

  return {
    paymentMethod: values.paymentMethod || "",
    contractPrice: {
      id: values.contractPriceId,
      percentage: values.constractPriceIncreasePercentage,
      note: values.constractPriceIncreaseDescription,
    },
    paymentFormat: {
      _id: values.paymentFormatId,
      prepayment: values.prepayment,
      remains: values.remains,
    },
    deadline: values.deadline,
    discount: values.discount,
    designerDiscount: values.designerDiscount,
    orders: getFormattedOrders(),
  };
};

export const getBodyToUpdateClient = (values) => {
  console.log(values, 236)
  return {
    name: values.name || "",
    clientType: values.clientType || "",
    BIN: values.BIN || "",
    accountNumber: values.accountNumber || "",
    bankName: values.bankName || "",
    BIC: values.BIC || "",
    fullNameDirector: values.fullNameDirector || "",
    onWhatBasisDirector: values.onWhatBasisDirector || "",
    phone: values.phone || "",
    additionalPhone: values.additionalPhone || "",
    iin: values.iin || "",
    companyName: values.companyName || "",
  };
};

export const getBodyToUpdateLead = (values) => {
  return {
    hasSignature: values.hasSignature,
    address: values.address,
  };
};
