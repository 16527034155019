export const banks = [
  {
    BIC: "ABNAKZKX",
    code: "922",
    name: "Акционерное общество «First Heartland Bank»",
    img: "https://kase.kz/files/emitters/_logotypes/amro_.gif",
  },
  {
    BIC: "ALFAKZKA",
    code: "947",
    name: "АО «ДОЧЕРНИЙ БАНК «АЛЬФА-БАНК»",
    img: "https://static.qiwi.com/img/providers/logoBig/24646_l.png",
  },
  {
    BIC: "ALMNKZKA",
    code: "826",
    name: "АО «АТФБанк»",
    img: "https://hirebee-main-new.s3.amazonaws.com/hirebee.kz/upload/7/6/b/1/76b17f94.png",
  },
  {
    BIC: "ASFBKZKA",
    code: "450",
    name: "АО «Банк «Астаны»",
    img: "https://zaimtime.kz/sites/default/files/2019-03/astanabank_300x140.png",
  },
  {
    BIC: "ATYNKZKA",
    code: "949",
    name: "АО «Altyn Bank» (ДБ АО «Народный Банк Казахстана»)",
    img: "https://media-exp1.licdn.com/dms/image/C4D0BAQEmhLnSRSPBlA/company-logo_200_200/0/1602830659035?e=2159024400&v=beta&t=6Sx6jOmhICzcmSp-aFbaYgbhuTDmcCmU2yRC_3a0dD4",
  },
  {
    BIC: "BKCHKZKA",
    code: "913",
    name: "АО ДБ «БАНК КИТАЯ В КАЗАХСТАНЕ»",
    img: "https://st6.prosto.im/cache/st6/1/0/1/7/1017/1017_230x.jpg",
  },
  {
    BIC: "CASPKZKA",
    code: "722",
    name: "АО «KASPI BANK»",
    img: "https://travel.kaspi.kz/static/app-icon.png",
  },
  {
    BIC: "CEDUKZKA",
    code: "766",
    name: "АО «ЦЕНТРАЛЬНЫЙ ДЕПОЗИТАРИЙ ЦЕННЫХ БУМАГ»",
    img: "https://almaty.hh.kz/employer-logo/2417246.png",
  },
  {
    BIC: "CITIKZKA",
    code: "832",
    name: "АО «Ситибанк Казахстан»",
    img: "https://www.ghanayello.com/img/gh/e/1595699421-35-citibank-n-a-ghana-rep-office.png",
  },
  {
    BIC: "DVKAKZKA",
    code: "907",
    name: "АО «Банк Развития Казахстана»",
    img: "https://storage.yvision.kz/images/avatars/kdbkz-normal.jpg",
  },
  {
    BIC: "EABRKZKA",
    code: "700",
    name: "ЕВРАЗИЙСКИЙ БАНК РАЗВИТИЯ",
    img: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/vgxztincxpxaomee1vfa",
  },
  {
    BIC: "EURIKZKA",
    code: "948",
    name: "АО «Евразийский Банк»",
    img: "https://rebus-finance.kz/wp-content/uploads/2020/03/evrazijsk-bank.jpg",
  },
  {
    BIC: "EXKAKZKA",
    code: "942",
    name: "АО «ЭКСИМБАНК КАЗАХСТАН»",
    img: "https://etoday.kz/wp-content/uploads/2015-04/1429898634_eksimbank.jpg",
  },
  {
    BIC: "GCVPKZ2A",
    code: "009",
    name: "НАО «Государственная корпорация «Правительство для граждан»",
    img: "https://gov4c.kz/public/img/logo.png",
  },
  {
    BIC: "HCSKKZKA",
    code: "972",
    name: "АО «Жилстройсбербанк Казахстана»",
    img: "https://hcsbk.kz/upload/iblock/1b1/1b133ea01d72fb361b0938ade2805705.jpg",
  },
  {
    BIC: "HLALKZKZ",
    code: "246",
    name: "АО «Исламский Банк «Al Hilal»",
    img: "https://hirebee-main-new.s3.amazonaws.com/hirebee.kz/upload/d/2/8/a/d28acd41.png",
  },
  {
    BIC: "HSBKKZKX",
    code: "601",
    name: "АО «Народный Банк Казахстана»",
    img: "https://media1.lyubimiigorod.ru/images/cards/5d8cf1ff9a5ab.jpg",
  },
  {
    BIC: "ICBKKZKX",
    code: "930",
    name: "АО «Торгово-промышленный Банк Китая в г. Алматы»",
    img: "",
  },
  {
    BIC: "INEARUMM",
    code: "550",
    name: "г.Москва Межгосударственный Банк",
    img: "https://upload.wikimedia.org/wikipedia/ru/4/4b/Mezhgosbank.jpg",
  },
  {
    BIC: "INLMKZKA",
    code: "886",
    name: "ДБ АО «Банк Хоум Кредит»",
    img: "https://strgcdn.finance.kz/images/2019/06/07/7ee2424d9bf9db35146e6fe4003665d8.200.png",
  },
  {
    BIC: "IRTYKZKA",
    code: "965",
    name: "АО «ForteBank»",
    img: "https://media1.lyubimiigorod.ru/images/cards/5d283a8e2c721.png",
  },
  {
    BIC: "KCJBKZKX",
    code: "856",
    name: "АО «Банк ЦентрКредит»",
    img: "https://hirebee-main-new.s3.amazonaws.com/hirebee.kz/upload/f/e/b/3/feb3df87.png",
  },
  {
    BIC: "KICEKZKX",
    code: "927",
    name: "АО «Казахстанская фондовая биржа»",
    img: "https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_400,h_400/https://investguide.kz/wp-content/uploads/2021/07/image-25.png",
  },
  {
    BIC: "KINCKZKA",
    code: "821",
    name: "Акционерное общество «Банк «Bank RBK»",
    img: "https://pbs.twimg.com/profile_images/1064772376464900096/afBCIrRv_400x400.jpg",
  },
  {
    BIC: "KISCKZKX",
    code: "224",
    name: "РГП «Казахстанский центр межбанковских расчетов НБРК»",
    img: "https://www.inform.kz/lenta/new_files/img/quest/nationalbank.jpg",
  },
  {
    BIC: "KKMFKZ2A",
    code: "070",
    name: "РГУ «Комитет казначейства Министерства финансов РК»",
    img: "https://gosslugba.kz/wp-content/uploads/2019/08/kaznachejstvo-klient.jpg",
  },
  {
    BIC: "KPSTKZKA",
    code: "563",
    name: "АО «КАЗПОЧТА»",
    img: "https://www.globalkz.biz/usr/catalog/big-catalog-15698195501.png",
  },
  {
    BIC: "KSNVKZKA",
    code: "551",
    name: "АО «Банк Kassa Nova»",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqXXrqTwKIUZAvLGvmJAQlG6nZNAbA_NQV1hq3HTRAq-RLcHU96dAlR0h0DHqZmuXyq-o&usqp=CAU",
  },
  {
    BIC: "KZIBKZKA",
    code: "885",
    name: "АО «ДБ «КАЗАХСТАН-ЗИРААТ ИНТЕРНЕШНЛ БАНК»",
    img: "https://st6.prosto.im/cache/st6/1/0/7/4/1074/1074_230x.jpg",
  },
  {
    BIC: "KZKOKZKX",
    code: "926",
    name: "АО «КАЗКОММЕРЦБАНК»",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Qazkom_Logo.svg/1200px-Qazkom_Logo.svg.png",
  },
  {
    BIC: "LARIKZKA",
    code: "774",
    name: "АО «AsiaCredit Bank (АзияКредит Банк)»",
    img: "https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/28/23/1e/28231eb3-a57d-2d76-1d45-e479a0332af7/source/512x512bb.jpg",
  },
  {
    BIC: "NBPAKZKA",
    code: "553",
    name: "АО ДБ «Национальный Банк Пакистана» в Казахстане",
    img: "https://st6.prosto.im/cache/st6/1/0/3/3/1033/1033_230x.jpg",
  },
  {
    BIC: "NBPFKZKX",
    code: "147",
    name: "«Банк-кастодиан АО «ЕНПФ»",
    img: "https://www.gov.kz/uploads/2021/1/21/f98c1ac4521ec15645f0ab3a8be31896_original.10504.jpg",
  },
  {
    BIC: "NBRKKZKX",
    code: "125",
    name: "Республиканское Государств Учреждение Национальный Банк РК",
    img: "https://www.inform.kz/lenta/new_files/img/quest/nationalbank.jpg",
  },
  {
    BIC: "NCCBRUMM",
    code: "660",
    name: "НКО-ЦК «Национальный Клиринговый Центр» (АО)",
    img: "https://www.globalmsk.ru/usr/catalog/big-12615-15284846180.jpg",
  },
  {
    BIC: "NFBAKZ23",
    code: "639",
    name: "АО «Delta Bank»",
    img: "https://st6.prosto.im/cache/st6/1/0/0/4/1004/1004_230x.jpg",
  },
  {
    BIC: "NURSKZKX",
    code: "849",
    name: "АО «Нурбанк»",
    img: "https://upload.wikimedia.org/wikipedia/commons/2/2f/%D0%A2%D1%80%D0%B5%D1%83%D0%B3%D0%BE%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA_%286%29.jpg",
  },
  {
    BIC: "SABRKZKA",
    code: "914",
    name: "ДБ АО «Сбербанк»",
    img: "https://wikicity.kz/fotos_ms/Company_3748_u9ZvPp6hPqXuNBGYkj4642GH.jpeg",
  },
  {
    BIC: "SENIKZKA",
    code: "549",
    name: "АО «Qazaq Banki»",
    img: "https://opt-603046.ssl.1c-bitrix-cdn.ru/images/%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D0%B1%D0%B0%D0%BD%D0%BA%20%D0%BB%D0%BE%D0%B3%D0%BE.jpg",
  },
  {
    BIC: "SHBKKZKA",
    code: "435",
    name: "АО «Шинхан Банк Казахстан»",
    img: "https://strgcdn.finance.kz/images/2019/09/03/5847d081d09e222b86cb0417f250ab72.200.jpeg",
  },
  {
    BIC: "TBKBKZKA",
    code: "781",
    name: "АО «Capital Bank Kazakhstan»",
    img: "https://globalkz.biz/usr/catalog/big-catalog-15820192350.png",
  },
  {
    BIC: "TNGRKZKX",
    code: "620",
    name: "АО «Tengri Bank»",
    img: "https://tengribank.kz/static/build/img/logo-facebook.png",
  },
  {
    BIC: "TSESKZKA",
    code: "998",
    name: "АО «Цеснабанк»",
    img: "https://wikicity.kz/fotos_ms/Company_3764_pt9uj5P0dw38GN0j7v4XXin.jpeg",
  },
  {
    BIC: "VTBAKZKZ",
    code: "432",
    name: "ДО АО Банк ВТБ (Казахстан)",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzkDLuiVtbKpyQLz7RWXEadeLnv4Eldro_LqSgc7Dx9peqQ5bFaqcRmTbbjKQqEm0zsT4&usqp=CAU",
  },
  {
    BIC: "ZAJSKZ22",
    code: "896",
    name: "АО «Исламский банк «Заман-Банк»",
    img: "https://www.banker.kz/upload/iblock/16e/risunok1.png",
  },
];
