import { Button, Form, Input, Layout } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useState } from "react";
import config from "../../config.json";
import { showErrorMessage } from "../../utils/message";
import { postRequest } from "../../utils/requests";
import { saveToken } from "../../utils/token";

const LoginForm = ({ setToken }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (body) => {
    const url = `${config.service}/api/auth/login`;

    const request = postRequest(url, body, {});

    setIsLoading(true);
    request
      .then((data) => {
        const accessToken = data.data.accessToken;

        saveToken(accessToken);
        setToken(accessToken);
      })
      .catch((err) => {
        const message = err?.response?.data?.message || "Неизвестная ошибка";

        showErrorMessage(message, 3);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h3
        style={{
          marginBottom: 15,
        }}
      >
        Авторизация
      </h3>
      <Form
        name="basic"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        autoComplete="off"
        style={{
          minWidth: 350,
        }}
      >
        <Form.Item
          label="Номер телефона"
          name="phone"
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <MaskedInput mask={config.mask.phone} />
        </Form.Item>
        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 9, span: 20 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default LoginForm;
