import { Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import { fetchSuppliesWarehouse } from "../utils/fetch";
import { suppliesLeadsColumn, suppliesLeadsDataSource } from "../utils/table";
import SuppliesLeadCalc from "./SuppliesLeadCalc";

const SuppliesWarehouse = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchSuppliesWarehouse(
      currentPage,
      pageSize,
      setData,
      setTotalPages,
      setIsLoading
    );
  }, [currentPage, pageSize]);

  return (
    <div>
      <Table
        bordered
        size="small"
        loading={isLoading}
        columns={suppliesLeadsColumn}
        pagination={false}
        dataSource={suppliesLeadsDataSource(data)}
        expandable={{
          expandedRowRender: (record) => <SuppliesLeadCalc id={record.key} />,
        }}
      />
      <div className="mt-3">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
          total={totalPages * pageSize}
        />
      </div>
    </div>
  );
};

export default SuppliesWarehouse;
