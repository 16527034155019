import { Table } from "antd";
import { accessoryColumns, accessoryDataSource } from "../utils/table";

const AccessoriesList = ({ accessories, totalCount, isLoading, fetch }) => {
  return (
    <div style={{ overflow: "auto" }}>
      <Table
        bordered
        size="small"
        columns={accessoryColumns}
        dataSource={accessoryDataSource(accessories, fetch)}
      />
    </div>
  );
};

export default AccessoriesList;
