import { DeleteOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Upload} from "antd";
import {
  ModalForm,
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormCheckbox,
} from "@ant-design/pro-components";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { fetchRoles, updateUser } from "../utils/fetch";
import axios from "axios";
import config from "../../../config.json";

const UpdateUser = ({ data, companyPermissions, fetch }) => {
  const [roles, setRoles] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const avatarState = () => {
    if (!data?.imgPath) {
      return [];
    }
    const imgSrc = `${config.service}/api/file/photos/avatars/${data.imgPath}`;
    return [{
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: imgSrc,
    }]
  }
  const [fileList, setFileList] = useState(avatarState());

  useEffect(() => {
    fetchRoles(setRoles);
  }, [data._id]);

  useEffect(() => {
    if (!Array.isArray(roles)) {
      return;
    }

    const newList = [];
    for (let role of roles) {
      newList.push({
        value: role._id,
        label: role.name,
      });
    }
    setRoleOptions(newList);
  }, [roles]);

  const deleteUser = () => {
    setIsLoading(true);

    axios
      .delete(`${config.service}/api/user/${data._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then(() => {
        fetch();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const formdata = new FormData();
  if (!data.imgPath) {
    if (fileList.length > 0) {
      fileList[0].status = 'done';
      const image = fileList[0].originFileObj;
      formdata.append("image", image, image.name);
    }
  }
  const getBody = (values) => {
    const formdata = new FormData();

    formdata.append('name', values.name);
    formdata.append('surname', values.surname);
    formdata.append('phone', values.phone);
    formdata.append('password', values.password || '');
    formdata.append('roleId', values.roleId);
    formdata.append('companyId', data.companyId._id);
    formdata.append('createLead', values.createLead);
    formdata.append('clients', values.clients);
    formdata.append('specification', values.specification);
    formdata.append('act', values.act);
    formdata.append('agreement', values.agreement);
    formdata.append('KP', values.KP);
    formdata.append('payment', values.payment);
    formdata.append('deliveryCertificate', values.deliveryCertificate);
    formdata.append('acceptanceAct', values.acceptanceAct);
    formdata.append('products', values.products);
    formdata.append('production', values.production);
    formdata.append('orderStatus', values.orderStatus);
    formdata.append('assemblyStatus', values.assemblyStatus);
    formdata.append('manufactureStatus', values.manufactureStatus);
    formdata.append('deliveryStatus', values.deliveryStatus);
    formdata.append('installationStatus', values.installationStatus);
    formdata.append('changeState', values.changeState);
    formdata.append('changeStatus', values.changeStatus);

    if (!data.imgPath) {
      if (fileList.length > 0) {
        fileList[0].status = 'done';
        const image = fileList[0].originFileObj;
        formdata.append("image", image, image.name);
      }
    } else {
      if (fileList.length > 0 && fileList[0].originFileObj) {
        const image = fileList[0].originFileObj;
        formdata.append("image", image, image.name);
      }
      if (fileList.length === 0) {
        formdata.append("image", '')
      } else {
        formdata.append("image", data.imgPath)
      }
    }
    return formdata;
  };

  const handleUpdate = (values) => {
    console.log('values', values);
    const formdata = getBody(values);
    updateUser(data._id, formdata,() => {setIsVisible(false);});
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className="me-2"
      >
        <Popconfirm
          title="Удалить пользователя?"
          onConfirm={deleteUser}
          okText="Да"
          cancelText="Нет"
        >
          <Button
            loading={isLoading}
            className="text-danger px-1"
            style={{ width: 35 }}
            icon={<DeleteOutlined />}
          ></Button>
        </Popconfirm>
      </div>
      <ModalForm
        visible={isVisible}
        onVisibleChange={setIsVisible}
        title={data.name + " " + data.surname}
        trigger={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button icon={<MenuUnfoldOutlined />}></Button>
          </div>
        }
        initialValues={{
          ...data,
          ...data.permission,
          roleId: data.roleId?._id || "",
          companyId: data.companyId?._id || "",
          phone: data.phone,
          password: "",
        }}
        onFinish={(values) => {
          handleUpdate(values);
          // updateUser(
          //   data._id,
          //   {
          //     ...values,
          //     companyId: data.companyId,
          //   },
          //   () => {
          //     setIsVisible(false);
          //   }
          // );
        }}
      >
        <ProForm.Group>
          <div>
            <ProForm.Group>
              <ProFormText name="name" label="Имя" />
              <ProFormText name="surname" label="Фамилия" />
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText name="phone" label="Номер телефона" />
              <ProFormText.Password
                name="password"
                label="Пароль"
                placeholder="Введите новый пароль"
              />
            </ProForm.Group>
          </div>
          <ProFormSelect name="roleId" label="Роль" options={roleOptions} />
          <FormItem label="Фото">
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                accept={".png, .jpeg, .jpg"}
                max={1}
              >
                {fileList.length < 1 && "+ Загрузить"}
              </Upload>
            </FormItem>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox
            disabled={!companyPermissions?.createLead}
            name="createLead"
          >
            Создание заявки
          </ProFormCheckbox>
          <ProFormCheckbox
            disabled={!companyPermissions?.clients}
            name="clients"
          >
            Доступ к клиентам
          </ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox
            disabled={!companyPermissions?.specification}
            name="specification"
          >
            Спецификация
          </ProFormCheckbox>
          <ProFormCheckbox disabled={!companyPermissions?.act} name="act">
            Заявка на производство
          </ProFormCheckbox>
          <ProFormCheckbox
            disabled={!companyPermissions?.agreement}
            name="agreement"
          >
            Договор
          </ProFormCheckbox>
          <ProFormCheckbox disabled={!companyPermissions?.KP} name="KP">
            КП
          </ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox
            disabled={!companyPermissions?.payment}
            name="payment"
          >
            Счет на оплату
          </ProFormCheckbox>
          <ProFormCheckbox
              disabled={!companyPermissions?.deliveryCertificate}
              name="deliveryCertificate"
            >
              Акт приема-передачи
            </ProFormCheckbox>
            <ProFormCheckbox
              disabled={!companyPermissions?.acceptanceAct}
              name="acceptanceAct"
            >
              Акт выполненных работ
            </ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox
            disabled={!companyPermissions?.products}
            name="products"
          >
            Товары
          </ProFormCheckbox>
          <ProFormCheckbox
            disabled={!companyPermissions?.orderStatus}
            name="orderStatus"
          >
            Статусы заказов
          </ProFormCheckbox>
          <ProFormCheckbox
            disabled={!companyPermissions?.production}
            name="production"
          >
            Запуск на производство
          </ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox
            disabled={!companyPermissions?.changeState}
            name="changeState"
          >
            Принятие / отклонение заявок
          </ProFormCheckbox>
          <ProFormCheckbox
            disabled={!companyPermissions?.changeStatus}
            name="changeStatus"
          >
            Изменение статуса
          </ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
            <ProFormCheckbox name="assemblyStatus" disabled={!companyPermissions?.assemblyStatus}>
              Статусы сборки
            </ProFormCheckbox>
            <ProFormCheckbox name="manufactureStatus" disabled={!companyPermissions?.manufactureStatus}>
              Статусы производства
            </ProFormCheckbox>
            <ProFormCheckbox name="deliveryStatus" disabled={!companyPermissions?.deliveryStatus}>
              Статусы доставки
            </ProFormCheckbox>
            <ProFormCheckbox name="installationStatus" disabled={!companyPermissions?.installationStatus}>
              Статусы монтажа
            </ProFormCheckbox>
        </ProForm.Group>
      </ModalForm>
    </div>
  );
};

export default UpdateUser;

