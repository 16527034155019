import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import CompanyFullInfo from "../components/CompanyFullInfo";

export const companiesColumn = [
  {
    title: "",
    key: "number",
    dataIndex: "number",
    width: 50,
  },
  {
    title: "Название",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Дата регистрации",
    key: "registrationDate",
    dataIndex: "registrationDate",
    width: 160,
  },
  {
    title: "",
    key: "actions",
    dataIndex: "actions",
    width: 75,
  },
];

export const getCompaniesDataSource = (companies, openCompanyPage) => {
  const data = [];

  if (Array.isArray(companies)) {
    let i = 1;
    for (let company of companies) {
      data.push({
        key: company._id,
        number: `${i++}`,
        name: (
          <div
            className="cursor-pointer"
            onClick={() => {
              openCompanyPage(company._id);
            }}
          >
            {company.name}
          </div>
        ),
        registrationDate: new Date(company.createdAt).toLocaleDateString(),
        data: company,
        actions: (
          <div
            style={{
              display: "flex",
            }}
          >
            <CompanyFullInfo company={company} />
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                padding: 5,
                color: "red",
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        ),
      });
    }
  }

  return data;
};
