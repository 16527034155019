import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const CustomRangePicker = ({ onChange, value }) => {
  const handleClear = () => {
    if (onChange) {
      onChange([]);
    }
  };

  return (
    <RangePicker
      value={value}
      onChange={onChange}
      allowClear={true}
      style={{ marginLeft: '10px' }}
      onCalendarChange={(dates) => {
        if (!dates || dates.length === 0) {
          handleClear();
        }
      }}
    />
  );
};

export default CustomRangePicker;
