import { Button, Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getSidebarData } from "./utils/sidebar";
import { Logo } from "../../assets";
import { LogoutOutlined } from "@ant-design/icons";

const { Sider } = Layout;

const Sidebar = ({ userPermission }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(getSidebarData(userPermission));
  }, [userPermission]);

  const signout = () => {
    localStorage.removeItem("accessToken");

    navigate("/");

    window.location.reload();
  };

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{
        padding: "10px 0",
      }}
    >
      {!collapsed && (
        <div className="px-4 my-2 pb-3 border-bottom text-center">KEPLER</div>
      )}
      {collapsed && (
        <div className="d-flex justify-content-center my-2">
          <img style={{ width: 40 }} src={Logo} />
        </div>
      )}
      <Menu mode="inline" style={{ position: "relative" }}>
        {items.map((item) => {
          return (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.link}>{item.label}</Link>
            </Menu.Item>
          );
        })}
        <Menu.Item
          icon={<LogoutOutlined />}
          className="text-danger mt-5"
          onClick={signout}
        >
          Выйти
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
