import { Checkbox } from "antd";

const ProductAccessories = ({
  selectedAccessories,
  accessories,
  setSelected,
  readonly,
}) => {
  return (
    <div className="border-top pt-3 border-bottom">
      <div className="mb-1">Комплектующие:</div>

      <ul className="p-0" style={{ listStyle: "none" }}>
        {readonly &&
          selectedAccessories.map((accessory, index) => {
            const foundAccessory = accessories.find((v) => v._id === accessory);

            if (!foundAccessory) {
              return <></>;
            }

            return (
              <li key={index}>
                <span>{foundAccessory.name}</span>
              </li>
            );
          })}
        {!readonly &&
          accessories.map((accessory, index) => {
            const isSelected = selectedAccessories.includes(accessory._id);

            return (
              <li key={index}>
                <Checkbox
                  checked={isSelected}
                  onClick={() => {
                    setSelected(accessory._id);
                  }}
                  className={`text-${isSelected ? "primary" : "dark"}`}
                >
                  {accessory.name}
                </Checkbox>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ProductAccessories;
