import React, { useState } from 'react';
import { Card, Segmented } from "antd";
import KeplerPartnerDiscount from "./KeplerPartnerDiscount";
import KeplerPartnerSettings from "./KeplerPartnerSettings";
import KeplerPartnerActivations from "./KeplerPartnerActivations";

const KeplerPartnerInfo = ({ partner, fetch }) => {
  const [tab, setTab] = useState("discount");

  const getBody = () => {
    if (tab === "settings") {
      return <KeplerPartnerSettings partner={partner} fetch={fetch} />;
    }

    if (tab === "activations") {
      return <KeplerPartnerActivations partnerId={partner._id} />;
    }

    return <KeplerPartnerDiscount keplerPartnerId={partner._id} />;
  };

  return (
    <Card>
      <Segmented
        block
        value={tab}
        onChange={setTab}
        options={[
          { value: "discount", label: "Скидки" },
          { value: "settings", label: "Настройки" },
          { value: "activations", label: "Активации" },
        ]}
      />
      <div>{getBody()}</div>
    </Card>
  );
};

export default KeplerPartnerInfo;
