import { Card, Layout, Button, Divider } from "antd";
import {
  ProForm,
  PageContainer,
  FooterToolbar,
} from "@ant-design/pro-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomerInformation,
  PaymentFormat,
  PaymentMethod,
  PriceInfo,
  ProductsInformation,
} from "./components";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { fetchClient, updateClient } from "../Clients/utils/fetch";
import {
  fetchLead,
  fetchSpecificSpecification,
  getBodyToUpdateClient,
  getBodyToUpdateLead,
  getBodyToUpdateSpecification,
  updateLead,
  updateSpecification,
} from "./utils/fetch";
import { getInitialValues } from "./utils/values";
import { getTotalPriceWithDiscount } from "./utils/price";
import { useForm } from "antd/es/form/Form";
import {
  requestSendAgreement,
  requestSendCommercialProposal,
  sendInvoiceForPayment,
  requestSendForProduction,
} from "../ClientInfo/utils/fetch";
import { banks } from "./utils/banks";
import { changePageTitle } from "../../utils/title";

const EditLead = ({ setToken }) => {
  const params = useParams();
  const clientId = params.clientId;
  const leadId = params.leadId;
  const applicationId = params.applicationId;
  const [form] = useForm();
  const navigate = useNavigate();

  const [client, setClient] = useState({});
  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [lead, setLead] = useState({});
  const [isLoadingLead, setIsLoadingLead] = useState(true);
  const [clientType, setClientType] = useState(
    client?.clientType || "INDIVIDUAL"
  );
  const [specification, setSpecification] = useState({});
  const [isLoadingSpecification, setIsLoadingSpecification] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [increasePrice, setIncreasePrice] = useState(0);

  useEffect(() => {
    changePageTitle("Редактировать заявку");
  }, []);

  useEffect(() => {
    fetchClient(clientId, setClient, setIsLoadingClient, () => {
      navigateToNewLead();
    });
    fetchLead(leadId, setLead, setIsLoadingLead, setToken);
    fetchSpecificSpecification(
      applicationId,
      setSpecification,
      setIsLoadingSpecification
    );
  }, [clientId, leadId]);

  useEffect(() => {
    setClientType(client?.clientType || "INDIVIDUAL");
  }, [client]);

  useEffect(() => {
    setSelectedProducts(specification.orders || []);
  }, [specification]);

  useEffect(() => {
    if (!Array.isArray(lead?.specifications)) {
      return;
    }

    setSpecification(lead?.specifications[0]?.orders || []);
  }, [lead]);

  useEffect(() => {
    calculateRemains();
  }, [specification, selectedProducts]);

  const calculatePrepayment = () => {
    const totalPrice = getTotalPriceWithDiscount(
      specification?.orders || [],
      form.getFieldValue("constractPriceIncreasePercentage"),
      form.getFieldValue("discount")
    );

    const prepayment = parseInt(totalPrice * 0.7);

    form.setFieldValue("prepayment", prepayment);

    calculateRemains();
  };

  const calculateRemains = () => {
    const discount = form.getFieldValue("discount") || 0;

    const totalPrice = getTotalPriceWithDiscount(
      specification?.orders || [],
      form.getFieldValue("constractPriceIncreasePercentage"),
      discount
    );

    form.setFieldValue(
      "remains",
      parseInt(
        totalPrice -
          (totalPrice * discount) / 100 -
          form.getFieldValue("prepayment")
      )
    );
  };

  const navigateToNewLead = () => {
    navigate("/lead");
  };

  const onValuesChange = (value) => {
    if (value.clientType) {
      setClientType(value.clientType);
    }
    if (value.discount || value.discount === 0) {
      setDiscount(value.discount);
    }

    if (
      value.constractPriceIncreasePercentage ||
      value.constractPriceIncreasePercentage === 0
    ) {
      setIncreasePrice(value.constractPriceIncreasePercentage);
    }

    if (value.accountNumber) {
      const accountNumber = value.accountNumber;
      if (accountNumber.length >= 7) {
        const bankCode = accountNumber.substring(4, 7);

        const bank = banks.find((v) => v.code === bankCode);

        if (bank) {
          form.setFieldValue("bankName", bank.name);
          form.setFieldValue("BIC", bank.BIC);
        } else {
          form.setFieldValue("bankName", "");
          form.setFieldValue("BIC", "");
        }
      } else {
        form.setFieldValue("bankName", "");
        form.setFieldValue("BIC", "");
      }
    }

    if (value.prepayment) {
      calculateRemains();
    }

    if (
      value.discount ||
      value.discount === 0 ||
      value.constractPriceIncreasePercentage ||
      value.constractPriceIncreasePercentage === 0
    ) {
      calculatePrepayment();
    }
  };

  const handleUpdate = (values) => {
    updateClient(clientId, getBodyToUpdateClient(values));
    updateLead(leadId, getBodyToUpdateLead(values));
    updateSpecification(
      applicationId,
      getBodyToUpdateSpecification(
        {
          ...values,
          contractPriceId: specification.contractPriceId,
          paymentFormatId: specification.paymentFormatId,
        },
        selectedProducts
      )
    );
  };

  if (isLoadingClient || isLoadingLead || isLoadingSpecification) {
    return <Loading />;
  }

  return (
    <Layout>
      <PageContainer title="Редактировать заявку">
        <Card>
          <ProForm
            form={form}
            submitter={{
              render: () => (
                <FooterToolbar>
                  <Button type="primary" htmlType="submit">
                    Обновить
                  </Button>
                  <Button
                    style={{
                      width: 100,
                    }}
                    onClick={() => {
                      requestSendAgreement(leadId);
                    }}
                  >
                    Договор
                  </Button>
                  <Button
                    style={{
                      width: 100,
                    }}
                    onClick={() => {
                      requestSendCommercialProposal(applicationId);
                    }}
                  >
                    КП
                  </Button>
                  <Button
                    style={{
                      width: 150,
                    }}
                    onClick={() => {
                      sendInvoiceForPayment(applicationId);
                    }}
                  >
                    Счет на оплату
                  </Button>
                  <Button
                    style={{
                      width: 200,
                    }}
                    onClick={() => {
                      // window.open(`http://localhost:9091/api/file/act/${applicationId}`);
                      requestSendForProduction(applicationId);
                    }}
                  >
                    Заявка на производство
                  </Button>
                </FooterToolbar>
              ),
            }}
            onFinish={handleUpdate}
            onValuesChange={onValuesChange}
            initialValues={getInitialValues(client, 0, 0, lead, specification)}
          >
            <CustomerInformation clientType={clientType} disabled={false} />
            <Divider />
            <ProductsInformation
              setToken={setToken}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              disabled={false}
              increasePrice={form.getFieldValue(
                "constractPriceIncreasePercentage"
              )}
            />
            <PriceInfo
              selectedProducts={selectedProducts}
              discount={discount}
              increasePrice={increasePrice}
            />
            <Divider
              style={{
                marginTop: 0,
              }}
            />
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  marginRight: 40,
                }}
              >
                <PaymentFormat />
              </div>
              <div
                style={{
                  marginRight: 40,
                }}
              >
                <PaymentMethod />
              </div>
            </div>
            <Divider />
          </ProForm>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default EditLead;
