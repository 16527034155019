import { Button, Form, Layout, DatePicker, Card, Divider } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { fetchClient } from "../Clients/utils/fetch";
import {
  CustomerInformation,
  PriceInfo,
  ProductsInformation,
  PaymentFormat,
  ContractPriceIncrease,
  PaymentMethod,
} from "./components";
import {
  ProForm,
  PageContainer,
  FooterToolbar,
} from "@ant-design/pro-components";
import {
  fetchLead,
  saveSpecification as requestSaveSpecification,
} from "./utils/fetch";
import { useForm } from "antd/es/form/Form";
import { getTotalPriceWithDiscount } from "./utils/price";
import { banks } from "./utils/banks";
import { getInitialValues } from "./utils/values";
import { changePageTitle } from "../../utils/title";

const NewLead = ({ setToken }) => {
  const params = useParams();
  const clientId = params.clientId;
  const leadId = params.leadId;

  const [form] = useForm();
  const navigate = useNavigate();

  const [customerInfo, setCustomerInfo] = useState({
    clientType: "INDIVIDUAL",
    address: "",
  });
  const [client, setClient] = useState();
  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [specification, setSpecification] = useState([]);
  const [lead, setLead] = useState();
  const [isLoadingLead, setIsLoadingLead] = useState(true);

  const [clientType, setClientType] = useState();
  const [discount, setDiscount] = useState(0);
  const [increasePrice, setIncreasePrice] = useState(0);

  const navigateToClientPage = () => {
    navigate(`/client/${clientId}`);
  };

  const saveSpecification = (values) => {
    requestSaveSpecification(getBody(values), navigateToClientPage);
  };

  useEffect(() => {
    changePageTitle("Новая спецификация");
  }, []);

  useEffect(() => {
    if (clientId) {
      fetchClient(clientId, setClient, setIsLoadingClient);
    } else {
      setIsLoadingClient(false);
    }
  }, [clientId]);

  useEffect(() => {
    if (leadId) {
      fetchLead(leadId, setLead, setIsLoadingLead);
    }
  }, [leadId]);

  useEffect(() => {
    setClientType(client?.clientType || "INDIVIDUAL");
  }, [client]);

  useEffect(() => {
    calculatePrepayment();
  }, [specification]);

  const getBody = (values) => {
    return {
      lead: leadId,
      paymentMethod: values.paymentMethod,
      paymentFormat: {
        prepayment: values.prepayment,
        remains: values.remains,
      },
      contractPrice: {
        percentage: values.constractPriceIncreasePercentage,
        note: values.constractPriceIncreaseDescription,
      },
      deadline: values.deadline,
      orders: specification,
    };
  };

  const calculatePrepayment = () => {
    const totalPrice = getTotalPriceWithDiscount(
      specification || [],
      form.getFieldValue("constractPriceIncreasePercentage"),
      form.getFieldValue("discount")
    );

    const prepayment = parseInt(totalPrice * 0.7);

    form.setFieldValue("prepayment", prepayment);

    calculateRemains();
  };

  const calculateRemains = () => {
    const discount = form.getFieldValue("discount") || 0;

    const totalPrice = getTotalPriceWithDiscount(
      specification || [],
      form.getFieldValue("constractPriceIncreasePercentage"),
      discount
    );

    form.setFieldValue(
      "remains",
      parseInt(
        totalPrice -
          (totalPrice * discount) / 100 -
          form.getFieldValue("prepayment")
      )
    );
  };

  const onValuesChange = (value) => {
    if (value.clientType) {
      setClientType(value.clientType);
    }
    if (value.discount || value.discount === 0) {
      setDiscount(value.discount);
    }

    if (value.prepayment) {
      calculateRemains();
    }

    if (
      value.constractPriceIncreasePercentage ||
      value.constractPriceIncreasePercentage === 0
    ) {
      setIncreasePrice(value.constractPriceIncreasePercentage);
    }

    if (value.accountNumber) {
      const accountNumber = value.accountNumber;
      if (accountNumber.length >= 7) {
        const bankCode = accountNumber.substring(4, 7);

        const bank = banks.find((v) => v.code === bankCode);

        if (bank) {
          form.setFieldValue("bankName", bank.name);
          form.setFieldValue("BIC", bank.BIC);
        } else {
          form.setFieldValue("bankName", "");
          form.setFieldValue("BIC", "");
        }
      } else {
        form.setFieldValue("bankName", "");
        form.setFieldValue("BIC", "");
      }
    }

    if (
      value.discount ||
      value.discount === 0 ||
      value.constractPriceIncreasePercentage ||
      value.constractPriceIncreasePercentage === 0
    ) {
      calculatePrepayment();
    }
  };

  if (isLoadingClient || !client || isLoadingLead || !lead) {
    return <Loading />;
  }

  return (
    <Layout>
      <PageContainer title="Новая заявка">
        <Card>
          <ProForm
            form={form}
            onValuesChange={(values) => {
              onValuesChange(values);
            }}
            onReset={() => {
              setClientType("INDIVIDUAL");
              setDiscount(0);
              setSpecification([]);
            }}
            submitter={{
              render: (_, _dom) => (
                <FooterToolbar>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                </FooterToolbar>
              ),
            }}
            onFinish={(values) => {
              saveSpecification(values);
            }}
            initialValues={getInitialValues(
              client,
              discount,
              increasePrice,
              lead
            )}
          >
            <CustomerInformation
              setToken={setToken}
              customerInfo={customerInfo}
              setCustomerInfo={setCustomerInfo}
              client={client}
              clientType={clientType}
              disabled={true}
            />
            <Divider />
            <ProductsInformation
              setToken={setToken}
              selectedProducts={specification}
              setSelectedProducts={setSpecification}
              onChange={calculatePrepayment}
              increasePrice={form.getFieldValue(
                "constractPriceIncreasePercentage"
              )}
            />
            <PriceInfo
              selectedProducts={specification}
              discount={discount}
              increasePrice={increasePrice}
            />
            <Divider
              style={{
                marginTop: 0,
              }}
            />
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  marginRight: 40,
                }}
              >
                <PaymentFormat />
              </div>
              <div
                style={{
                  marginRight: 40,
                }}
              >
                <PaymentMethod />
              </div>
            </div>
            <Divider />
          </ProForm>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default NewLead;
