import { useEffect, useState } from "react";
import {
  NewStatus,
} from "./components";
import OrderStatusNavBar from "./components/OrderStatusNavBar";
import { PageContainer } from "@ant-design/pro-components";
import { Button, Card, Form, Input, Layout } from "antd";
import { useForm } from "antd/es/form/Form";
import { changePageTitle } from "../../utils/title";
import { SearchOutlined } from "@ant-design/icons";

const DeliveryStatus = ({ userPermission }) => {
  const [form] = useForm();
  const [mod, setMod] = useState("NEW");
  const [query, setQuery] = useState("");

  useEffect(() => {
    changePageTitle("Статусы доставки");
  }, []);

  const getStatusBody = () => {
    return <NewStatus userPermission={userPermission} query={query} />;
  };

  return (
    <Layout>
      <PageContainer
        title={"Статусы доставки"}
        style={{
          maxHeight: "100vh",
        }}
      >
        <Card>
          <OrderStatusNavBar mod={mod} setMod={setMod} />

          <Form
            form={form}
            style={{
              marginTop: 25,
              display: "flex",
            }}
            onFinish={() => {
              setQuery(form.getFieldValue("query"));
            }}
          >
            <Form.Item
              className="me-2"
              style={{
                width: "100%",
              }}
              name="query"
            >
              <Input placeholder="Поиск по ключевому слову" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="d-flex align-items-center"
              icon={<SearchOutlined />}
            >
              Поиск
            </Button>
          </Form>

          <div
            style={{
              overflow: "auto",
              maxWidth: "100%",
              // minWidth: 1700,
            }}
          >
            {getStatusBody()}
          </div>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default DeliveryStatus;
