import React from 'react';
import { Table } from 'antd';
import { getTableColumns, getTableDataSource } from '../utils/table';

const NPSTable = ({ data, isLoading }) => {
  const columns = getTableColumns();
  const dataSource = getTableDataSource(data);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={false}
        rowKey="_id"
      />
    </div>
  );
};

export default NPSTable;
