import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { fetchClient } from "../Clients/utils/fetch";
import { PageContainer, ProCard } from "@ant-design/pro-components";
import { Collapse, Layout, Table } from "antd";
import { ClientFullInfo, LeadFullInfo } from "./components";
import { clientLeadsColumns, clientLeadsDataSource } from "./utils/table";
import { fetchClientLeads } from "./utils/fetch";
import SpecificationProducts from "./components/SpecificationProducts";
import { changePageTitle } from "../../utils/title";

const { Panel } = Collapse;

const ClientInfo = ({ userPermission }) => {
  const params = useParams();
  const { clientId } = params;
  const [client, setClient] = useState({});
  const [leads, setLeads] = useState([]);
  const [isLeadsLoading, setIsLeadsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showLeadFullInfo, setShowLeadFullInfo] = useState(false);
  const [leadFullInfo, setLeadFullInfo] = useState();

  useEffect(() => {
    let title = "Клиенты";
    if (client) {
      title = client?.name || client?.companyName;
    }

    changePageTitle(title);
  }, [client]);

  useEffect(() => {
    fetchClient(clientId, setClient, setIsLoading);
    fetchClientLeads(clientId, setLeads, setIsLeadsLoading);
  }, [clientId]);

  if (isLoading) {
    return <Loading />;
  }

  const getClientName = () => {
    if (client.clientType === "ENTITY") {
      return client.companyName;
    }

    return client.name;
  };

  return (
    <Layout>
      {showLeadFullInfo && (
        <LeadFullInfo
          onClose={() => setShowLeadFullInfo(false)}
          data={leadFullInfo}
        />
      )}
      <PageContainer>
        <ProCard>
          <Collapse>
            <Panel header={getClientName()}>
              <ClientFullInfo client={client} />
            </Panel>
          </Collapse>
          <Table
            size="small"
            columns={clientLeadsColumns}
            dataSource={clientLeadsDataSource(
              leads,
              setLeadFullInfo,
              setShowLeadFullInfo
            )}
            pagination={false}
            bordered
            loading={isLeadsLoading}
            expandable={{
              expandedRowRender: (record) => (
                <SpecificationProducts
                  clientId={clientId}
                  data={record.data}
                  userPermission={userPermission}
                />
              ),
              defaultExpandAllRows: true,
            }}
          />
        </ProCard>
      </PageContainer>
    </Layout>
  );
};

export default ClientInfo;
