import { message } from "antd";
import config from "../../../config.json";
import { patchRequest, postRequest, getRequest } from "../../../utils/requests";

export const fetchStatuses = (
  state = "NEW",
  status = "",
  query = "",
  setSpecifications = () => {},
  setIsLoading = () => {}
) => {
  let url = `${config.service}/api/appl/state?search=${query}`;

  const body = {
    state,
  };
  if (status) {
    body.status = status;
  }

  const response = postRequest(url, body, {});

  response
    .then((data) => {
      const filteredApplications = data.data.application.filter(app => app.isHasDelivery === true);
      setSpecifications(filteredApplications);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchSpecificationOrders = (
  specificationId,
  setOrders = () => {},
  setIsLoading = () => {}
) => {
  const url = `${config.service}/api/appl/orders/${specificationId}`;

  const isService = (product) => {
    let service = false
    const type = product?.productType || product?.product?.productType
    const name = product?.name || product?.product?.name
    if (type == "6323409e1870a29049455ae4"|| // Монтаж
    type == "6323e9251870a29049456a74"|| // Услуга
    type == "63298b75d5c733d1dfd94c7a"|| // Индивидуальный просчет
    type == "66615dc792168d32c76e3809" ||// Доставка
    /сборка/i.test(name)
    ) {
      service = true
    }
    return service
  }

  const filterProduct = (products) => {
    return products.filter(item => !isService(item))
  }
  
  getRequest(url)
  .then((data) => {
      setOrders(filterProduct(data.data?.products) || []);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const acceptDocumentsForDelivery = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "ACTIVE",
    status: "ON_DELIVERY",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const sendDeliveryCodeToClient = (id, phone, onSuccess, onFinish) => {
  const url = `${config.service}/api/appl/verificationCode/${id}`;
  const body = {
    phone: phone,
    type: "DELIVERY",
  };
  // const hideLoadingMsg = message.loading("Отправляем код на WhatsApp...", 0);

  postRequest(url, body)
    .then(data => {
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      message.error("Ошибка", e);
    })
    .finally(() => {
      // hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const completeDelivery = (pin, lead, onSuccess, onError, onFinish) => {
  const url = `${config.service}/api/appl/verifyDeliveryCode/${lead._id}`;
  const body = {
    id: lead._id,
    code: pin,
    type: "DELIVERY",
    isHasMontage: lead.isHasMontage,
    phone: lead.client[0].phone,
  };

  postRequest(url, body)
    .then(data => {
      console.log('message', data.data);
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      console.log('error while completing delivery', e);
      onError();
      // message.error("Неверный код", 3);
    })
    .finally(() => {
      if (onFinish) {
        onFinish();
      }
    });
}