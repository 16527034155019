import { Segmented } from "antd";

const OrderStatusNavBar = ({ mod, setMod }) => {
  return (
    <Segmented
      style={{
        userSelect: "none",
        width: "100%",
      }}
      size="large"
      block
      value={mod}
      onChange={(value) => setMod(value)}
      color="red"
      className="custom-segmented"
      options={[
        {
          label: (
            <div className="bg-secondary rounded text-white p-0 m-0 w-100">
              Будущие
            </div>
          ),
          value: "FUTURE",
        },
        {
          label: (
            <div className="bg-warning rounded text-white p-0 m-0 w-100">
              Новые
            </div>
          ),
          value: "NEW",
        },
        {
          label: <div className="bg-primary rounded text-white">В процессе</div>,
          value: "ACTIVE",
        },
        {
          label: <div className="bg-primary rounded text-white">Готовы к отгрузке</div>,
          value: "READY_FOR_SHIPMENT",
        },
        {
          label: (
            <div className="bg-success rounded text-white">Сдано</div>
          ),
          value: "COMPLETED",
        },
      ]}
    />
  );
};

export default OrderStatusNavBar;
