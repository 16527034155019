import { getRequest, patchRequest } from "../../../utils/requests";
import config from "../../../config.json";
import { message } from "antd";

export const fetchClients = (
  data,
  setClients = () => {},
  setTotalPage = () => {},
  setIsLoading = () => {},
  setToken
) => {
  setIsLoading(true);

  const url = `${config.service}/api/client?search=${data.search || ""}&size=${
    data.size || 1000
  }&page=${data.page || 1}`;

  const request = getRequest(url, {});

  request
    .then((data) => {
      if (setClients) {
        setClients(data.data.clients);
      }
      if (setTotalPage) {
        setTotalPage(data.data.totalPages);
      }
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        setToken("");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchClient = (
  id,
  setClient = () => {},
  setIsLoading = () => {},
  onForbidden = () => {}
) => {
  setIsLoading(true);

  const url = `${config.service}/api/client/${id}`;

  const request = getRequest(url, {});

  request
    .then((data) => {
      if (setClient) {
        setClient(data.data);
      }
    })
    .catch((err) => {
      if (err?.response?.status === 403) {
        onForbidden();
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const updateClient = (id, body) => {
  const hideLoadingMsg = message.loading("Клиент обновляется...", 0);

  const url = `${config.service}/api/client/${id}`;

  const response = patchRequest(url, body);

  response
    .then(() => {
      message.success("Клиент успешно обновлен", 3);
    })
    .catch(() => {
      message.error("Не удалось обновить клиента", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};
