import { EditOutlined } from "@ant-design/icons";
import {
  ModalForm,
  ProFormGroup,
  ProFormCheckbox,
  ProForm,
  ProFormText,
} from "@ant-design/pro-components";
import { Button, Card } from "antd";
import { useState } from "react";
import { updateCompany } from "../utils/fetch";

const CompanyFullInfo = ({ company }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ModalForm
      initialValues={{
        KP: false,
        act: false,
        agreement: false,
        changeState: false,
        changeStatus: false,
        clients: false,
        createLead: false,
        name: company?.name || "",
        payment: false,
        production: false,
        products: false,
        specification: false,
        assemblyStatus: false,
        manufactureStatus: false,
        ProFormCheckbox: false,
        installationStatus: false,
        ...company.permission,
      }}
      onFinish={(values) => {
        setIsLoading(true);

        const { name } = values;
        delete values.name;

        updateCompany({
          companyId: company._id,
          body: {
            name,
            permission: values,
          },
          setIsLoading,
        });
      }}
      title={company?.name || "Редактировать"}
      trigger={
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 5,
            padding: 5,
          }}
        >
          <EditOutlined />
        </Button>
      }
      submitter={{
        submitButtonProps: {
          loading: isLoading,
        },
      }}
    >
      <ProFormGroup>
        <ProFormText name="name" label="Название" />
      </ProFormGroup>
      <Card>
        <ProForm.Group>
          <ProFormCheckbox name="createLead">Создание заявки</ProFormCheckbox>
          <ProFormCheckbox name="clients">Доступ к клиентам</ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox name="specification">Спецификация</ProFormCheckbox>
          <ProFormCheckbox name="act">Заявка на производство</ProFormCheckbox>
          <ProFormCheckbox name="agreement">Договор</ProFormCheckbox>
          <ProFormCheckbox name="KP">КП</ProFormCheckbox>
          <ProFormCheckbox name="payment">Счет на оплату</ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox name="products">Товары</ProFormCheckbox>
          <ProFormCheckbox name="orderStatus">Статусы заказов</ProFormCheckbox>
          <ProFormCheckbox name="production">Запуск на производство</ProFormCheckbox>
          <ProFormCheckbox name="changeStatus">Изменение статуса</ProFormCheckbox>
        </ProForm.Group>
        <ProForm.Group>
          <ProFormCheckbox name="changeState">
            Принятие / отклонение заявок
          </ProFormCheckbox>
          <ProFormCheckbox name="assemblyStatus">
            Статусы сборки
          </ProFormCheckbox>
          <ProFormCheckbox name="manufactureStatus">
            Статусы производства
          </ProFormCheckbox>
          <ProFormCheckbox name="deliveryStatus">
            Статусы доставки
          </ProFormCheckbox>
          <ProFormCheckbox name="installationStatus">
            Статусы монтажа
          </ProFormCheckbox>
        </ProForm.Group>
      </Card>
    </ModalForm>
  );
};

export default CompanyFullInfo;
