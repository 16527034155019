import { message } from "antd";
import config from "../../../config.json";
import { getRequest, patchRequest } from "../../../utils/requests";

export const fetchCompanies = ({
  setCompanies = () => {},
  setIsLoading = () => {},
  setToken = () => {},
}) => {
  const url = `${config.service}/api/company`;

  const response = getRequest(url, {});

  response
    .then((data) => {
      setCompanies(data.data);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        setToken("");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const updateCompany = async ({
  companyId = "",
  body = {},
  setIsLoading = () => {},
}) => {
  const hideLoadingMsg = message.loading("Загрузка...", 0);

  await patchRequest(`${config.service}/api/company/${companyId}`, body)
    .catch(() => {
      message.error("Ошибка при обновлении", 3);
    })
    .finally(() => {
      setIsLoading(false);
    });

  await patchRequest(
    `${config.service}/api/permission/company/${companyId}`,
    body.permission
  )
    .then(() => {
      message.success("Обновлено", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};
