import { Card, Layout } from "antd";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { useEffect, useState } from "react";
import { fetchCompanyPermissions, fetchCompanyUsers } from "./utils/fetch";
import Loading from "../../components/Loading/Loading";
import { getUsersDataSource, usersColumn } from "./utils/table";
import { CreateUser } from "./components";
import { useParams } from "react-router-dom";
import { changePageTitle } from "../../utils/title";

const Users = ({ userPermission }) => {
  const params = useParams();
  const companyId = params.companyId;

  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [companyPermissions, setCompanyPermissions] = useState({});

  useEffect(() => {
    fetch();
  }, [companyId]);

  const fetch = () => {
    fetchCompanyUsers(companyId, setUsers, setIsUsersLoading);
    fetchCompanyPermissions(companyId, setCompanyPermissions);
  };

  useEffect(() => {
    changePageTitle("Пользователи");
  }, []);

  if (isUsersLoading) {
    return <Loading />;
  }

  return (
    <Layout>
      <PageContainer title="Пользователи">
        <Card>
          <div
            style={{
              marginBottom: 25,
            }}
          >
            {(userPermission.isLocalAdmin || userPermission.isAdmin) && (
              <CreateUser setUsers={setUsers} companyId={companyId} />
            )}
          </div>
          <div style={{ overflow: "auto" }}>
            <ProTable
              bordered
              size="small"
              columns={usersColumn}
              search={false}
              options={false}
              dataSource={getUsersDataSource(users, companyPermissions, fetch)}
              pagination={false}
            />
          </div>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default Users;
