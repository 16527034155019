import { Form, Input, Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import { fetchSuppliesLeads } from "../utils/fetch";
import { suppliesLeadsColumn, suppliesLeadsDataSource } from "../utils/table";
import SuppliesLeadCalc from "./SuppliesLeadCalc";
import Search from "antd/lib/input/Search";

const SuppliesLeadList = ({
  leads,
  setLeads,
  selectedLeads,
  setSelectedLeads,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [query, setQuery] = useState("");

  useEffect(() => {
    loadLeads();
  }, [currentPage, pageSize]);

  const loadLeads = () => {
    fetchSuppliesLeads(
      query,
      currentPage,
      pageSize,
      setLeads,
      setTotalPages,
      setIsLoading
    );
  };

  return (
    <div>
      {/* <div>
        <Form onFinish={loadLeads}>
          <Form.Item name="query">
            <Search
              placeholder="Поиск по ключевому слову..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Form.Item>
        </Form>
      </div> */}
      <div>
        <Table
          bordered
          size="small"
          pagination={false}
          loading={isLoading}
          columns={suppliesLeadsColumn}
          dataSource={suppliesLeadsDataSource(leads)}
          expandable={{
            expandedRowRender: (record) => <SuppliesLeadCalc id={record.key} />,
          }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedLeads,
            onSelect: (record) => {
              if (selectedLeads.includes(record.key)) {
                const newList = [];
                for (let key of selectedLeads) {
                  if (key !== record.key) {
                    newList.push(key);
                  }
                }
                setSelectedLeads(newList);
              } else {
                setSelectedLeads([...selectedLeads, record.key]);
              }
            },
            onSelectAll: (selected, selectedRows) => {
              if (selected) {
                const newList = [];
                for (let row of selectedRows) {
                  newList.push(row.key);
                }
                setSelectedLeads(newList);
              } else {
                setSelectedLeads([]);
              }
            },
            onChange: (_keys, _rows, info) => {
              if (info.type === "none") {
                setSelectedLeads([]);
              }
            },
          }}
        />
      </div>
      <div className="mt-3">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
          total={totalPages * pageSize}
        />
      </div>
    </div>
  );
};

export default SuppliesLeadList;
