import { message } from "antd";
import config from "../../../config.json";
import { patchRequest, postRequest } from "../../../utils/requests";

export const fetchStatuses = (
  state = "NEW",
  status = "",
  query = "",
  setSpecifications = () => {},
  setIsLoading = () => {}
) => {
  let url = `${config.service}/api/appl/state?search=${query}`;

  const body = {
    state,
  };
  if (status) {
    body.status = status;
  }

  const response = postRequest(url, body, {});

  response
    // .then((data) => {
    //   setSpecifications(data.data.application);
    // })
    .then((data) => {
      const filteredApplications = data.data.application.filter(app => app.isHasMontage === true);
      setSpecifications(filteredApplications);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const acceptForInstallation = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "ACTIVE",
    status: "INSTALLING",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
}

export const sendInstallationCodeToClient = (id, phone, onSuccess, onFinish) => {
  const url = `${config.service}/api/appl/verificationCode/${id}`;
  const body = {
    phone: phone,
    type: "INSTALLATION",
  };
  // const hideLoadingMsg = message.loading("Отправляем код на WhatsApp...", 0);

  postRequest(url, body)
    .then(data => {
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      message.error("Ошибка", e);
    })
    .finally(() => {
      // hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const completeInstallation = (pin, lead, onSuccess, onError, onFinish) => {
  const url = `${config.service}/api/appl/verifyInstallationCode/${lead._id}`;
  const body = {
    id: lead._id,
    code: pin,
    phone: lead.client[0].phone,
  };

  postRequest(url, body)
    .then(data => {
      console.log('message', data.data);
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      console.log('error while completing installation', e);
      onError();
      // message.error("Неверный код", 3);
    })
    .finally(() => {
      if (onFinish) {
        onFinish();
      }
    });
}
