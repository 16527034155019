import { Form, Input, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useState } from "react";
import { EntityClientInfo } from ".";
import IndividualClientInfo from "./IndividualClientInfo";
import config from "../../../config.json";
import moment from "moment";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDatePicker,
  ProFormDigit,
} from "@ant-design/pro-components";

const { Option } = Select;

const CustomerInformation = ({ clientType, disabled = false }) => {
  const requiredRule = {
    required: true,
  };

  const getClientData = () => {
    if (clientType === "ENTITY") {
      return <EntityClientInfo disabled={disabled} />;
    }

    return <IndividualClientInfo disabled={disabled} />;
  };

  return (
    <div>
      <h1
        style={{
          marginBottom: 20,
        }}
      >
        Информация о заказчике
      </h1>
      <ProForm.Group width="lg">
        <ProFormSelect
          label="Тип клиента"
          name="clientType"
          placeholder="Тип клиента"
          rules={[requiredRule]}
          disabled={disabled}
          options={[
            {
              value: "INDIVIDUAL",
              label: "Физическое лицо",
            },
            {
              value: "ENTITY",
              label: "Юридическое лицо",
            },
          ]}
        />
        {getClientData()}
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText
          name="address"
          label="Адрес"
          placeholder="Адрес"
          disabled={disabled}
        />
        <ProFormDatePicker
          name="deadline"
          placeholder="Срок сдачи"
          label="Срок сдачи"
          format="DD-MM-YYYY"
        />
        <ProFormDigit
          min={0}
          max={100}
          name="designerDiscount"
          placeholder="Дизайнерская скидка"
          label="Дизайнерская скидка"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText
          name="phone"
          placeholder="+"
          label="Контакт №1"
          rules={[requiredRule]}
          disabled={disabled}
        />
        <ProFormText
          name="additionalPhone"
          placeholder="+"
          label="Контакт №2"
          disabled={disabled}
        />
      </ProForm.Group>
    </div>
  );
};

export default CustomerInformation;
