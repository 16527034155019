import { Pagination } from "antd";
import { Link } from "react-router-dom";
import ClientsPopover from "../../ClientInfo/ClientsPopover";

export const getTableColumns = () => {
  return [
    {
      title: "№",
      dataIndex: "number",
      key: "number",
      width: 50,
    },
    {
      title: "Дата регистрации",
      dataIndex: "registrationDate",
      key: "registrationDate",
      width: 100,
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          ФИО
        </div>
      ),
      dataIndex: "fullName",
      key: "fullName",
      render: (_, record) => {
        return <div>{getClientName(record)}</div>;
      },
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Ответственные
        </div>
      ),
      dataIndex: "responsibles",
      key: "responsibles",
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Оценка NPS
        </div>
      ),
      dataIndex: "npsScore",
      key: "npsScore",
      render: (npsScore) => renderNpsScore(npsScore),
    },
    {
      title: <></>,
      dataIndex: "more",
      key: "more",
      fixed: "right",
    },
  ];
};

const getClientName = (client) => {
  let name = client.fullNameDirector;

  if (client.clientType === "INDIVIDUAL") {
    name = client.name;
  }

  return <Link to={`/client/${client._id}`}>{name}</Link>;
};

const renderNpsScore = (npsScore) => {
  if (Array.isArray(npsScore)) {
    return npsScore.map((item, index) => (
      <div key={index}>
        {item.score} (дата: {new Date(item.date).toLocaleDateString()})
      </div>
    ));
  } else if (npsScore && typeof npsScore === 'object') {
    return (
      <div>
        {npsScore.score} (дата: {new Date(npsScore.date).toLocaleDateString()})
      </div>
    );
  } else {
    return 'Нет данных';
  }
};

export const getTableDataSource = (clients = []) => {
  if (!Array.isArray(clients)) {
    return [];
  }

  const data = [];

  const getUsers = (user) => {
    if (Array.isArray(user)) {
      let users = [];

      for (let u of user) {
        users.push(`${u.name} ${u.surname}`);
      }

      return (
        <div>
          {users.map((u, index) => {
            return <div key={index}>{u}</div>;
          })}
        </div>
      );
    } else {
      return "N/A";
    }
  };

  let number = 1;
  for (let client of clients) {
    const users = client.user || [];

    if (client.npsScore && client.npsScore.length > 0) {
      const newData = {
        data: client,
        _id: client._id,
        responsibles: getUsers(users),
        clientType: client.clientType,
        fullNameDirector: client.fullNameDirector,
        name: client.name,
        number: (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {number}
          </div>
        ),
        registrationDate: (
          <div
            style={{
              width: 100,
            }}
          >
            {new Date(client.createdAt).toLocaleDateString()}
          </div>
        ),
        npsScore: client.npsScore,
        more: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ClientsPopover data={client} />
          </div>
        ),
      };

      data.push(newData);
      number++;
    }
  }

  return data;
};

export const getPagination = (
  currentPage,
  setCurrentPage,
  limit,
  setLimit,
  totalPage
) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        margin: "15px 0",
      }}
    >
      <Pagination
        current={currentPage}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setLimit(pageSize);
        }}
        pageSize={limit}
        total={totalPage * limit}
      />
    </div>
  );
};
