import axios from "axios";
import config from "../../../config.json";

export const getKeplerPartners = async () => {
  const url = `${config.service}/api/kepler-partner`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });

  return response.data;
};

export const updateKeplerPartner = async (id, data) => {
  const url = `${config.service}/api/kepler-partner/${id}`;

  await axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const deleteKeplerPartner = async (id) => {
  const url = `${config.service}/api/kepler-partner/${id}`;

  await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getKeplerPartnerDiscounts = async (id) => {
  const url = `${config.service}/api/kepler-partner-discount/${id}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });

  return response.data;
};

export const updateKeplerPartnerDiscounts = async (id, data) => {
  const url = `${config.service}/api/kepler-partner-discount/${id}`;

  const response = await axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });

  return response.data;
};
