import { Button, Checkbox, Drawer, Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import {
  createProduct,
  fetchDoorTypes,
  fetchProductTypes,
} from "../utils/fetch";

const { Option } = Select;

const CreateProduct = ({ onClose, visible, fetchProducts, accessories }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [doorTypes, setDoorTypes] = useState([]);

  const [data, setData] = useState({
    name: "",
    productType: "",
    productTypeName: "",
    price: "",
    costPrice: "",
    description: "",
    minHeight: "",
    maxHeight: "",
    minWidth: "",
    maxWidth: "",
    doorType: "",
    thickness: 42,
    doorStyle: "SINGLE_DOORS",
    isSlidingDoor: false,
    doorCovering: "PRIMED",
  });

  useEffect(() => {
    fetchProductTypes(setProductTypes);
    fetchDoorTypes(setDoorTypes);
  }, []);

  const onFinish = () => {
    setIsLoading(true);

    createProduct(data, setIsLoading, fetchProducts);
  };

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      title="Добавить товар"
      style={{
        minWidth: 400,
      }}
      extra={
        <Space>
          <Button onClick={onClose}>Отменить</Button>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            onClick={onFinish}
          >
            Сохранить
          </Button>
        </Space>
      }
    >
      <div>
        <Form.Item name="name">
          <Input
            placeholder="Наименование"
            value={data.name}
            onChange={(event) => {
              setData({
                ...data,
                name: event.target.value,
              });
            }}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item name="productType">
          <Select
            placeholder="Тип товара"
            value={data.productType}
            onChange={(productType) => {
              const foundProductType = productTypes.find(
                (v) => v._id === productType
              );

              setData({
                ...data,
                productType,
                productTypeName: foundProductType.name,
              });
            }}
            disabled={isLoading}
          >
            {productTypes.map((productType, index) => {
              return (
                <Option key={index} value={productType._id}>
                  {productType.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {data.productTypeName === "Двери" && (
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item style={{ width: "49%" }}>
                  <Input
                    type={"number"}
                    placeholder="Мин. ширина"
                    onChange={(event) => {
                      setData({
                        ...data,
                        minWidth: event.target.value,
                      });
                    }}
                    disabled={isLoading}
                  />
                </Form.Item>
                <Form.Item style={{ width: "49%" }}>
                  <Input
                    type={"number"}
                    placeholder="Макс. ширина"
                    onChange={(event) => {
                      setData({
                        ...data,
                        maxWidth: event.target.value,
                      });
                    }}
                    disabled={isLoading}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item style={{ width: "49%" }}>
                  <Input
                    type={"number"}
                    placeholder="Мин. высота"
                    onChange={(event) => {
                      setData({
                        ...data,
                        minHeight: event.target.value,
                      });
                    }}
                    disabled={isLoading}
                  />
                </Form.Item>
                <Form.Item style={{ width: "49%" }}>
                  <Input
                    type={"number"}
                    placeholder="Макс. высота"
                    onChange={(event) => {
                      setData({
                        ...data,
                        maxHeight: event.target.value,
                      });
                    }}
                    disabled={isLoading}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <Select
                placeholder="Тип двери"
                disabled={isLoading}
                onChange={(value) => {
                  setData({
                    ...data,
                    doorType: value,
                  });
                }}
              >
                {doorTypes.map((doorType, index) => {
                  return (
                    <Option key={index} value={doorType._id}>
                      {doorType.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="doorStyle">
              <Select
                defaultValue={data.doorStyle}
                disabled={isLoading}
                onChange={(value) => {
                  setData({
                    ...data,
                    doorStyle: value,
                  });
                }}
                options={[
                  {
                    value: "SINGLE_DOORS",
                    label: "Одностворчатые",
                  },
                  {
                    value: "DOUBLE_DOORS",
                    label: "Двустворчатые",
                  },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item name="doorStyle">
              <Select
                placeholder="Толщина двери"
                defaultValue={data.thickness}
                disabled={isLoading}
                onChange={(value) => {
                  setData({
                    ...data,
                    thickness: value,
                  });
                }}
                options={[
                  {
                    value: 42,
                  },
                  {
                    value: 57,
                  },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item name="isSlidingDoor">
              <Checkbox
                defaultValue={data.isSlidingDoor}
                disabled={isLoading}
                onChange={(value) => {
                  setData({
                    ...data,
                    isSlidingDoor: value.target.checked,
                  });
                }}
              >
                Раздвижной дверь
              </Checkbox>
            </Form.Item>
            <Form.Item name="doorCovering">
              <Select
                value={data.doorCovering}
                onChange={(value) => {
                  setData({
                    ...data,
                    doorCovering: value,
                  });
                }}
                options={[
                  {
                    value: "PRIMED",
                    label: "Грунтованные",
                  },
                  {
                    value: "VENEERED",
                    label: "Шпонированные",
                  },
                  {
                    value: "MIRROR",
                    label: "Зеркальные",
                  },
                  {
                    value: "PAINTED",
                    label: "Крашенные",
                  },
                ]}
              />
            </Form.Item>
          </>
        )}
        <Form.Item name={"price"}>
          <Input
            placeholder="Стоимость"
            type={"number"}
            value={data.price}
            onChange={(event) => {
              setData({
                ...data,
                price: event.target.value,
              });
            }}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item name="costPrice">
          <Input
            placeholder="Себестоимость"
            type={"number"}
            value={data.costPrice}
            onChange={(event) => {
              setData({
                ...data,
                costPrice: event.target.value,
              });
            }}
            disabled={isLoading}
          />
        </Form.Item>

        <Form.Item name="description">
          <Input.TextArea
            placeholder="Описание"
            value={data.description}
            onChange={(event) => {
              setData({
                ...data,
                description: event.target.value,
              });
            }}
            disabled={isLoading}
          />
        </Form.Item>
      </div>
    </Drawer>
  );
};

export default CreateProduct;
