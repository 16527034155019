import { formatStringToDate } from "./format";
import { AcceptButton, CompleteButton } from "../components/InstallationModal";
import { getDeadlineColor } from "../../../utils/deadlineColor"

export const specificationProductsColumn = [
  {
    title: "№",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Наименование товара",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Размеры",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Открывание",
    dataIndex: "opening",
    key: "opening",
  },
  {
    title: "Алюминий",
    dataIndex: "aluminium",
    key: "aluminium",
  },
  {
    title: "Кол - во",
    dataIndex: "amount",
    key: "amount",
    width: 75,
  }
];

export const orderStatusColumnsNew = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 300,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "fullName",
    title: (
      <div
        style={{
          width: 200,
        }}
      >
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "actions",
    title: "",
    dataIndex: "actions",
    width: 200,
  },
];

export const getOrderStatusDataSource = (leads, fetch) => {
  const getActions = (lead) => {
    if (lead.state === "ACTIVE" && lead.status === "PASSED_TO_MONTAGE") {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AcceptButton lead={lead} fetch={fetch}/>
        </div>
      )
    }
    if (lead.state === "ACTIVE" && lead.status === "INSTALLING") {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompleteButton lead={lead} fetch={fetch}/>
        </div>
      )
    }
  }

  const dataSource = [];
  for (let lead of leads) {
    const client = lead.client[0];

    const user = lead?.user;

    let responsibleUser = "";
    if (user) {
      responsibleUser = `${user.name} ${user.surname}`;
    }

    let props = {};
    if (lead.state === "ACTIVE") {
      props = getDeadlineColor(lead?.montageDeadline, 1);
    }

    let aluminiumColor = "";
    if (Array.isArray(lead?.aluminium)) {
      aluminiumColor = lead?.aluminium.filter((v) => v !== "").join(", ");
    }

    dataSource.push({
      key: lead._id,
      data: lead,
      montage: lead.isHasMontage ? "Есть" : "Нет",
      responsibleUser,
      agreementNumber: lead?.lead?.agreementNumber,
      phoneNumber: client?.phone,
      address: lead?.lead?.address,
      fullName: client?.name || client?.companyName,
      deadline: (
        <div {...props}>{new Date(lead?.montageDeadline).toLocaleDateString()}</div>
      ),
      amount: lead?.doorCount ? parseInt(lead?.doorCount) : 0,
      aluminiumColor,
      date: new Date(lead?.createdAt).toLocaleDateString(),
      actions: getActions(lead),
    });
  }

  return dataSource;
};

export const orderStatusColumnsActive = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 300,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "fullName",
    title: (
      <div
        style={{
          width: 200,
        }}
      >
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 200,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "actions",
    title: "",
    dataIndex: "actions",
    width: 200,
  },
];

export const orderStatusColumnsCompleted = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 300,
  },
  {
    key: "amount",
    title: "Кол-во",
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 87,
  },
];
