import { Segmented } from "antd";

const SuppliesNavBar = ({ mod, setMod }) => {
  return (
    <Segmented
      size="medium"
      block
      value={mod}
      onChange={setMod}
      options={[
        {
          label: "Заказы",
          value: "lead",
        },
        {
          label: "Итого",
          value: "total",
        },
        {
          label: "Склад",
          value: "warehouse",
        },
      ]}
    />
  );
};

export default SuppliesNavBar;
