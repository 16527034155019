import { EditOutlined } from "@ant-design/icons";
import { Button} from "antd";
import {
  ModalForm,
  ProForm,
  ProFormText,
  ProFormSelect,
} from "@ant-design/pro-components";
// import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/es/form/Form";
import React from "react";
import {  useState } from "react";
import { updateEntity } from "../utils/fetch";
// import axios from "axios";
// import config from "../../../config.json";
import { banks } from "../../NewLead/utils/banks";

const UpdateEntity = ({ data, fetch }) => {
  const [isVisible, setIsVisible] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const requiredRule = {
    required: true,
  };


const onValuesChange = (value) => {
    console.log('Changing value');
    if (value.accountNumber) {
      const accountNumber = value.accountNumber;
      if (accountNumber.length >= 7) {
        const bankCode = accountNumber.substring(4, 7);
        const bank = banks.find((v) => v.code === bankCode);

        if (bank) {
          form.setFieldValue("bankName", bank.name);
          form.setFieldValue("BIC", bank.BIC);
        } else {
          form.setFieldValue("bankName", "");
          form.setFieldValue("BIC", "");
        }
      } else {
        form.setFieldValue("bankName", "");
        form.setFieldValue("BIC", "");
      }
    }
    form.setFieldValue("clientType", "INDIVIDUAL"); // edit string to data.clientType
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className="me-2"
      >
       
      </div>
      <ModalForm
        form={form}
        onValuesChange={onValuesChange}
        visible={isVisible}
        onVisibleChange={setIsVisible}
        title={""} // title
        onFinish={(values) => {
              updateEntity(
                data._id,
                {
                  ...values,
                },
                () => {
                  setIsVisible(false);
                }
              );
            }}
        trigger={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
                style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 5,
                padding: 5,
          }}
        >
          <EditOutlined />
        </Button>
          </div>
        }
      >
        <h1
        style={{
          marginBottom: 20,
        }}
      >
        Редактировать
      </h1>
        <ProForm.Group>
        <ProFormText
          name="companyName"
          label="Наименование компании"
          placeholder="Наименование компании"
          rules={[requiredRule]}
        />
        <ProFormText
          name="fullNameDirector"
          label="Фамилия и инициалы директора"
          placeholder="Фамилия и инициалы директора"
          rules={[requiredRule]}
        />
        </ProForm.Group>
        <ProForm.Group>
        <ProFormSelect
          placeholder="На каком основании является директором"
          label="На каком основании является директором"
          name="onWhatBasisDirector"
          rules={[requiredRule]}
          options={[
            {
              value: "BY_STATUE",
              label: "на основании устава",
            },
            {
              value: "BASED_ON_THE_DECISION_OF_THE_FOUNDERS",
              label: "на основании решения учредителей",
            },
            {
              value: "ON_THE_BASIS_OF_AN_ORDER",
              label: "на основании приказа",
            },
          ]}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText
          name="BIN"
          label="БИН"
          placeholder="БИН"
          rules={[requiredRule]}
        />
        <ProFormText
          name="accountNumber"
          label="Номер счета"
          placeholder="Номер счета"
          rules={[requiredRule]}
        />
        </ProForm.Group>
        <ProForm.Group>
        <ProFormText
          name="bankName"
          label="Наименование банка"
          placeholder="Наименование банка"
          rules={[requiredRule]}
          disabled={true}
        />
        <ProFormText
          name="BIC"
          label="БИК"
          placeholder="БИК"
          rules={[requiredRule]}
          disabled={true}
        />
        </ProForm.Group>
        <ProForm.Group width="lg">
        <ProFormSelect
          label="Тип клиента"
          name="clientType"
          placeholder="Тип клиента"
          rules={[requiredRule]}
          options={[
            {
              value: "INDIVIDUAL",
              label: "Физическое лицо",
            },
            {
              value: "ENTITY",
              label: "Юридическое лицо",
            },
          ]}
        />
      </ProForm.Group>
      </ModalForm>
    </div>
  );
};

export default UpdateEntity;

