import { PageContainer } from "@ant-design/pro-components";
import { Card, Table } from "antd";
import AddKeplerPartner from "./components/AddKeplerPartner";
import { useEffect, useState } from "react";
import { getKeplerPartners } from "./utils/fetch";
import {
  getKeplerPartnersColumn,
  getKeplerPartnersDataSource,
} from "./utils/table";
import KeplerPartnerInfo from "./components/KeplerPartnerInfo";

const KeplerPartners = () => {
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);
    getKeplerPartners()
      .then(setPartners)
      .finally(() => setIsLoading(false));
  };
  useEffect(fetch, []);

  return (
    <PageContainer title="Партнеры">
      <Card>
        <AddKeplerPartner onSuccess={fetch} />
        <Table
          className="mt-3"
          size="small"
          pagination={false}
          bordered
          loading={isLoading}
          columns={getKeplerPartnersColumn()}
          dataSource={getKeplerPartnersDataSource(partners, fetch)}
          expandable={{
            expandedRowRender: (record) => (
              <KeplerPartnerInfo partner={record.data} fetch={fetch} />
            ),
          }}
        />
      </Card>
    </PageContainer>
  );
};

export default KeplerPartners;
