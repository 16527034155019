import { Button, Popconfirm, Switch } from "antd";
import { deleteKeplerPartner, updateKeplerPartner } from "./fetch";
import { DeleteOutlined } from "@ant-design/icons";

export const getKeplerPartnersColumn = () => {
  return [
    {
      title: "Название",
      dataIndex: "name",
      key: "",
    },
    {
      title: "Номер телефона",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "",
      dataIndex: "launch",
      key: "launch",
      width: 50,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      width: 50,
    },
  ];
};

export const getKeplerPartnersDataSource = (partners, fetch) => {
  const result = [];

  for (const partner of partners) {
    result.push({
      key: partner._id,
      data: partner,
      name: partner.name,
      phoneNumber: partner.phoneNumber,
      address: partner.address,
      launch: (
        <div>
          <Switch
            defaultChecked={partner.isActive}
            onChange={(v) => {
              updateKeplerPartner(partner._id, { isActive: v });
            }}
          />
        </div>
      ),
      delete: (
        <Popconfirm
          title={
            <div style={{ minWidth: 300 }}>
              Вы точно хотите удалить партнера <b>{partner.name}</b>?
            </div>
          }
          okButtonProps={{
            className: "text-danger bg-danger border-danger text-white",
          }}
          okText="Удалить"
          onConfirm={() => {
            deleteKeplerPartner(partner._id).then(fetch);
          }}
        >
          <Button
            className="text-danger border-danger"
            icon={<DeleteOutlined />}
          ></Button>
        </Popconfirm>
      ),
    });
  }

  return result;
};
