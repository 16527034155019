import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../utils/requests";
import config from "../../../config.json";
import { message } from "antd";
import axios from "axios";

export const fetchClientLeads = (
  id,
  setLeads = () => {},
  setIsLoading = () => {}
) => {
  setIsLoading(true);

  const url = `${config.service}/api/lead/${id}`;

  const request = getRequest(url, {});

  request
    .then((data) => {
      if (setLeads) {
        setLeads(data.data);
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const requestSendAgreement = (id) => {
  const hideLoadingMsg = message.loading("Отправка договора", 0);

  const url = `${config.service}/api/file/agreement/${id}`;

  postRequest(url, {})      
    .then(() => {
      message.success("Договор успешно отправлен", 3);

      window.open(url);
    })
    .catch(() => {
      message.error("Не удалось отправить договор", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const requestSendCommercialProposal = (id) => {
  const hideLoadingMsg = message.loading("Отправка КП", 0);

  const url = `${config.service}/api/file/commercial-proposal/${id}`;

  postRequest(url, {})
    .then(() => {
      message.success("КП успешно отправлен", 3);

      window.open(url);
    })
    .catch(() => {
      message.error("Не удалось отправить КП", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};
export const requestSendForProduction = (id) => {
  const hideLoadingMsg = message.loading("Отправка заявки на производство", 0);

  const url = `${config.service}/api/file/act/${id}`;

  postRequest(url, {})
    .then(() => {
      message.success("Заявка на производство успешно отправлен", 3);

      window.open(url);
    })
    .catch(() => {
      message.error("Не удалось отправить заявку на производство", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const deleteClient = (id) => {
  const hideLoadingMsg = message.loading("Удаление", 0);

  const url = `${config.service}/api/client/${id}`;

  deleteRequest(url, {}, {})
    .then(() => {
      message.success("Клиент успешно удален", 3);

      window.location.reload();
    })
    .catch(() => {
      message.error("Не удалось удалить клиента", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const sendToProduction = (id, orders) => {
  let isHasProduction = false;
  let isHasAssembly = false;
  let isHasDelivery = false;
  for (const order of orders) {
    const type = order?.product?.productType || order?.productType;
    const name = order?.product?.name || order.name;
    if (type == "6322ce1de19c7310d0d3beb8" || // добор
      type == "6322cf14e19c7310d0d3bf11" || // двери
      type == "646748cb4efe74aa73b8aa08" || // двери
      type == "64674bc74efe74aa73b8baff" || // двери
      type == "64e489a39c602ea5f5576888" || // двери
      type == "6322cd90e19c7310d0d3be07" || // Скрытый плинтус
      type == "6322cea1e19c7310d0d3beca" || // Наличник
      type == "63244a051870a29049457573" || // Фальш панель
      type == "632835c12faac0f3b06c1c30" || // Стеновая панель
      type == "645b7b034efe74aa73ae608b" // Плинтус
     ) {
      isHasProduction = true
    }

    if (order.assembly === true || /сборка/i.test(name)) {
      isHasAssembly = true;
    }

    if (type == "66615dc792168d32c76e3809" || /доставка/i.test(name)) {
      isHasDelivery = true;
    }
  }

  let body;
  
  if (isHasProduction) {
      body= {
          status: "REVIEW",
          state: "NEW",
        };
      } else if (!isHasProduction && isHasAssembly) {
          body= {
              status: "PASSED_TO_ASSEMBLY",
              state: "ACTIVE",
            };
          } else {
            body= {
                status: "READY_FOR_SHIPMENT",
                state: "ACTIVE",
              };
            }
          // else if (!isHasProduction && !isHasAssembly && isHasDelivery) {
          //     body= {
          //         status: "READY_FOR_SHIPMENT",
          //         state: "ACTIVE",
          //       };
          //     } else if (!isHasProduction && !isHasAssembly && !isHasDelivery) {
          //         body= {
          //             status: "PICKUP",
          //             state: "ACTIVE",
          //           };
          //         }
  const hideLoadingMsg = message.loading("Отправка на производство", 0);
  const url = `${config.service}/api/appl/production/${id}`;
  const response = postRequest(url, body);

  // const response = postRequest(url, {
  //     status: "REVIEW",
  //     state: "NEW",
  //   });

  response
    .then(() => {
      message.success("Спецификация успешно отправлена на производство", 6);
    })
    .catch(() => {
      message.error("Не удалось отправить на производство", 6);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const sendPromoCodeToClient = (id) => {
  const hideLoadingMsg = message.loading("Отправка на промо код клиенту", 0);

  const url = `${config.service}/api/appl/send-promo-code-to-client/${id}`;

  const response = postRequest(url);

  response
    .then(() => {
      message.success("Промо код успешно отправлен клиенту", 3);
    })
    .catch(() => {
      message.error("Не удалось отправить промо код клиенту", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const sendToBooking = (id) => {
  const hideLoadingMsg = message.loading("Ставим на бронь", 0);

  const url = `${config.service}/api/appl/production/${id}`;

  const response = postRequest(url, {
    status: "BOOKING",
    state: "NEW",
  });

  response
    .then(() => {
      message.success("Успешно забронирована", 3);
    })
    .catch(() => {
      message.error("Не удалось забронировать", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const fetchSpecificationOrders = (
  specificationId,
  setOrders = () => {},
  setIsLoading = () => {}
) => {
  const url = `${config.service}/api/appl/orders/${specificationId}`;

  getRequest(url)
    .then((data) => {
      setOrders(data.data?.products || []);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const sendInvoiceForPayment = (specificationId) => {
  const hideLoadingMsg = message.loading('Отправка "Счет на оплату"', 0);

  const url = `${config.service}/api/file/invoice-payment/${specificationId}`;

  postRequest(url)
    .then(() => {
      window.open(url);

      message.success('"Счет на оплату" успешно отправлен', 2);
    })
    .catch(() => {
      message.error('Не удалось отправить "Счет на оплату"', 2);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const sendOrderForSupplies = (id, orders, onSuccess) => {
  const hideLoadingMsg = message.loading("Отправка заявки на закуп", 0);

  const url = `${config.service}/api/file/equipments-by-spec/${id}`;

  postRequest(url, {})
    .then(() => {
      message.success("Заявка на закуп успешно отправлена", 6);

      window.open(url);
      onSuccess(id, orders)
    })
    .catch((e) => {
      if (e) {
        message.error(`${e.response.data.message}`, 6);
      } else message.error("Не удалось отправить заявку на закуп", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
};

export const notifyDepartments = (id) => {
  const hideLoadingMsg = message.loading("Отправка оповещений", 0);

  const url = `${config.service}/api/appl/notify-departments/${id}`;

  postRequest(url, {})
    .then(() => {
      message.success("Оповещения успешно отправлены", 3);
    })
    .catch(() => {
      message.error("Не удалось отправить оповещения", 3);
    })
    .finally(() => {
      hideLoadingMsg();
    });
}