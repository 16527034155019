import { AutoComplete, Input, InputNumber, Select } from "antd";
import { useState } from "react";
import { formulaOptions, unitOptions } from "../../../constants/options";
import { updateAccessory } from "../utils/fetch";

export const AccessoryName = ({ name, id }) => {
  const [value, setValue] = useState(name || "");

  const update = () => {
    updateAccessory(id, { name: value });
  };

  return (
    <Input
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      style={{ minWidth: 250 }}
      onBlur={update}
    />
  );
};

export const AccessoryCostPrice = ({ costPrice, id }) => {
  const [value, setValue] = useState(costPrice || 0);

  const update = () => {
    updateAccessory(id, { costPrice: value });
  };

  return (
    <InputNumber
      value={value}
      onChange={(event) => {
        setValue(event);
      }}
      style={{ width: 110 }}
      onBlur={update}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
      parser={(value) => value.replace(/\$\s?|( *)/g, "")}
    />
  );
};

export const AccessoryUnit = ({ unit, id }) => {
  const [value, setValue] = useState(unit || 0);

  const update = () => {
    updateAccessory(id, { unit: value });
  };

  return (
    <AutoComplete
      value={value}
      onChange={(event) => {
        setValue(event);
      }}
      style={{ width: 90 }}
      onBlur={update}
      options={unitOptions}
      placeholder="Введите ед. измерения..."
      filterOption={(inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    />
  );
};

export const AccessoryFormula = ({ formula, id }) => {
  const [value, setValue] = useState(formula);
  const [isLoading, setIsLoading] = useState(false);

  const update = (value) => {
    setValue(value);
    setIsLoading(true);

    updateAccessory(id, { statement: value }, setIsLoading);
  };

  return (
    <Select
      loading={isLoading}
      style={{
        width: 180,
      }}
      value={value}
      onChange={(v) => {
        update(v);
      }}
      allowClear
      placeholder="Выберите формулу..."
      options={formulaOptions}
    />
  );
};
