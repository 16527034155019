import { getRowColor } from "./color";
import { formatStringToDate } from "./format";
import { AcceptDocumentsForDeliveryButton, CompleteDeliveryButton } from "../components/DeliveryActionModal";

export const specificationProductsColumn = [
  {
    title: "№",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Наименование товара",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Размеры",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Открывание",
    dataIndex: "opening",
    key: "opening",
  },
  {
    title: "Алюминий",
    dataIndex: "aluminium",
    key: "aluminium",
  },
  {
    title: "Кол - во",
    dataIndex: "amount",
    key: "amount",
    width: 75,
  }
];

export const orderStatusColumnsNew = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 250,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "fullName",
    title: (
      <div
        style={{
          width: 200,
        }}
      >
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 200,
  },
  {
    key: "actions",
    title: "",
    dataIndex: "actions",
    width: 200,
  },
];

export const getOrderStatusDataSource = (leads, fetch) => {
  const getActions = (lead) => {
    if (lead.state === "ACTIVE" && lead.status === "READY_FOR_SHIPMENT") {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AcceptDocumentsForDeliveryButton lead={lead} fetch={fetch}/>
        </div>
      )
    }
    if (lead.state === "ACTIVE" && lead.status === "ON_DELIVERY") {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompleteDeliveryButton lead={lead} fetch={fetch}/>
        </div>
      )
    }
  }

  const dataSource = [];
  for (let lead of leads) {
    const client = lead.client[0];

    const user = lead?.user;

    let responsibleUser = "";
    if (user) {
      responsibleUser = `${user.name} ${user.surname}`;
    }

    let props = {};
    if (lead.state === "ACTIVE") {
      props = getRowColor(lead);
    }

    let aluminiumColor = "";
    if (Array.isArray(lead?.aluminium)) {
      aluminiumColor = lead?.aluminium.filter((v) => v !== "").join(", ");
    }

    dataSource.push({
      key: lead._id,
      data: lead,
      montage: lead.isHasMontage ? "Есть" : "Нет",
      responsibleUser,
      agreementNumber: lead?.lead?.agreementNumber,
      phoneNumber: client?.phone,
      address: lead?.lead?.address,
      fullName: client?.name || client?.companyName,
      amount: lead?.doorCount ? parseInt(lead?.doorCount) : 0,
      aluminiumColor,
      date: new Date(lead?.createdAt).toLocaleDateString(),
      actions: getActions(lead),
    });
  }

  return dataSource;
};