export const DOOR_TYPES_OPTIONS = [
  {
    label: "Одностворчатые",
    value: "SINGLE_LEAF",
  },
  {
    label: "Двустворчатые",
    value: "DOUBLE_LEAF",
  },
  {
    label: "Зеркальные",
    value: "MIRROR",
  },
];
