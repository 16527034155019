import { Button, Form, Input, message } from "antd";
import { MaskedInput } from "antd-mask-input";
import config from "../../../config.json";
import { useForm } from "antd/lib/form/Form";
import { updateKeplerPartner } from "../utils/fetch";
import { useState } from "react";
import KeplerPartnerImagesSettings from "./KeplerPartnerImagesSettings";

const KeplerPartnerSettings = ({ partner, fetch }) => {
  return (
    <div className="my-3">
      <div className="pb-3 mb-3 border-bottom">
        <KeplerPartnerGeneralSettings partner={partner} fetch={fetch} />
      </div>
      <div>
        <KeplerPartnerImagesSettings partner={partner} fetch={fetch} />
      </div>
    </div>
  );
};

const KeplerPartnerGeneralSettings = ({ partner, fetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    updateKeplerPartner(partner._id, values)
      .then(() => {
        message.success("Партнер успешно обновлен");
        fetch();
      })
      .catch(() => {
        message.error("Не удалось обновить партнера");
      })
      .finally(() => setIsLoading(false));
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <h1>Общие настройки</h1>
      <Form initialValues={partner} onFinish={onFinish} form={form}>
        <Form.Item
          label="Название"
          labelCol={{ span: 24 }}
          name="name"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <div className="d-flex w-100">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Адрес"
            name="address"
            className="w-100 me-1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Ссылка на 2GIS"
            name="addressUrl"
            className="w-100 ms-1"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          labelCol={{ span: 24 }}
          label="Номер телефона"
          name="phoneNumber"
          rules={[{ required: true, message: "Введите номер телефона" }]}
        >
          <MaskedInput mask={config.mask.phone} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label="Ссылка на сайт"
          name="webSiteUrl"
          className="w-100 ms-1"
        >
          <Input />
        </Form.Item>
        <div className="d-flex w-100">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Cсылка на Telegram"
            name="telegramUrl"
            className="w-100 me-1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Ссылка на Facebook"
            name="facebookUrl"
            className="w-100 ms-1"
          >
            <Input />
          </Form.Item>
        </div>
        <div className="d-flex w-100">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Instagram"
            name="instagramUsername"
            className="w-100 me-1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Cсылка на Instagram профиль"
            name="instagramUrl"
            className="w-100 ms-1"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item labelCol={{ span: 24 }} label="E-mail" name="email">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Описание" labelCol={{ span: 24 }}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <div className="mt-3">
          <Button
            type="primary"
            className="me-1"
            htmlType="submit"
            loading={isLoading}
          >
            Сохранить
          </Button>
          <Button onClick={onReset} disabled={isLoading}>
            Сбросить
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default KeplerPartnerSettings;
