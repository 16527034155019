import {
  CheckOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Card, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  fetchSpecificationOrders,
  requestSendAgreement,
  requestSendCommercialProposal,
  sendInvoiceForPayment,
  sendPromoCodeToClient,
  sendToBooking,
  sendToProduction,
  sendOrderForSupplies,
  notifyDepartments,
} from "../utils/fetch";
import {
  getSpecificationProductsDataSource,
  specificationProductsColumn,
} from "../utils/table";

const SpecificationProducts = ({ clientId, data, userPermission }) => {
  const specifications = data?.applications || [];

  return (
    <div>
      {specifications.map((specification, index) => {
        return (
          <SpecificSpecificationProducts
            key={index}
            specification={specification}
            clientId={clientId}
            index={index}
            userPermission={userPermission}
          />
        );
      })}
    </div>
  );
};

const SpecificSpecificationProducts = ({
  specification,
  clientId,
  index,
  userPermission,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchSpecificationOrders(specification._id, setOrders, setIsLoading);
  }, [specification]);

  const isInProduction = () => {
    if (specification.state !== "NEW") {
      return true;
    } else if (specification.status === "REVIEW") {
      return true;
    }

    return false;
  };

  const getIcon = () => {
    if (specification.state === "NEW" && specification.status === "NEW") {
      return <></>;
    }

    if (specification.state === "NEW") {
      if (specification.status === "REFUSED") {
        return (
          <Tooltip title="Отклонено">
            <WarningOutlined className="text-warning" />
          </Tooltip>
        );
      } else if (specification.status === "BOOKING") {
        return (
          <Tooltip title="Бронь">
            <QuestionCircleOutlined className="text-primary" />
          </Tooltip>
        );
      }
    }

    return (
      <Tooltip title="В производстве">
        <CheckOutlined className="text-success" />
      </Tooltip>
    );
  };

  return (
    <Card className={`my-2`}>
      <div className="d-flex align-items-center pb-3">
        <div className="d-flex align-items-center">
          <h1 className="m-0">
            Cпецификация №{index + 1} от{" "}
            {new Date(specification.createdAt).toLocaleDateString()}
          </h1>
          <div className="ms-3 d-flex align-items-center">{getIcon()}</div>
        </div>
        {!isInProduction() && (
          <Link
            className="ms-3"
            to={`/lead/${clientId}/${specification.lead}/${specification._id}/edit`}
          >
            Изменить
          </Link>
        )}
      </div>
      <Table
        loading={isLoading}
        columns={specificationProductsColumn}
        dataSource={getSpecificationProductsDataSource(orders)}
        pagination={false}
        rowClassName={(_record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-bg-secondary"
        }
      />
      <div className="mt-4">
        <div className="mb-3">
          {(userPermission?.production || userPermission?.isAdmin) && (
            <Button
              className="me-1 text-white"
              disabled={isInProduction()}
              onClick={() => {
                sendOrderForSupplies(specification._id, orders, 
                  () => {sendToProduction(specification._id, orders)
                  notifyDepartments(specification._id)
                  })
                // sendToProduction(specification._id, orders);
              }}
              style={{
                backgroundColor: "#dc5566",
              }}
            >
              На производство
            </Button>
          )}
          {(userPermission?.production || userPermission?.isAdmin) &&
            specification.status !== "BOOKING" && (
              <Button
                className="me-1 text-white bg-secondary"
                disabled={isInProduction()}
                onClick={() => {
                  sendToBooking(specification._id);
                }}
              >
                Поставить на бронь
              </Button>
            )}
          {(userPermission?.agreement || userPermission?.isAdmin) && (
            <Button
              disabled={isInProduction()}
              className="me-1 text-white"
              onClick={() => {
                requestSendAgreement(specification.lead);
              }}
              style={{
                backgroundColor: "#f4ab4b",
              }}
            >
              Договор
            </Button>
          )}
          {(userPermission?.KP || userPermission?.isAdmin) && (
            <Button
              disabled={isInProduction()}
              onClick={() => {
                requestSendCommercialProposal(specification._id);
              }}
              className="me-1 text-white"
              style={{
                backgroundColor: "#4fbe86",
              }}
            >
              КП
            </Button>
          )}
          {(userPermission?.payment || userPermission?.isAdmin) && (
            <Button
              className="me-1 text-white"
              disabled={isInProduction()}
              onClick={() => {
                sendInvoiceForPayment(specification._id);
              }}
              style={{
                backgroundColor: "#0caad1",
              }}
            >
              Счет на оплату
            </Button>
          )}
        </div>
        {(userPermission?.agreement || userPermission?.isAdmin) && (
          <Button
            className="me-1"
            onClick={() => {
              sendPromoCodeToClient(specification._id);
            }}
            type="primary"
          >
            Отправить промо код клиенту
          </Button>
        )}
      </div>
    </Card>
  );
};

export default SpecificationProducts;
