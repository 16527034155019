import {
  getSelectedProductsColumns,
  getSelectedProductsDataSource,
} from "../utils/table";
import { Table } from "antd";

const SelectedProductsList = ({
  selectedProducts,
  setSelectedProducts,
  deleteSelectedProduct,
  increasePrice = 0,
}) => {
  const columns = getSelectedProductsColumns(selectedProducts);
  const dataSource = getSelectedProductsDataSource(
    selectedProducts,
    setSelectedProducts,
    deleteSelectedProduct,
    increasePrice
  );

  return (
    <div
      style={{
        overflow: "auto",
        maxWidth: "100%",
      }}
    >
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default SelectedProductsList;
