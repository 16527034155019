import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { changePageTitle } from "../../utils/title";
import { fetchClients } from "./utils/fetch";
import {
  getPagination,
  getTableColumns,
  getTableDataSource,
} from "./utils/table";

const Clients = ({ setToken }) => {
  const [clients, setClients] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    changePageTitle("Клиенты");
  }, []);

  useEffect(() => {
    fetch();
  }, [currentPage]);

  const fetch = () => {
    fetchClients(
      {
        search: searchQuery,
        size: limit,
        page: currentPage,
      },
      setClients,
      setTotalPage,
      setIsLoading,
      setToken
    );
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <Card>
        <Form
          onFinish={fetch}
          style={{
            display: "flex",
            marginBottom: 15,
          }}
        >
          <Input
            placeholder="Поиск по ключевому слову"
            onChange={(value) => {
              setSearchQuery(value.target.value);
            }}
            className="me-2"
          />
          <Button
            htmlType="submit"
            type="primary"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            // onClick={() => {
            //   fetch();
            // }}
          >
            <SearchOutlined />
            <span>Поиск</span>
          </Button>
        </Form>
        <div style={{ overflow: "auto" }}>
          <Table
            bordered
            size="small"
            loading={isLoading}
            columns={getTableColumns()}
            dataSource={getTableDataSource(clients)}
            pagination={false}
          />
        </div>
        {getPagination(currentPage, setCurrentPage, limit, setLimit, totalPage)}
      </Card>
    </div>
  );
};

export default Clients;
