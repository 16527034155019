import { ModalForm } from "@ant-design/pro-components";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { requiredField } from "../../../constants/message";
import { formulaOptions, unitOptions } from "../../../constants/options";
import { createAccessory, fetchAccessories } from "../utils/fetch";
import { AccessoriesList } from "./";

const CreateAccessories = ({
  accessories,
  setAccessories,
  totalCount,
  setTotalCount,
}) => {
  const [form] = useForm();

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible]);

  const fetch = () => {
    setAccessories([]);

    fetchAccessories(setAccessories, setTotalCount, setIsLoading);
  };

  const reset = () => {
    form.resetFields();

    setIsLoading(true);
    setIsSaving(false);
  };

  const onSuccess = () => {
    form.resetFields();

    fetch();
  };

  const onFinish = async (values) => {
    createAccessory(values, setIsSaving, onSuccess);
  };

  return (
    <ModalForm
      form={form}
      visible={visible}
      onVisibleChange={setVisible}
      trigger={<Button onClick={() => setVisible(true)}>Комплектующие</Button>}
      title="Комплектующие"
      onFinish={onFinish}
      submitter={{
        submitButtonProps: {
          style: {
            display: "none",
          },
        },
      }}
    >
      <div className="mb-3">
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Название:"
              className="mb-2 me-2"
              rules={[requiredField]}
            >
              <Input placeholder="Введите название..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="costPrice"
              label="Себестоимость:"
              className="mb-2 ms-2"
              rules={[requiredField]}
            >
              <InputNumber
                className="w-100"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => value.replace(/\$\s?|( *)/g, "")}
                placeholder="Введите себестоимость..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="unit"
              label="Ед. измерения:"
              className="me-2 mb-1"
              rules={[requiredField]}
            >
              <AutoComplete
                options={unitOptions}
                placeholder="Введите ед. измерения..."
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="formula" label="Формула:" className="ms-2 mb-1">
              <Select
                allowClear
                placeholder="Выберите формулу..."
                options={formulaOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="mt-2 d-flex justify-content-end">
          <Button type="primary" htmlType="submit" loading={isSaving}>
            Cохранить
          </Button>
        </div>
      </div>

      <AccessoriesList
        accessories={accessories}
        totalCount={totalCount}
        isLoading={isLoading}
        fetch={fetch}
      />
    </ModalForm>
  );
};

export default CreateAccessories;
