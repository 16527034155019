import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  TreeSelect,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import { getTreeNode } from "../utils/format";

const { Option } = Select;

const SelectProduct = ({
  selectedProducts,
  setSelectedProducts,
  isLoading = false,
  products = [],
  form,
  knobs = [],
  doorstep = [],
  latch = [],
  doorPanel = []
}) => {
  const [values, setValues] = useState({
    amount: 1,
    addPetli: 0,
    assembly: false,
  });
  const [selectedProduct, setSelectedProduct] = useState({});

  const isSelectedProductDoor = () => {
    return selectedProduct?.maxHeight ||
      selectedProduct?.minHeight ||
      selectedProduct?.maxWidth ||
      selectedProduct?.minWidth
      ? true
      : false;
  };

  const resetValues = () => {
    if (!isSelectedProductDoor()) {
      form.setFieldValue("width", "");
      form.setFieldValue("height", "");
      form.setFieldValue("opening", "");
      form.setFieldValue("aluminium", "");
      form.setFieldValue("addPetli", 0);
      form.setFieldValue("assembly", false);
      form.setFieldValue("description", "");
      form.setFieldValue("doorHandle", "");
      form.setFieldValue("doorKnob", "");
      form.setFieldValue("doorstep", "");
      form.setFieldValue("doorPanel", "");
      form.setFieldValue("latch", "");

      setValues({
        ...values,
        height: undefined,
        width: undefined,
        opening: undefined,
        aluminium: undefined,
        addPetli: 0,
        assembly: false,
        description: undefined,
        doorHandle: undefined,
        doorKnob: undefined,
        doorstep: undefined,
        doorPanel: undefined,
        latch: undefined,
      });
    }
  };

  const addProduct = () => {
    if (!selectedProduct) {
      message.error("Неполная информация о товаре", 2);
      return;
    }

    const amount = values?.amount || form.getFieldValue("amount");
    let v = values;

    if (isSelectedProductDoor()) {
      if (
        !values?.opening ||
        !values?.aluminium ||
        !values?.width ||
        !values?.height ||
        !amount
      ) {
        message.error("Неполная информация о товаре", 2);

        return;
      }else{
        resetValues();
      }
    } else {
      resetValues();

      v = {
        ...values,
        height: undefined,
        width: undefined,
        opening: undefined,
        aluminium: undefined,
        addPetli: 0,
        assembly: false,
        description: undefined,
        doorHandle: undefined,
        doorKnob: undefined,
        doorstep: undefined,
        doorPanel: undefined,
        latch: undefined,
      };
    }

    setSelectedProducts([]);

    const newProduct = {
      ...v,
      doorHandle: v.opening,
      name: selectedProduct?.name || "",
      unit: selectedProduct?.productType?.unit || "",
      price: selectedProduct?.price || 0,
      productId: selectedProduct?._id || "",
      product: selectedProduct || "",
      _id: selectedProduct?._id || "",
    };

    const newList = [];
    let isFound = false;

    for (let product of selectedProducts) {
      const selectedProductId =
        product?.product?.productId || product?.product || "";

      if (
        product._id === newProduct._id &&
        product.description === newProduct.description &&
        product.width === newProduct.width &&
        product.height === newProduct.height &&
        product.opening === newProduct.opening &&
        product.aluminium === newProduct.aluminium &&
        product.doorKnob._id === newProduct.doorKnob._id || "" &&
        product.latch._id === newProduct.latch._id || "" &&
        product.doorstep._id === newProduct.doorstep._id || "" &&
        product.assembly === newProduct.assembly || false &&
        product.addPetli === newProduct.addPetli || 0 &&
        product.doorPanel._id === newProduct.doorPanel._id &&
        isSelectedProductDoor()
      ) {
        product.amount += newProduct.amount;
        isFound = true;
      } else if (!isSelectedProductDoor()) {
        if (
          selectedProductId === newProduct._id &&
          product.description === newProduct.description
        ) {
          product.amount += amount;
          isFound = true;
        }
      }

      newList.push(product);
    }

    if (!isFound) {
      newList.push(newProduct);
    }


    setSelectedProducts(newList);

    message.success("Товар добавлен в список", 1);
    form.resetFields(); // Reset all form fields
    setValues({
      amount: 1,
      addPetli: 0,
      assembly: false,
    })
  };

  const isDoor = () => {
    try {
      return selectedProduct.doorType ? true : false;
    } catch (e) {}

    return false;
  };

  const handleChange = (key, value) => {
    values[key] = value;

    setValues(values);
  };

  useEffect(() => {
    form.setFieldValue("price", selectedProduct?.price);
    form.setFieldValue("height", selectedProduct?.minHeight);
    form.setFieldValue("width", selectedProduct?.minWidth);
    setValues({
      ...values,
      price: selectedProduct?.price || 0,
      width: selectedProduct?.minWidth,
      height: selectedProduct?.minHeight,
    });
  }, [selectedProduct]);

  return (
    <div>
      <div className="d-flex flex-wrap">
        <Form.Item name={"selectedProduct"} className="w-50 me-5">
          <div>&nbsp;</div>
          <Select
            placeholder="Выберите товар из списка"
            loading={isLoading}
            onChange={(value) => {
              const foundProduct = products.find((v) => v._id === value);
              if (foundProduct) {
                setSelectedProduct(foundProduct);
              }
            }}
            style={{ display: "none" }}
          >
            <Option disabled selected value="">
              Выберите товар из списка
            </Option>
            {products.map((product, index) => {
              return (
                <Option key={index} value={product._id}>
                  {product.name}
                </Option>
              );
            })}
          </Select>
          <TreeSelect
            bordered
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            placeholder="Выберите товар из списка"
            showSearch
            treeNodeFilterProp="title"
            onChange={(value) => {
              const foundProduct = products.find((v) => v._id === value);
              if (foundProduct) {
                setSelectedProduct(foundProduct);
              } else {
                setSelectedProduct(null);
              }
            }}
          >
            {getTreeNode(products)}
          </TreeSelect>
        </Form.Item>

        <div
          style={{
            marginRight: 15,
          }}
        >
          <div>Размер:</div>
          <div
            style={{
              display: "flex",
            }}
          >
            <Form.Item
              name="height"
              rules={[
                {
                  required: isDoor(),
                },
              ]}
            >
              <InputNumber
                placeholder="Высота"
                disabled={
                  selectedProduct?.maxHeight && selectedProduct?.minHeight
                    ? false
                    : true
                }
                max={selectedProduct?.maxHeight || 0}
                min={selectedProduct?.minHeight || 0}
                onChange={(value) => handleChange("height", value)}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => value.replace(/\$\s?|( *)/g, "")}
              />
            </Form.Item>
            <Form.Item
              name="width"
              rules={[
                {
                  required: isDoor(),
                },
              ]}
            >
              <InputNumber
                disabled={
                  selectedProduct?.maxWidth && selectedProduct?.minWidth
                    ? false
                    : true
                }
                max={selectedProduct?.maxWidth || 0}
                min={selectedProduct?.minWidth || 0}
                onChange={(value) => handleChange("width", value)}
                placeholder="Ширина"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => value.replace(/\$\s?|( *)/g, "")}
              />
            </Form.Item>
          </div>
        </div>
        <div
          style={{
            marginRight: 15,
          }}
        >
          <div>Цена:</div>
          <Form.Item name="price">
            <InputNumber
              disabled={true}
              defaultValue={selectedProduct?.price || 0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => value.replace(/\$\s?|( *)/g, "")}
            />
          </Form.Item>
        </div>
        <div
          style={{
            marginRight: 15,
          }}
        >
          <div>Количество:</div>
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              onChange={(value) => handleChange("amount", value)}
              min={1}
              disabled={selectedProduct?.name ? false : true}
              defaultValue={""}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => value.replace(/\$\s?|( *)/g, "")}
            />
          </Form.Item>
        </div>
        <div
          style={{
            marginRight: 15,
          }}
        >
          <div>Открывание:</div>
          <Form.Item
            name="opening"
            rules={[
              {
                required: isDoor(),
              },
            ]}
          >
            <Select
              onChange={(value) => handleChange("opening", value)}
              defaultValue=""
              disabled={selectedProduct?.name && isDoor() ? false : true}
            >
              <Option selected disabled value="">
                Выберите открывание
              </Option>
              <Option value="Наружное правое">Наружное правое</Option>
              <Option value="Наружное левое">Наружное левое</Option>
              <Option value="Внутреннее правое">Внутреннее правое</Option>
              <Option value="Внутреннее левое">Внутреннее левое</Option>
            </Select>
          </Form.Item>
        </div>

        <div
          style={{
            marginRight: 15,
          }}
        >
          <div>Алюминий:</div>
          <Form.Item
            name="aluminium"
            rules={[
              {
                required: isDoor(),
              },
            ]}
          >
            <Select
              onChange={(value) => handleChange("aluminium", value)}
              defaultValue=""
              disabled={selectedProduct?.name && isDoor() ? false : true}
            >
              <Option selected disabled value="">
                Выберите алюминий
              </Option>
              <Option value="SILVER">Серебро</Option>
              <Option value="BLACK">Чёрный</Option>
              <Option value="WITHOUT_ALUMINIUM">Без алюминия</Option>
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="d-flex flex-wrap">
        {/* NEW */}
        <div
          style={{
            marginRight: 15,
            width: '40%'
          }}
        >
          <div>Выберите ручку:</div>
          <Form.Item name={"doorKnob"}>
            <Select 
              onChange={(value, option) => {
                const foundProduct = knobs.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("doorKnob", foundProduct);
                }
              }}
              defaultValue=""
              disabled={selectedProduct?.name && isDoor() ? false : true}
            >
              <Option selected disabled value="">
                Пожалуйста выберите ручку
              </Option>
              {knobs.map((knob, index) => {
                return (
                  <Option key={index} value={knob._id} label={knob.name}>
                    {knob.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div
          style={{
            marginRight: 15,
            width: '40%'
          }}
        >
          <div>Поворотник:</div>
          <Form.Item
            name="latch"
            rules={[
              {
                required: isDoor(),
              },
            ]}
          >
            <Select
              onChange={(value, option) => {
                const foundProduct = latch.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("latch", foundProduct);
                }
              }}
              defaultValue=""
              disabled={selectedProduct?.name && isDoor() ? false : true}
            >
              <Option selected disabled value="">
                Не выбрано
              </Option>
              {latch.map((knob, index) => {
                return (
                  <Option key={index} value={knob._id} label={knob.name}>
                    {knob.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        </div>
        <div className="d-flex flex-wrap">
        <div
          style={{
            marginRight: 15,
            width: '40%'
          }}
        >
          <div>Порог</div>
          <Form.Item
            name="doorstep"
            rules={[
              {
                required: isDoor(),
              },
            ]}
          >
            <Select
              onChange={(value, option) => {
                const foundProduct = doorstep.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("doorstep", foundProduct);
                }
              }}
              defaultValue=""
              disabled={selectedProduct?.name && isDoor() ? false : true}
            >
              <Option selected disabled value="">
                Выберите порог
              </Option>
              {doorstep.map((knob, index) => {
                return (
                  <Option key={index} value={knob._id} label={knob.name}>
                    {knob.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div
          style={{
            marginRight: 15,
            width: '40%'
          }}
        >
          <div>Добор</div>
          <Form.Item
            name="doorPanel"
            rules={[
              {
                required: isDoor(),
              },
            ]}
          >
            <Select
              onChange={(value, option) => {
                const foundProduct = doorPanel.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("doorPanel", foundProduct);
                }
              }}
              defaultValue=""
              disabled={selectedProduct?.name && isDoor() ? false : true}
            >
              <Option selected disabled value="">
                Выберите добор
              </Option>
              {doorPanel.map((panel, index) => {
                return (
                  <Option key={index} value={panel._id} label={panel.name}>
                    {panel.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        </div>
        <div className="d-flex flex-wrap mb-3">
        <div
          style={{
            marginRight: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>Сборка</div>
          <Form.Item
            name="assembly"
          >
             <Checkbox
            disabled={!isDoor()}
            onChange={(event) => {
              handleChange("assembly", event.target.checked);
            }}
          ></Checkbox>
            </Form.Item>
         
        </div>
        <div
          style={{
            marginRight: 15,
          }}
        >
          <div>Доп петля</div>
          <Form.Item
            name="addPetli"
            rules={[
              {
                required: isDoor(),
              },
            ]}
          >
             <InputNumber
            disabled={!isDoor()}
            min={0}
            max={100}
            defaultValue={0}
            name="addPetli"
            placeholder="Доп петли"
            onChange={(event) => {
              handleChange("addPetli", event);
            }}
          />
            </Form.Item>
         
          {/* <ProForm.Group>
            <ProFormDigit
              disabled={!isDoor()}
              defaultValue={1}
              min={0}
              max={100}
              name="addPetli"
              placeholder="Доп петли"
              onChange={(event) => {
                handleChange("addPetli", event)
              }
              }
            />
          </ProForm.Group> */}
        </div>
      
      </div>
      <div
      className="d-flex flex-wrap"
        style={{
          marginBottom: 30,
          display: "flex",
        }}
        
      >
        {/* <div
          style={{
            marginRight: 15,
          }}
        >
          <div>Цена:</div>
          <Form.Item name="price">
            <InputNumber
              disabled={true}
              defaultValue={selectedProduct?.price || 0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => value.replace(/\$\s?|( *)/g, "")}
            />
          </Form.Item>
        </div> */}

        <Form.Item
          name="description"
          style={{
            marginRight: 15,
          }}
        >
          <div>Примечание</div>
          <Input
            onChange={(event) =>
              handleChange("description", event.target.value)
            }
          />
        </Form.Item>

        <div>
          <div>&nbsp;</div>
          <Form.Item>
            <Button
              disabled={selectedProduct?.name ? false : true}
              type="primary"
              onClick={addProduct}
            >
              Добавить
            </Button>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default SelectProduct;
