import { getDeadlineColor } from "../../../utils/deadlineColor";

export const getRowColor = (data) => {
  const deadline = new Date(data.deadline);

  if (data.deadline) {
    if (deadline < new Date()) {
      return {
        style: {
          backgroundColor: "rgb(241, 70, 53)",
        },
      };
    }

    if (deadline.getTime() - 5 * 24 * 60 * 60 * 1000 < new Date().getTime()) {
      if (deadline.getTime() - 5 * 24 * 60 * 60 * 1000 < new Date().getTime()) {
        return {
          style: {
            backgroundColor: "#eb8e86",
          },
        };
        
      }

      return {
        className: "bg-warning",
      };
    }
  }

  return {};
};

export const getStatusColor = (lead) => {
  if (lead.status === "PRODUCTION") {
    return getDeadlineColor(lead.productionDeadline, 3);
  } else if (lead.status === "LOOP_MILLING") {
    return getDeadlineColor(lead.assemblyDeadline, 1);
  } else if (lead.status === "INSTALLING") {
    return getDeadlineColor(lead.montageDeadline, 1);
  }
}
