export const getDeadlineColor = (statusDeadline, days, mainDeadline = null) => {
    const deadline = new Date(statusDeadline);
    if (!deadline) {
        return {};
    }

    if (mainDeadline && new Date(mainDeadline) < new Date()) {
        return {
            className: "bg-danger",
        };
    }
  
    if (deadline < new Date()) {
    // return {
    //     style: {
    //     backgroundColor: "#eb8e86", //  "rgb(241, 70, 53)"
    //     },
    // };
        return {
            className: "bg-danger",
        };
    }

    if (deadline.getTime() - days * 24 * 60 * 60 * 1000 < new Date().getTime()) {
        return {
            className: "bg-warning",
          };
    }
  
    return {};
  };
  