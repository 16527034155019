import React from 'react';
import { Typography } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const { Text } = Typography;

const NPSCharts = ({ selectedChart, selectedDates, npsToday, npsYesterday, averageNPSByDay, averageNPSByPeriod }) => {
  let chartData = [];
  let chartTitle = '';

  switch(selectedChart) {
    case 'today':
      return (
        <div style={{ marginBottom: '20px' }}>
          <Text strong style={{ fontSize: '40px' }}>NPS за сегодня: {npsToday}</Text>
        </div>
      );
    case 'yesterday':
      return (
        <div style={{ marginBottom: '20px' }}>
          <Text strong style={{ fontSize: '40px' }}>NPS за вчера: {npsYesterday}</Text>
        </div>
      );
    case 'week':
      chartData = averageNPSByDay;
      chartTitle = 'Оценки за неделю';
      break;
    case 'period':
      chartData = averageNPSByPeriod;
      chartTitle = `Оценки за период ${selectedDates.length ? selectedDates.map(date => date.format('DD.MM.YYYY')).join(' - ') : ''}`;
      break;
    default:
      chartData = averageNPSByDay;
      chartTitle = 'Оценки за неделю';
  }

  return (
    <div style={{ width: '60%' }}>
      <h2>{chartTitle}</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" style={{ fontSize: '16px', fontWeight: 'bold' }} />
          <YAxis domain={[1, 5]} style={{ fontSize: '16px', fontWeight: 'bold' }} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="nps" stroke="#8884d8" strokeWidth={4} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NPSCharts;
