import { CloseOutlined } from "@ant-design/icons";
import { Input, InputNumber } from "antd";
import {
  currencyFormat,
  getOrderPriceWithDiscountAndAppreciationPercentage,
  getTotalPriceWithDiscount,
} from "./price";
import { calculateAssembly } from "./orderAccessories";

export const getSelectedProductsColumns = () => {
  const columns = [
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      width: 25,
      fixed: "left",
    },
    {
      title: "№",
      dataIndex: "number",
      key: "number",
    },
    {
      title: (
        <div
          style={{
            minWidth: 250,
          }}
        >
          Наименование&nbsp;товара
        </div>
      ),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: <>Размеры</>,
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Открывание",
      dataIndex: "opening",
      key: "opening",
    },
    {
      title: "Алюминий",
      dataIndex: "aluminium",
      key: "aluminium",
    },
    {
      title: "Ручка",
      dataIndex: "doorKnob",
      key: "doorKnob",
    },
    {
      title: "Поворотник",
      dataIndex: "latchName",
      key: "latchName",
    },
    {
      title: "Порог",
      dataIndex: "doorstepName",
      key: "doorsterName",
    },
    {
      title: "Сборка",
      dataIndex: "assembly",
      key: "assembly",
    },
    {
      title: "Доп петля",
      dataIndex: "addPetli",
      key: "addPetli",
    },
    {
      title: "Добор",
      dataIndex: "doorPanel",
      key: "doorPanel",
    },
    {
      title: <>Кол&nbsp;-&nbsp;во</>,
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: <>Ед.&nbsp;изм.</>,
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Скидка",
      dataIndex: "orderDiscountNode",
      key: "orderDiscountNode",
      valueType: "input",
    },
    {
      title: <div>Удорожания,&nbsp;%</div>,
      dataIndex: "appreciationPercentage",
      key: "appreciationPercentage",
      valueType: "input",
    },
    {
      title: "Сумма",
      dataIndex: "priceWithDiscount",
      key: "priceWithDiscount",
      render: (_, record) => {
        return (
          <div style={{ inlineSize: 100 }}>
            {currencyFormat(record?.priceWithDiscount || 0)}
          </div>
        );
      },
    },
    {
      title: "Примечаниe",
      dataIndex: "description",
      key: "description",
    },
  ];

  return columns;
};

export const getSelectedProductsDataSource = (
  selectedProducts = [],
  setSelectedProducts = () => {},
  deleteSelectedProduct = () => {},
  increasePrice = 0
) => {
  const dataSource = [];

  const getSize = (selectedProduct) => {
    if (selectedProduct.width && selectedProduct.height) {
      const width = selectedProduct?.width || 0;
      const height = selectedProduct?.height || 0;

      return `${height}x${width}`;
    }

    return "-";
  };

  const isDoor = (selectedProduct) => {
    if (selectedProduct?.width) {
      return true;
    }

    return false;
  };

  let number = 1;
  for (let i = 0; i < selectedProducts.length; i++) {
    const selectedProduct = selectedProducts[i];
    const product = selectedProduct.product || selectedProduct;
    const amount = selectedProduct?.amount || 1;
    let price = selectedProduct?.price || product?.price;
    if (selectedProduct.aluminium === "BLACK") {
      price *= 1.1;
    }
    price += price * (increasePrice / 100);
 
    if(isDoor(selectedProduct)){
      if (selectedProduct.doorKnob) {
        price += selectedProduct.doorKnob.price;
      }
      if (selectedProduct.doorstep) {
        price += selectedProduct.doorstep.price;
      }
      if (selectedProduct.doorPanel) {
        price += selectedProduct.doorPanel.price;
      }
      if (selectedProduct.latch) {
        price += selectedProduct.latch.price;
      }
      if(selectedProduct.assembly){
        const assemblyPrice = calculateAssembly([selectedProduct])
        price += assemblyPrice.price
      }
    }
    price = parseInt(price);
    const orderDiscount = selectedProduct?.orderDiscount || 0;
    const appreciationPercentage = selectedProduct?.appreciationPercentage || 0;
    const priceWithDiscount = getTotalPriceWithDiscount(
        [selectedProduct],
        appreciationPercentage,
        orderDiscount,
      );


    const data = {
      delete: (
        <div
          className="cursor-pointer text-danger"
          onClick={() => {
            deleteSelectedProduct(i++);
          }}
        >
          <CloseOutlined />
        </div>
      ),
      key: selectedProduct._id,
      number,
      productName: selectedProduct?.name || product?.name,
      unit: selectedProduct?.unit || product?.productType?.unit,
      amount: (
        <InputNumber
          style={{ width: 50 }}
          className="me-1"
          value={amount || 0}
          min={0}
          onChange={(value) => {
            saveAmount(selectedProduct, parseInt(value.toString()));
          }}
        />
      ),
      price: <div style={{ inlineSize: 100 }}>{currencyFormat(price)}</div>,
      description: (
        <Input.TextArea
          style={{ width: 200 }}
          defaultValue={
            selectedProduct?.description || selectedProduct?.comment || ""
          }
          onBlur={(v) => {
            saveDescription(selectedProduct, v.target.value);
          }}
        />
      ),
      addPetli: isDoor(selectedProduct) ? selectedProduct?.addPetli : "-",
      assembly: isDoor(selectedProduct) ? selectedProduct?.assembly ? "есть" : "нет" : '-',
      doorKnob: isDoor(selectedProduct) ? selectedProduct?.doorKnob?.name : "-",
      doorPanel: isDoor(selectedProduct) ?  selectedProduct?.doorPanel?.name : "-",
      latchName: isDoor(selectedProduct) ?  selectedProduct?.latch?.name : "-",
      doorstepName: isDoor(selectedProduct) ?   selectedProduct?.doorstep?.name  : '-',
      opening: isDoor(selectedProduct) ?  selectedProduct?.doorHandle : "-",
      size: getSize(selectedProduct),
      aluminium: isDoor(selectedProduct)
        ? selectedProduct.aluminium === "BLACK"
          ? "Черный"
          : "Серебро"
        : "-",
      orderDiscountNode: (
        <InputNumber
          style={{ width: 50 }}
          className="me-1"
          value={selectedProduct?.orderDiscount || 0}
          min={0}
          max={100}
          onChange={(value) => {
            saveDiscount(selectedProduct, parseInt(value.toString()));
          }}
        />
      ),

      orderDiscount,
      priceWithDiscount,
      appreciationPercentage: (
        <InputNumber
          style={{ width: 75 }}
          className="me-1"
          value={selectedProduct?.appreciationPercentage || 0}
          min={0}
          max={100}
          onChange={(value) => {
            saveAppreciationPercentage(
              selectedProduct,
              parseInt(value.toString())
            );
          }}
        />
      ),
    };
    dataSource.push(data);
    number++;
  }

  const isSameProduct = (selectedProduct, product) => {
    // const selectedProductId = selectedProduct?.product?.productId || selectedProduct?.productId;
    // const productId = product?.product?.productId || product?.productId;
    const selectedProductId =
      selectedProduct?.product?._id || selectedProduct?._id;
    const productId = product?.product?._id || product?._id;

    if (
      productId === selectedProductId &&
      product.width === selectedProduct.width &&
      product.height === selectedProduct.height &&
      product.name === selectedProduct.name &&
      product.aluminium === selectedProduct.aluminium &&
      product.doorHandle === selectedProduct.doorHandle
    ) {
      return true;
    }

    return false;
  };

  const saveDiscount = (selectedProduct, value) => {
    const newList = [];

    for (let product of selectedProducts) {
      if (isSameProduct(selectedProduct, product)) {
        const sum = getOrderPriceWithDiscountAndAppreciationPercentage(
          product?.price || product?.product?.price,
          value,
          product.appreciationPercentage
        );

        product.orderDiscount = value;
        product.priceWithDiscountNode = (
          <div style={{ inlineSize: 100 }}>{currencyFormat(sum)}</div>
        );
        product.priceWithDiscount = sum;
      }

      newList.push(product);
    }

    setSelectedProducts(newList);
  };

  const saveAppreciationPercentage = (selectedProduct, value) => {
    const newList = [];

    for (let product of selectedProducts) {
      if (isSameProduct(selectedProduct, product)) {
        const sum = getOrderPriceWithDiscountAndAppreciationPercentage(
          product?.price || product?.product?.price,
          product.orderDiscount,
          value
        );

        product.appreciationPercentage = value;
        product.priceWithDiscountNode = (
          <div style={{ inlineSize: 100 }}>{currencyFormat(sum)}</div>
        );
        product.priceWithDiscount = sum;
      }

      newList.push(product);
    }

    setSelectedProducts(newList);
  };

  const saveAmount = (selectedProduct, value) => {
    const newList = [];

    for (let product of selectedProducts) {
      if (isSameProduct(selectedProduct, product)) {
        product.amount = value;
      }

      newList.push(product);
    }

    setSelectedProducts(newList);
  };

  const saveDescription = (selectedProduct, value) => {
    const newList = [];

    for (let product of selectedProducts) {
      if (isSameProduct(selectedProduct, product)) {
        product.comment = value;
      }

      newList.push(product);
    }

    setSelectedProducts(newList);
  };

  const saveProductPrice = (selectedProduct, value) => {
    const newList = [];

    for (let product of selectedProducts) {
      if (isSameProduct(selectedProduct, product)) {
        product.product.price = value;
      }

      newList.push(product);
    }

    setSelectedProducts(newList);
  };
  return dataSource;
};
