import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateCompany } from "../Users/components";
import { fetchCompanies } from "../Users/utils/fetch";
import { companiesColumn, getCompaniesDataSource } from "./utils/table";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { changePageTitle } from "../../utils/title";

const Company = ({ setToken, userPermission }) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    fetchCompanies(setCompanies, setIsLoading, setToken);

    changePageTitle("Компания");
  }, []);

  const navigate = useNavigate();

  const openCompanyPage = (id) => {
    navigate(`/user/${id}`);
  };

  if (userPermission.isLocalAdmin) {
    openCompanyPage(userPermission?.companyId);
  }

  return (
    <Layout>
      <PageContainer title="Компании">
        <Card>
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <CreateCompany setCompanies={setCompanies} />
          </div>
          <div style={{ overflow: "auto" }}>
            <ProTable
              bordered
              search={false}
              options={false}
              loading={isLoading}
              size="small"
              columns={companiesColumn}
              dataSource={getCompaniesDataSource(companies, openCompanyPage)}
              pagination={false}
            />
          </div>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default Company;
