import {
  CarryOutOutlined,
  FormOutlined,
  InboxOutlined,
  SolutionOutlined,
  SisternodeOutlined,
  UserOutlined,
  CreditCardOutlined,
  PercentageOutlined,
  LikeOutlined,
} from "@ant-design/icons";

export const getSidebarData = (userPermission) => {
  const result = [];

  if (userPermission.createLead || userPermission?.isAdmin) {
    result.push({
      key: "lead",
      icon: <FormOutlined />,
      label: "Новая заявка",
      link: "/",
    });
  }

  if (userPermission.clients || userPermission?.isAdmin) {
    result.push({
      key: "client",
      icon: <SolutionOutlined />,
      label: "Клиенты",
      link: "/client",
    });
  }

  if (userPermission.products || userPermission?.isAdmin) {
    result.push({
      key: "product",
      icon: <InboxOutlined />,
      label: "Товары",
      link: "/product",
    });
  }

  if (userPermission?.isLocalAdmin || userPermission?.isAdmin) {
    result.push({
      key: "user",
      icon: <UserOutlined />,
      label: "Пользователи",
      link: "/user",
    });
  }

  if (userPermission?.orderStatus || userPermission?.isAdmin) {
    result.push({
      key: "status",
      icon: <CarryOutOutlined />,
      label: "Статусы заказов",
      link: "/status",
    });
  }

  if (userPermission?.assemblyStatus) {
    result.push({
      key: "assembly status",
      icon: <CarryOutOutlined />,
      label: "Статусы сборки",
      link: "/assembly_status",
    });
  }

  if (userPermission?.manufactureStatus) {
    result.push({
      key: "manufacture status",
      icon: <CarryOutOutlined />,
      label: "Статусы производства",
      link: "/manufacture_status",
    });
  }

  if (userPermission?.deliveryStatus) {
    result.push({
      key: "delivery status",
      icon: <CarryOutOutlined />,
      label: "Статусы доставки",
      link: "/delivery_status",
    });
  }

  if (userPermission?.installationStatus) {
    result.push({
      key: "installation status",
      icon: <CarryOutOutlined />,
      label: "Статусы монтажа",
      link: "/installation_status",
    });
  }

  if (userPermission?.isAdmin) {
    result.push({
      key: "supplies",
      icon: <SisternodeOutlined />,
      label: "Снабжения",
      link: "/supplies",
    });
  }

  if (userPermission?.isAdmin) {
    result.push({
      key: "entity",
      icon: <CreditCardOutlined />,
      label: "Реквизиты",
      link: "/entity",
    });
  }

  if (userPermission?.isAdmin) {
    result.push({
      key: "partners",
      icon: <PercentageOutlined />,
      label: "Партнеры",
      link: "/kepler-partner",
    });
  }

  if (userPermission?.isAdmin) {
    result.push({
      key: "nps",
      icon: <LikeOutlined />,
      label: "NPS",
      link: "/nps",
    });
  }

  return result;
};
