import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import UpdateEntity from "../components/UpdateEntity";

import {  ProTable } from "@ant-design/pro-components";


export const entitiesColumn = [
  {
    title: "№",
    key: "number",
    dataIndex: "number",
    width: 60,
    render: (text, record, index) => (
      <span>{index + 1}</span>
    ),
  },
  
  // {
  //   title: "Название",
  //   key: "name",
  //   dataIndex: "clientType",
  //   render: (text, record) => (
  //     <span>
  //       {record.clientType === "ENTITY" ? "Юридический счет" : "Физический счет"}
  //     </span>
  //   ),
  // },
  {
    title: "Данные реквизитов",
    key: "entityData",
    dataIndex: "entityData",
    render: (text, record) => (
      <div>
        {Object.entries(record)
          .map(([key, value]) => (
            <p key={key}>
              <strong>{key}: </strong> {value}
            </p>
          ))}
      </div>
    ),
  },
  {
    title: "",
    key: "actions",
    dataIndex: "actions",
    width: 85,
    render: (_, record) => (
      <div style={{ display: "flex" }}>
        <UpdateEntity />
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 5,
            color: "red",
          }}
        >
          <DeleteOutlined />
        </Button>
      </div>
    ),
  },
];

export const getEntitiesDataSource = (entities, openEntityPage) => {
  const data = [];

  if (Array.isArray(entities)) {
    for (let entity of entities) {
      const rowData = {
        Название: entity.clientType === "ENTITY" ? "Юридический счет" : "Физический счет",
        Компания: entity.companyName,
        БИК: entity.BIC,
        БИН:  entity.BIN,
        "Номер счёта": entity.accountNumber,
        Банк: entity.bankName,
        "Ген. Директор": entity.fullNameDirector
      };
      data.push(rowData);
    }
  }
  return data;
};


function EntityTable({ entities, openEntityPage }) {
  const dataSource = getEntitiesDataSource(entities, openEntityPage);

  return (
    <ProTable dataSource={dataSource} columns={entitiesColumn} />
  );
}
export default EntityTable;

// import React, { useState, useEffect } from 'react';
// import { Table } from 'antd';
// import { fetchEntities, getEntitiesDataSource } from './fetch'; 

// export const entitiesColumn = [
//   {
//     title: "",
//     key: "number",
//     dataIndex: "number",
//     width: 60,
//   },
//   {
//     title: "Название",
//     key: "clientType",
//     dataIndex: "clientType",
//   },
//   {
//     title: "",
//     key: "actions",
//     dataIndex: "actions",
//     width: 85,
//   },
// ];

// const YourComponent = () => {
//   const [entities, setEntities] = useState([]); 
//   const [isLoading, setIsLoading] = useState(true); 

//   useEffect(() => {
//     fetchEntities({
//       setEntities: setEntities, 
//       setIsLoading: setIsLoading, 
//     });
//   }, []);

//   const columns = entitiesColumn; 
//   const dataSource = getEntitiesDataSource(entities, openEntityPage); 
  

//   return (
//     <Table
//       columns={columns}
//       dataSource={dataSource}
//       loading={isLoading} 
//     />
//   );
// };

// export default YourComponent;
