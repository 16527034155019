import { Modal, Tooltip, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { acceptApplication, refuseApplication, completeApplication } from "../utils/fetch";
import { getModalInnerText } from "../utils/calc";


export const RefuseApplicationButton = ({ lead, fetch }) => {
  const [showRefuseApplicationModal, setShowRefuseApplicationModal] = useState(false);

const content = (
  <>
        <p>Вы точно хотите отклонить заявку на производство?</p>
        {getModalInnerText(lead)}
      </>
)

  return (
    <>
      <Tooltip title="Отклонить">
        <Button
          style={{
            padding: "5px 10px",
            color: "red",
            marginRight: 10,
          }}
          onClick={() => {
            setShowRefuseApplicationModal(true);
          }}
        >
          <CloseOutlined />
        </Button>
      </Tooltip>
      <ManufactureModal 
        visible = {showRefuseApplicationModal}
        onSubmit = {() => {
          refuseApplication(lead._id, fetch)
          setShowRefuseApplicationModal(false)
        }}
        onCancel = {() => setShowRefuseApplicationModal(false)}
        okText = "Отклонить"
        content = {content}
      />
  </>
  )
}

export const AcceptApplicationButton = ({ lead, fetch }) => {
  const [showAcceptApplicationModal, setShowAcceptApplicationModal] = useState(false);

  const content = (
    <>
          <p>Вы точно хотите принять заявку на производство?</p>
          {getModalInnerText(lead)}
        </>
  )
  
    return (
      <>
        <Tooltip title="Принять">
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            onClick={() => {
              setShowAcceptApplicationModal(true);
            }}
          >
            <CheckOutlined />
          </Button>
        </Tooltip>
        <ManufactureModal 
          visible = {showAcceptApplicationModal}
          onSubmit = {() => {
            acceptApplication(lead._id, fetch)
            setShowAcceptApplicationModal(false)
          }}
          onCancel = {() => setShowAcceptApplicationModal(false)}
          okText = "Принять"
          content = {content}
        />
    </>
    )
}

export const CompleteApplicationButton = ({ lead, fetch }) => {
  const [showCompleteApplicationModal, setShowCompleteApplicationModal] = useState(false);

  const content = (
    <>
          <p>Вы точно готовы сдать заказ?</p>
          {getModalInnerText(lead)}
        </>
  )
  
    return (
      <>
        <Tooltip title="Сдать">
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            onClick={() => {
              setShowCompleteApplicationModal(true);
            }}
          >
            <CheckOutlined />
          </Button>
        </Tooltip>
        <ManufactureModal 
          visible = {showCompleteApplicationModal}
          onSubmit = {() => {
            completeApplication(lead._id, lead.isHasAssembly, fetch)
            setShowCompleteApplicationModal(false)
          }}
          onCancel = {() => setShowCompleteApplicationModal(false)}
          okText = "Сдать"
          content = {content}
        />
    </>
    )
}

const ManufactureModal = ({
  visible,
  onSubmit,
  onCancel,
  okText,
  content,
}) => {
  return (
    <Modal
    title="Внимание!"
    visible={visible}
    onOk={onSubmit}
    onCancel={onCancel}
    okText={okText}
    cancelText="Закрыть"
    okButtonProps={{
      style: {
        backgroundColor: "red",
        borderColor: "red",
      },
    }}
  >
    {content}
  </Modal>
  )
}  