import axios from "axios";
import { getToken } from "./token";

export const postRequest = async (url, body = {}, options = {}) => {
  return axios
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      ...options,
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("accessToken");
        window.location.reload();
      }

      throw err;
    });
};

export const getRequest = async (url, options = {}) => {
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      ...options,
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("accessToken");
        window.location.reload();
      }

      throw err;
    });
};

export const deleteRequest = async (url, options = {}) => {
  return axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      ...options,
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("accessToken");
        window.location.reload();
      }

      throw err;
    });
};

export const patchRequest = async (url, body, options) => {
  return axios
    .patch(url, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      ...options,
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("accessToken");
        window.location.reload();
      }

      throw err;
    });
};
