import { ProForm, ProFormText } from "@ant-design/pro-components";

const IndividualClientInfo = ({ disabled }) => {
  const requiredRule = {
    required: true,
  };

  return (
    <>
      <ProForm.Group>
        <ProFormText
          name="name"
          label="ФИО"
          placeholder="ФИО клиента"
          rules={[requiredRule]}
          disabled={disabled}
        />
        <ProFormText
          name="iin"
          label="ИИН"
          placeholder="ИИН клиента"
          disabled={disabled}
        />
      </ProForm.Group>
    </>
  );
};

export default IndividualClientInfo;
