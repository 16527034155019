import { Form } from "antd";
import { useEffect, useState } from "react";
import { fetchProducts } from "../utils/fetch";
import SelectedProductsList from "./SelectedProductsList";
import SelectProduct from "./SelectProduct";

const ProductsInformation = ({
  setToken,
  selectedProducts,
  setSelectedProducts,
  onChange,
  increasePrice = 0,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [knobs, setKnobs] = useState([]);
  const [doorstep, setDoorstep] = useState([]);
  const [latch, setLatch] = useState([]);
  const [doorPanel, setDoorPanel] = useState([])

  const [selectProductForm] = Form.useForm();

  useEffect(() => {
    fetchProducts(
      setProducts,
      setIsLoading,
      setToken,
      setKnobs,
      setDoorstep,
      setLatch,
      setDoorPanel
    );
  }, []);

  const deleteSelectedProduct = (id) => {
    setSelectedProducts([]);
    const newList = [];

    for (let i = 0; i < selectedProducts.length; i++) {
      if (i !== id) {
        newList.push(selectedProducts[i]);
      }
    }

    setSelectedProducts(newList);
  };

  return (
    <div>
      <h1
        style={{
          marginBottom: 20,
        }}
      >
        Информация о товаре
      </h1>
      <Form
        form={selectProductForm}
        initialValues={{
          selectedProduct: "",
          amount: 1,
        }}
      >
        <SelectProduct
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          isLoading={isLoading}
          products={products}
          form={selectProductForm}
          knobs={knobs}
          doorstep={doorstep}
          latch={latch}
          doorPanel={doorPanel}
          onChange={onChange}
        />
      </Form>
      <SelectedProductsList
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        deleteSelectedProduct={deleteSelectedProduct}
        increasePrice={increasePrice}
      />
    </div>
  );
};

export default ProductsInformation;
