import { Modal, Button, message } from "antd";
import { useState } from "react";
import { completeInstallation, sendInstallationCodeToClient, acceptForInstallation } from "../utils/fetch";
import PinInput from "react-pin-input";
import config from "../../../config.json";

export const AcceptButton = ({ lead, fetch }) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const openDocument = (type = "agreement", id) => {
    window.open(`${config.service}/api/file/${type}/${id}`);
  };

  const content = (
    <>
        <p>Вы точно хотите принять заказ на монтаж?</p>
    </>
  )
  
    return (
      <>
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            onClick={() => {
                setShowAcceptModal(true);
            }}
          >
            Принять
          </Button>
        <InstallationModal 
          visible = {showAcceptModal}
          onSubmit = {() => {
            acceptForInstallation(lead._id, fetch)
            // openDocument("acceptance-act", lead._id);
            setShowAcceptModal(false)
          }}
          onCancel = {() => setShowAcceptModal(false)}
          okText = "Принять"
          content = {content}
        />
    </>
    )
}

export const CompleteButton = ({ lead, fetch }) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('')

  const onClick = async (phone, id) => {
    setIsLoading(true);
    sendInstallationCodeToClient(id, phone, 
      msg => {
        if (msg) {
            message.success(msg, 3)
        }
        setShowCompleteModal(true)
      },
    )
    .catch((err) => {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err?.response?.data?.message;
      }

      message.error(msg);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const onSubmit = (pin, lead) => {
    setIsLoading(true);
    completeInstallation(pin, lead,
      msg => {
        if (msg) {
            message.success(msg, 5)
        }
        setShowCompleteModal(false)
      },
      () => {
        message.error("Неверный код", 3)
      },
      () => {
        fetch();
        setIsLoading(false)
      }
    )
    .catch((err) => {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err?.response?.data?.message;
      }

      message.error(msg);
    })
  }

  const content = (
    <>
        <p>Чтобы завершить монтаж, введите код, который клиент получил на WhatsApp</p>
        <div className="my-3">
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, _index) => {
            setPin(value);
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{ borderColor: "#dee2e6" }}
          inputFocusStyle={{ borderColor: "#1890ff" }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </div>
    </>
  )
    return (
      <>
          <Button
            style={{
              padding: "5px 10px",
              color: "green",
              marginRight: 10,
            }}
            loading = {isLoading}
            onClick={() => {
              onClick(lead.client[0].phone, lead._id)
            }}
          >
              Завершить монтаж
          </Button>
        <InstallationModal 
          visible = {showCompleteModal}
          onSubmit = {() => {
            onSubmit(pin, lead)
          }}
          onCancel = {() => setShowCompleteModal(false)}
          okText = "Отправить"
          content = {content}
          loading = {isLoading}
        />
    </>
    )
}

const InstallationModal = ({
  visible,
  onSubmit,
  onCancel,
  okText,
  content,
}) => {
  return (
    <Modal
    title="Внимание!"
    visible={visible}
    onOk={onSubmit}
    onCancel={onCancel}
    okText={okText}
    cancelText="Закрыть"
    okButtonProps={{
      style: {
        backgroundColor: "red",
        borderColor: "red",
      },
    }}
  >
    {content}
  </Modal>
  )
}  